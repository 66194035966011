<main id="main">
    <section class="dashboard-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-12">
                    <h5 style="text-align: center;">History</h5><br />
                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Total Projects</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-blue">
                                            <strong>£{{projectValue?.totalpassedValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-blue w-100"></a>
                    </div>

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Total Projects in my category</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-blue">
                                            <strong>£{{projectValue?.ProjectInCategoryValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-success w-100"></a>
                    </div>

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Total matched</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-teal">
                                            <strong>£{{projectValue?.matchedProjectsValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-teal w-100"></a>
                    </div>

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Shortlisted</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-teal">
                                            <strong>£{{projectValue?.sortListedValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-info w-100"></a>
                    </div>

                    <!-- <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Dropped Projects</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-teal">
                                            <strong>£{{projectValue?.dropValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-warning w-100"></a>
                    </div> -->

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Awarded</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-green">
                                            <strong>£{{projectValue?.totalAwardedValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-green w-100"></a>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-6 col-sm-6 col-md-6 col-12">
                    <h5 style="text-align: center;">Current</h5><br />
                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>In-solution</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-blue">
                                            <strong>£{{projectValue?.insolutionValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-blue w-100"></a>
                    </div>

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>In-Review</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-blue">
                                            <strong>£{{projectValue?.inReviewValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-success w-100"></a>
                    </div>

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>In-submission</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-teal">
                                            <strong>£{{projectValue?.inSubmitionsValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-teal w-100"></a>
                    </div>

                    <!-- <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>UK Expert-Writing</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-teal">
                                            <strong>£{{projectValue?.UKExpertWritingsValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-info w-100"></a>
                    </div> -->

                    <!-- <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>UK Expert-Review</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-teal">
                                            <strong>£{{projectValue?.UKExpertReviewValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-warning w-100"></a>
                    </div> -->

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Submitted</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-green">
                                            <strong>£{{projectValue?.totalSubmitValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-green w-100"></a>
                    </div>

                    <div class="col-12 mb-5">
                        <div class="statistic-card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <p><strong>Not Awarded</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="text-brown">
                                            <strong>£{{projectValue?.totalNotAwardedValue}}</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="explore-btn bg-brown w-100"></a>
                    </div>

                </div>

            </div>
        </div>
    </section>
</main>