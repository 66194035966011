<main id="main">
  <section>
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
          <div class="form-group mb-0">
            <span class="input-icon"><i class="bi bi-search"></i></span>
            <input type="search" class="form-control control-search" placeholder="Search by BOS ID & Project Title."
              [formControl]="searchText">
          </div>
        </div>
        <div class="col-lg-2 col-xl-2 col-12">
          <div class="form-group">
            <span class="input-icon"><i class="bi bi-filter"></i></span>
            <select class="form-select ps-5">
              <option>Filter By</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-responsive mt-5 mb-3">
        <table class="table table-striped align-middle">
          <thead>
            <tr>
              <th width="100px">Project Title</th>
              <!-- <th width="150px">Project Description</th> -->
              <th>BOS ID</th>
              <th>Publish Date</th>
              <th>Category</th>
              <th>Industry</th>
              <th>Min value (GBP)</th>
              <th>Max value (GBP)</th>
              <th>Project Type</th>
              <th>Status</th>
              <th>Due Date &amp; Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td *ngIf="!projectList?.length" colspan="11">
                <app-no-record-found></app-no-record-found>
              </td>
            </tr>

            <tr
              *ngFor="let item of projectList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
              <td><a href="javascript:;">{{item?.projectName}}</a></td>
              <!-- <td><a title="{{ item?.description }}">{{ item?.description | slice:0:10 }}</a></td> -->
              <td v-align="middle">{{item?.BOSID}}</td>
              <td>{{item?.publishDate | date:'dd/MM/yyyy'}}</td>
              <td>{{item?.category}}</td>
              <td>{{item?.industry}}</td>
              <td>{{item?.minValue}}</td>
              <td>{{item?.maxValue}}</td>
              <td>{{item?.projectType}}</td>
              <td>{{item?.status}}</td>
              <td>{{item?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
              <td>
                <a class="btn btn-sm btn-primary m-1" title="View Details" (click)="projectDetails(item?._id)">View
                  Details</a>
                <a routerLink="/supplier-admin/chat" class="btn btn-sm btn-primary m-1" title="Shortlist"><i
                    class="bi bi-chat-dots"></i> Chat</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-3 align-items-center">
        <div class="row">
          <div class="pagination">
            <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>