<header id="header">
    <div class="container d-flex align-items-center">

        <a routerLink="/boss-user/home" class="logo "><img src="assets/img/newlogoo.png" alt="" class="img-fluid"></a>
        <nav id="navbar" class="w-100 navbar">
            <ul class="mx-auto">
                <li class="nav-link noarrowdrop" *ngFor="let item of navUrlArr">
                    <a [class.active]="(item.route.includes(router.url))" class="nav-link " data-toggle="tab"
                        [routerLink]="item.route">
                        <i *ngIf="item.title == 'Live Projects'" class="bi bi-dot text-danger p-22"></i>
                        <span>{{item.title}}</span>
                    </a>
                </li>
            </ul>
            <ul>
                <li class="dropdown"><a class="nav-link d-flex align-items-center" href="javascript:;">
                        <div class="login-avtar">
                            <img src="assets/img/face1.jpg" />
                        </div>
                        <span>{{loginUser?.userName}}</span> <i class="bi bi-chevron-down"></i>
                    </a>
                    <ul>
                        <li><a routerLink="/boss-user/boss-user-profile">Profile Seting</a></li>
                        <li><a style="cursor: pointer;" (click)="logout()">Logout</a></li>
                    </ul>
                </li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
    </div>
</header>