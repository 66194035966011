<div class="modal-lg" role="document">
  <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header bg-primary text-white">
          <h5 class="modal-title" id="uploadModalLabel">Upload Excel File</h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      </div>

      <!-- Modal Body -->
      <div class="modal-body">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-12 text-center">
                      <div class="file-upload-wrapper">
                          <label for="file-upload" class="btn btn-secondary btn-lg">
                              <i class="bi bi-cloud-upload"></i> Choose File
                          </label>
                          <input id="file-upload" type="file" (change)="onFileChange($event)" accept=".xlsx, .xls"
                              class="d-none" />
                      </div>
                      <small class="text-muted d-block mt-2">Accepted formats: .xlsx, .xls</small>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>