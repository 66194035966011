<section>
  <div class="container-fluids mb-3">
    <div class="row align-items-center">
      <nav>
        <ul class="projects-nav-tab">
          <li routerLink="/super-admin/todo-tasks">
            <a class="active">List View</a>
          </li>
          <li routerLink="/super-admin/resources-productivity-view">
            <a>Resources Productivity View</a>
          </li>
          <li routerLink="/super-admin/team-productivity-view">
            <a>Team Productivity View</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="container pt-5">
    <div class="row">
      <div class="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-12 mob-mb1">
        <div class="sidebar-widget">
          <p class="ps-3"><strong>Task Status</strong></p>
          <ul class="sidebar-nav">
            <li>
              <a href="javascript:void(0)" routerLink="/super-admin/my-day-tasks">My Day</a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/super-admin/todo-tasks" class="active">Ongoing</a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/super-admin/completed-tasks">Completed</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-10 col-xl-10 col-md-8 col-sm-8 col-12">
        <div class="row">
          <div class="col-md-12 col-xl-12 col-12" style="cursor: pointer;">
            <!-- User List -->
            <ul class="taskuserList" *ngIf="!showLoader">
              <li *ngFor="let user of displayedUsers">
                <span [ngClass]="{
                    'selected-user': selectedUserIds.includes(user._id)
                  }" (click)="toggleUserSelection(user._id)">
                  {{ user.userName }} - {{ user?.taskcount }}
                </span>
              </li>
              <!-- View More / View Less Link -->
              <li *ngIf="userList.length > 7">
                <a href="javascript:;" (click)="toggleView()">
                  {{ showAll ? "- View Less" : "+ View More" }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="accordion accordion-flush" id="accordianTodoList">
          <p><strong>Ongoing Task</strong></p>

          <div class="select-container mb-3">
            <div class="row">
              <div class="col-md-12 mb-2">
                <div class="flex-grow-1">
                  <div class="form-group mb-0 position-relative">
                    <span class="input-icon"><i class="bi bi-search"></i></span>
                    <input class="form-control control-search" placeholder="Search by Title" type="text"
                      [(ngModel)]="searchText" (input)="searchtext()" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mb-2">
                  <ng-select [items]="filterbyDueDate" bindLabel="projectType" bindValue="value"
                    [(ngModel)]="selectedtype" (change)="searchtext()" [multiple]="false" placeholder="Select Type">
                  </ng-select>
                </div>

                <div class="col-md-4 mb-2">
                  <ng-select [items]="filterbyPriority" bindLabel="priorityValue" bindValue="priorityvalue"
                    [(ngModel)]="selectedpriority" (change)="searchtext()" [multiple]="false"
                    placeholder="Select Priority">
                  </ng-select>
                </div>

                <div class="col-md-4 mb-2">
                  <ng-select class="custom-ng-select-option" placeholder="Select Type" bindLabel="taskType"
                    bindValue="taskValue" [(ngModel)]="selectedtasktypes" (change)="searchtext()" [multiple]="false"
                    [items]="taskType">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-TodoListZero">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                <span class="bluetext">+ Add a New Task</span>
              </button>
            </h2>
          </div>

          <div *ngFor="let task of taskList; let i = index">
            <div class="accordion-item">
              <h2 class="accordion-header" [id]="'flush-TodoList-' + i">
                <button class="accordion-button d-flex justify-content-between align-items-center" type="button"
                  (click)="navigateToTaskDetail(task)" [ngClass]="{
                    'bg-light-yellow': task?.pickACategory === 'low',
                    'bg-light-green': task?.pickACategory === 'Medium',
                    'bg-light-orange': task?.pickACategory === 'High'
                  }">
                  <div>
                    {{ task?.task }}
                    <span *ngIf="task?.pickACategory">
                      ({{ task?.pickACategory }})</span>
                    <span *ngIf="task?.project">
                      (Status :
                      {{
                      [
                      "Fail",
                      "DocumentsNotFound",
                      "Awaiting",
                      "Inhold",
                      "Not Releted"
                      ].includes(task?.project?.status)
                      ? task?.project?.status
                      : task?.project?.bidManagerStatus
                      }}
                      ) </span>&nbsp;
                    <span *ngIf="task?.completedTask == true"><button class="btn btn-sm btn-success mt-2">
                        Completed
                      </button></span>
                  </div>
                  <div class="text-end">
                    <span *ngIf="task.assignTo?.length > 0" class="text-muted me-3">
                      <span *ngFor="let user of task.assignTo; let last = last">
                        {{ user?.userDetail?.name }}{{ last ? "" : ", " }}
                      </span>
                    </span>
                    <span *ngIf="task?.createdAt" class="text-muted me-2">
                      Created: {{ task.createdAt | date : "MMM d, y" }}
                    </span>
                    <span *ngIf="task?.dueDate" class="text-muted">
                      Due: {{ task.dueDate | date : "MMM d, y" }}
                    </span>
                  </div>
                </button>
              </h2>
              <div [id]="'flush-collapse-' + i" class="accordion-collapse show">
                <div class="accordion-body">
                  <ul class="SecondarytaskList" *ngIf="task?.todayComments">
                    <li *ngFor="let comment of task.todayComments" class="comment-item p-2"
                      [ngClass]="{ 'admin-comment': comment?.userDetail?.role === 'Admin' }">

                      <div class="row align-items-center mb-3">
                        <div class="col">
                          <div class="d-flex justify-content-between">
                            <p class="mb-0 user-name">
                              {{ comment?.userDetail?.name }}
                            </p>
                            <small class="comment-date">
                              {{ comment?.date | date : "dd/MM/yyyy, h:mm a" }}
                            </small>
                          </div>
                          <small class="text-muted user-role d-block">
                            {{
                            comment?.userDetail?.role === "BOS"
                            ? "USER"
                            : comment?.userDetail?.role
                            }}
                          </small>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between">
                        <p class="comment-text" [innerHTML]="comment?.comment">
                          <!-- {{ comment?.comment }} -->
                        </p>
                        <div class="d-flex gap-2">
                          <button class="btn btn-sm delete-btn" (click)="deleteComments(comment?.commentId, task)">
                            <i class="bi bi-trash3"></i>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>


                  <p *ngIf="!task?.todayComments" class="text-muted">
                    No comments available.
                  </p>

                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!taskList?.length">
            <app-no-record-found></app-no-record-found>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<!-- Add New task Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
  #taskModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add New Task</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input class="form-control" placeholder="Enter Task Titles" cols="100" rows="3" [(ngModel)]="taskTitle" />
      </div>
      <div class="modal-body">
        <label>Task Description</label>
        <form [formGroup]="taskForm">
          <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" style="padding: 15px !important;">
            </ngx-editor-menu>
            <ngx-editor [editor]="editor" rows="5" formControlName="description" [disabled]="false"
              [placeholder]="'Type here...'"></ngx-editor>
          </div>
        </form>
      </div>
      <div class="modal-body">
        <label>Due Date</label>
        <input class="form-control" type="date" [(ngModel)]="dueDate" />
      </div>
      <div class="modal-body">
        <label>Assign To</label>
        <ng-select style="padding: 0px !important" placeholder="Select Assign User"
          class="form-select custom-ng-select-option" bindLabel="userName" [multiple]="true" bindValue="_id"
          [items]="userList" [(ngModel)]="assignTo">
        </ng-select>
      </div>

      <div class="modal-body">
        <label>Task Type</label>
        <ng-select style="padding: 0px !important" placeholder="Select Task Type"
          class="form-select custom-ng-select-option" bindLabel="taskType" [multiple]="false" bindValue="taskValue"
          [items]="taskType" [(ngModel)]="type">
        </ng-select>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="addTask()">
          Add Task
        </button>
      </div>
    </div>
  </div>
</div>
