<main id="main">
    <section class="user-profile-section">
      <div class="container">
        <div class="row align-items-center mb-5">
          <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10 col-8 mob-mb1">
            <h4 class="text-theme mb-4"><strong>Users Profile</strong></h4>
          </div>
          <!-- <div class="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-12 text-end"
            routerLink="/supplier-admin/supplier-dashboard-header">
            <a href="javascript:;" class="btn btn-primary text-dark">Back</a>
          </div> -->
        </div>
        <div class="row">
          <div class="col-lg-2 col-xl-2 col-12">
            <div class="profile-avtar mt-4 mb-4">
              <!-- <img *ngIf="userData?.profileImageUrl" [src]="userData?.profileImageUrl" id="profile-image" />
              <img *ngIf="!userData?.profileImageUrl" src="assets/img/face1.jpg" id="profile-image" /> -->
              <!-- <div class="input--file">
                <i class="bi bi-camera-fill"></i>
                <input name="Select File" type="file" />
              </div> -->
            </div>
          </div>
          <div class="col-lg-5 col-xl-5 col-12 row">
            <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  
              <div class="form-group">
                <label>Employee Name</label>
                <input type="text" class="form-control" formControlName="name" />
  
              </div>
  
              <div class="form-group">
                <label>Location</label>
                <input type="text" class="form-control" formControlName="location" />
  
              </div>
  
              <div class="form-group">
                <label>Email</label>
                <input type="email" class="form-control" formControlName="email" readonly />
  
              </div>
  
              <div class="form-group">
                <label>Phone Number</label>
                <input type="text" class="form-control" formControlName="phoneNumber" (keypress)="NumberOnly($event)"
                  minlength="10" maxlength="10" />
  
              </div>
  
              <div class="form-group">
                <label>Job Title</label>
                <input type="text" class="form-control" formControlName="jobTitle" />
  
              </div>
  
              <div class="form-group">
                <label>Profession Skills</label>
                <ng-select style="padding: 0px !important;" class="form-select custom-ng-select-option" [items]="skills"
                  bindLabel="name" [multiple]="true" bindValue="id" formControlName="professionalSkill">
                </ng-select>
  
              </div>
              <div class="form-group">
                <label>Report to </label>
                <select name="" id="" class="form-control" formControlName="reportTo">
                  <ng-container *ngFor="let item of reportToData">
                    <option [value]="item?.id"> {{item.name}} </option>
                  </ng-container>
                </select>
  
              </div>
              <div class="form-group">
                <label>Manages</label>
                <ng-select style="padding: 0px !important;" class="form-select custom-ng-select-option"
                  [items]="managesData" bindLabel="name" [multiple]="true" bindValue="id" formControlName="manages">
                </ng-select>
  
              </div>
              <div class="form-group">
                <div class="upload-btn-wrapper">
                </div>
                <!-- <button type="submit" class="btn btn-primary" >Update</button> -->
              </div>
            </form>
          </div>
        </div>
  
        <!-- <div class="row align-items-center mb-5">
          <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10 col-8 mob-mb1">
            <h4 class="text-theme mb-4"><strong>Change Password</strong></h4>
          </div>
  
          <form class="needs-validation" [formGroup]="changePasswordForm" (ngSubmit)="forgotpassword()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="fw-bold">Old Password</label>
                  <div class="input-group">
                    <input [type]="showOldPassword ? 'text' : 'password'" class="form-control"
                      placeholder="Enter old password" formControlName="oldPassword" required>
                    <button type="button" class="btn btn-outline-secondary" (click)="showOldPassword = !showOldPassword">
                      <i class="bi" [ngClass]="showOldPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
                    </button>
                  </div>
                  <span class="text-danger small d-block mt-1" *ngIf="changePasswordForm.controls.oldPassword?.touched &&
                    changePasswordForm.controls.oldPassword?.errors?.['required']">
                    Please enter a valid password.
                  </span>
                </div>
              </div>
  
              <div class="col-md-6">
                <div class="form-group">
                  <label class="fw-bold">New Password</label>
                  <div class="input-group">
                    <input [type]="showNewPassword ? 'text' : 'password'" class="form-control"
                      placeholder="Enter new password" formControlName="newPassword" required>
                    <button type="button" class="btn btn-outline-secondary" (click)="showNewPassword = !showNewPassword">
                      <i class="bi" [ngClass]="showNewPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
                    </button>
                  </div>
                  <span class="text-danger small d-block mt-1" *ngIf="changePasswordForm.controls.newPassword?.touched &&
                    changePasswordForm.controls.newPassword?.errors?.['required']">
                    Please enter a valid password.
                  </span>
                </div>
              </div>
            </div>
  
            <div class="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-12 text-end">
              <button type="submit" class="btn btn-primary w-100 mt-3">Change Password</button>
            </div>
          </form>
  
  
  
        </div> -->
  
      </div>
    </section>
  </main>