<body class="body-login">

    <div class="container d-flex justify-content-center align-items-center" style="height:100vh; overflow:hidden;">
        <div class="row d-flex align-items-center justify-content-center"
            style="overflow:hidden; width:100vw; height:100vh;">

            <div class="col-lg-4 col-xl-4 col-12 text-center">
                <img src="assets/img/westgate.svg" width="250" />
            </div>
            <div class="col-lg-4 col-xl-4 col-12">
                <div class="login-modal">
                    <div class="login-body" id="SubmitEmail">
                        <h4 class="mb-4"><strong>Forgot Password</strong></h4>
                        <form class="needs-validation" [formGroup]="forgotForm" (ngSubmit)="forgotpassword()">
                            <div class="form-group">
                                <label>Role</label>
                                <select formControlName="role" class="form-select">
                                    <option disabled selected hidden value="">Select User Role</option>
                                    <option value="BOS">User</option>
                                    <option value="ProjectManager">Bid Manager</option>
                                    <option value="ProcessManagerAdmin">Process Manager</option>
                                    <option value="Admin">Super Admin</option>
                                </select>
                                <span class="text-danger" *ngIf="
                                forgotForm.controls.role?.touched &&
                                forgotForm.controls.role?.errors?.['required']">
                                    Please enter role
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Email id</label>
                                <input type="email" formControlName="email" required class="form-control"
                                    placeholder="Enter email id">
                                <span class="text-danger" *ngIf="
                            forgotForm.controls.email?.touched &&
                            forgotForm.controls.email?.errors?.['required']">
                                    Please enter email
                                </span>
                                <span class="text-danger" *ngIf="
                            forgotForm.controls.email?.touched &&
                            forgotForm.controls.email?.errors?.['pattern']">
                                    Please enter valid email
                                </span>
                            </div>
                            <button type="submit" class="btn btn-primary w-100 mt-3" id="submit-btn">Submit</button>
                        </form>
                        <!-- routerLink="/reset-password" -->
                    </div>
                    <div class="login-body" id="passwordLinkSend" style="display: none;">
                        <img src="assets/img/check.png" class="mb-4" />
                        <h4 class="mb-4"><strong>Great</strong></h4>
                        <p>Your request has been submited successfully!!</p>
                        <p>Password reset link send to your register email id, Please check</p>
                        <a href="javascript:void(0)" routerLink="/login">Back to Login</a>
                    </div>
                </div>
            </div>

        </div> <!-- Inner row -->
    </div> <!-- Outer container -->
</body>