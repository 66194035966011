<main id="main">
    <section>
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                    <div class="form-group mb-0">
                        <span class="input-icon"><i class="bi bi-search"></i></span>
                        <input type="search" [formControl]="searchText" class="form-control control-search"
                            placeholder="Search by BOS ID & Project Title.">
                    </div>
                </div>
                <div class="col-lg-2 col-xl-2 col-12">
                    <a routerLink="/uk-writer/uk-writer-projects-all"
                    class="btn btn-primary text-dark">Back</a>
                </div>
            </div>
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th width="200px">Supplier Name</th>
                            <th>Supplier ID</th>
                            <th>Admin Name</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td *ngIf="!supplierList?.length" colspan="5">
                                <app-no-record-found></app-no-record-found>
                            </td>
                        </tr>
                        
                        <tr
                        *ngFor="let supplier of supplierList">
                            <td><a href="javascript:;">{{supplier.supplierId?.name}}</a></td>
                            <td v-align="middle">{{supplier.supplierId?._id}}</td>
                            <td>{{supplier.supplierId.userName}}</td>
                            <td>Status</td>
                            <td>
                                <a (click)="projectDetails(supplier.supplierId?._id)" class="btn btn-sm btn-primarysm"
                                title="View Details">View Supplier Details</a>
                            </td>
                        </tr>
                        <!-- <tr
                        *ngFor="let supplier of supplierList">
                            <td><a href="javascript:;">{{supplier?.name}}</a></td>
                            <td v-align="middle">{{supplier?._id}}</td>
                            <td>{{supplier.userName}}</td>
                            <td>Status</td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
            <!-- <div class="row mt-3 align-items-center">
                <div class="row">
                    <div class="pagination">
                        <pagination-controls (pageChange)="paginate($event)"></pagination-controls>
                    </div>
                </div>
            </div> -->
            <!-- <nav>
                <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                        <span class="page-link">Previous</span>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;">1</a></li>
                    <li class="page-item active" aria-current="page">
                        <span class="page-link">2</span>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="javascript:;">Next</a>
                    </li>
                </ul>
            </nav> -->
        </div>
    </section>
</main><!-- End #main -->