<main id="main">
    <section>
        <div class="container-fluid">
            <div class="row align-items-center justify-content-end mb-4">
                <div class="col-auto mob-mb1">
                    <a routerLink="/boss-user/home" class="btn btn-outline-white w-100"> <i
                            class="bi bi-arrow-left"></i>
                        Back</a>
                </div>
            </div>
            <form [formGroup]="documentForm" (ngSubmit)="submitForm()">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-3">
                        <input type="text" formControlName="projectName" class="form-control"
                            placeholder="Project Name">
                        <span class="text-danger" style="color: red;"
                            *ngIf="documentForm.controls.projectName.touched && documentForm.controls.projectName?.errors?.['required']">
                            Please enter project name </span>
                    </div>
                    <div class="col-sm-2">
                        <input type="text" formControlName="BOSId" class="form-control" placeholder="BOS ID">
                        <span class="text-danger" style="color: red;"
                            *ngIf="documentForm.controls.BOSId.touched && documentForm.controls.BOSId?.errors?.['required']">
                            Please enter boss id </span>
                    </div>
                    <div class="col-sm-3">
                        <input type="email" formControlName="emailId" class="form-control" placeholder="Enter Email ID">
                        <span class="text-danger" style="color: red;"
                            *ngIf="documentForm.controls.emailId.touched && documentForm.controls.emailId?.errors?.['required']">
                            Please enter email </span>
                    </div>
                    <div class="col-sm-2">
                        <div class="upload-btn-wrapper">
                            <label for="fileInput" class="btn-upload-file"><i
                                    class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                                Screenshot</label>
                            <input type="file" id="fileInput" name="myfile" style="height: 10px; width: 10px;"
                                accept="image/png, image/jpeg" (change)="addFiles($event)" />
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <button type="submit" class="btn btn-primary w-100">ADD</button>
                    </div>
                </div>
            </form>

            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th>Project Title</th>
                            <th>BOS ID</th>
                            <th>Email</th>
                            <th>Screenshot</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of mailList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                            <td><a href="javascript:;">{{item?.projectName}}</a></td>
                            <td v-align="middle">{{item?.BOSId}}</td>
                            <td>{{item?.emailId}}</td>
                            <td>
                                <a (click)="openDocument(item)" data-bs-toggle="modal" data-bs-target="#ViewScreenshot"
                                    class="btn btn-small me-2"><i class="bi bi-eye-fill"></i>
                                    View</a>
                                <a href="javascript:;" (click)="download(item?.link?.url, item?.projectName)"
                                    class="btn btn-small"><i class="bi bi-file-earmark-arrow-down-fill"></i>
                                    Download</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 align-items-center">
                <div class="row">
                    <div class="pagination">
                        <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<!-- View Screenshot Modal -->
<div class="modal fade" id="ViewScreenshot" tabindex="-1" aria-labelledby="ViewScreenshotLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ViewScreenshotLabel">Mail Screenshot ({{selectedDocument?.projectName}})
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <img [src]="selectedDocument?.link?.url" class="img-fluid" />
            </div>
        </div>
    </div>
</div>