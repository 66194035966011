import { Component } from '@angular/core';

@Component({
  selector: 'app-total-projects-in-category',
  templateUrl: './total-projects-in-category.component.html',
  styleUrls: ['./total-projects-in-category.component.scss']
})
export class TotalProjectsInCategoryComponent {

}
