import { Component } from '@angular/core';

@Component({
  selector: 'app-uk-writer-question-details-edit',
  templateUrl: './uk-writer-question-details-edit.component.html',
  styleUrls: ['./uk-writer-question-details-edit.component.scss']
})
export class UkWriterQuestionDetailsEditComponent {

}
