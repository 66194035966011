import { Component } from '@angular/core';

@Component({
  selector: 'app-bos-user',
  templateUrl: './bos-user.component.html',
  styleUrls: ['./bos-user.component.scss']
})
export class BOSUserComponent {

}
