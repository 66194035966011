<main id="main">
    <!-- <section class="pageheader pt-5">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-2">
            <a href="project-details.html" class="btn btn-dark w-100">Back</a>
          </div>
        </div>
      </div>
    </section> -->
    <section>
      <div class="container">
        <div class="d-flex justify-content-end">
          <div class="col-2">
            <a routerLink="/supplier-admin/project-list" class="btn btn-primary text-dark">Back</a>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-lg-4 col-xl-4 col-12">
              <div class="form-group">
                <label>Question Name</label>
                <input type="text" class="form-control" [value]="questionDetails?.questionName" disabled>
              </div>
              <div class="form-group">
                <label>Question</label>
                <textarea class="form-control" rows="3"
                  cols="100" disabled>{{questionDetails?.question}}</textarea>
              </div>
            </div>
            <div class="col-lg-8 col-xl-8 col-12">
              <div class="form-group">
                <label>Instruction</label>
                <textarea class="form-control" rows="7"
                  cols="100" disabled>{{questionDetails?.instructions}}</textarea>
              </div>
              <!-- <div class="form-group">
                <label>Comment</label>
                <textarea class="form-control" rows="4"
                  cols="100" disabled>{{questionDetails?.comment}}</textarea>
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <label>Reference Documnet</label>
                <input type="text" class="form-control" [value]="questionDetails?.refrenceDocument" disabled>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <label>Weightage</label>
                <input type="text" class="form-control" [value]="questionDetails?.weightage" disabled>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <label>Deadline</label>
                <input type="text" class="form-control" [value]="questionDetails?.deadline | date : 'dd MMM YYYY'" disabled>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  <section style="background-color: gainsboro;">
    <div class="container">
      <div class="row">
        <div class="col-10">
          <div class="form-group">
            <input type="text" class="form-control" value="V1">
          </div>
        </div>
        <div class="col-2">
          <div class="form-group" style="display: none;">
            <button type="button" class="btn btn-light"><i class="bi bi-pencil-fill"></i></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <div class="form-group">
            <input type="text" class="form-control" value="Review Comments">
          </div>
        </div>
        <div class="col-2">
          <div class="form-group" style="display: none;">
            <button type="button" class="btn btn-light"><i class="bi bi-pencil-fill"></i></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <div class="form-group">
            <input type="text" class="form-control" value="V2">
          </div>
        </div>
        <div class="col-2">
          <div class="form-group" style="display: none;">
            <button type="button" class="btn btn-light"><i class="bi bi-pencil-fill"></i></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <div class="form-group">
            <input type="text" class="form-control" value="Review Comments">
          </div>
        </div>
        <div class="col-2">
          <div class="form-group" style="display: none;">
            <button type="button" class="btn btn-light"><i class="bi bi-pencil-fill"></i></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <div class="form-group">
            <input type="text" class="form-control" value="Final Version">
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <button type="button" class="btn btn-light"><i class="bi bi-pencil-fill"></i></button>
          </div>
        </div>
      </div>
      <div class="row jus">
        <div class="col-lg-10 col-xl-10 col-12 text-end">
          <button type="button" class="btn btn-success me-3">Save</button>
          <button type="submit" class="btn btn-primary" onclick="location.href='project-details.html'">Submit</button>
        </div>
        <div class="col-xl-2 col-lg-2 col-12"></div>
      </div>
    </div>
  </section>
</main><!-- End #main -->
