<main id="main">
    <section>
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                    <div class="form-group mb-0">
                        <span class="input-icon"><i class="bi bi-search"></i></span>
                        <input type="search" class="form-control control-search" placeholder="Search">
                    </div>
                </div>
            </div>
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th>Supplier Name</th>
                            <th>Supplier ID</th>
                            <th>Admin Name</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Company Name</td>
                            <td>416526</td>
                            <td>Ayush</td>
                            <td>In-Progress</td>
                            <td>
                                <a href="" class="btn btn-sm btn-primarysm" title="View Details">View Supplier
                                    Response</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td>416526</td>
                            <td>Ayush</td>
                            <td>In-Progress</td>
                            <td>
                                <a href="" class="btn btn-sm btn-primarysm" title="View Details">View Supplier
                                    Response</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td>416526</td>
                            <td>Ayush</td>
                            <td>In-Progress</td>
                            <td>
                                <a href="" class="btn btn-sm btn-primarysm" title="View Details">View Supplier
                                    Response</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <nav>
                <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                        <span class="page-link">Previous</span>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;">1</a></li>
                    <li class="page-item active" aria-current="page">
                        <span class="page-link">2</span>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="javascript:;">Next</a>
                    </li>
                </ul>
            </nav>

        </div>
    </section>

</main><!-- End #main -->