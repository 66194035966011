<!-- ======= Header ======= -->
<header id="header">
    <div class="container d-flex align-items-center">
        <a routerLink="/bid-submission/bid-submission-home" class="logo me-auto"><img src="assets/img/westgate.svg"
                alt="" class="img-fluid"></a>
        <nav id="navbar" class="navbar">
            <ul>
                <li><a class="nav-link active" routerLink="/bid-submission/bid-submission-home">Home</a></li>
                <li><a class="nav-link" routerLink="/bid-submission/bid-projects-all"><i
                            class="bi bi-circle-fill text-danger p-8 "></i>
                        &nbsp;Projects</a></li>
                <li class="dropdown"><a href="href:;">
                        <div class="login-avtar">
                            <img src="assets/img/face1.jpg" />
                        </div>
                        <span>{{loginUser?.userName}}</span> <i class="bi bi-chevron-down"></i>
                    </a>
                    <ul>
                        <li><a style="cursor: pointer;" routerLink="/bid-submission/profile-setting">Profile Setting</a></li>
                        <li><a style="cursor: pointer;" (click)="logout()">Logout</a></li>
                    </ul>
                </li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <!-- .navbar -->
    </div>
</header><!-- End Header -->
