<main id="main">
  <section>
    <div class="container-fluid">
      <form [formGroup]="productForm" (ngSubmit)="submitForm()">
        <div class="row">
          <h5 class="text-theme mb-4"><strong>Edit Project Details</strong></h5>
          <div class="col-lg-12 col-xl-12 col-12">
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label"
                    >Category</label
                  >
                  <div class="col-sm-8">
                    <ng-select
                      style="padding: 0px !important"
                      placeholder="Select Category"
                      class="form-select custom-ng-select-option"
                      [items]="categoryList"
                      bindLabel="category"
                      [multiple]="true"
                      bindValue="category"
                      formControlName="category"
                      [addTag]="true"
                      (add)="onItemAddCategory($event)"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label"
                    >Industry</label
                  >
                  <div class="col-sm-8">
                    <ng-select
                      style="padding: 0px !important"
                      placeholder="Select Category"
                      class="form-select custom-ng-select-option"
                      [items]="industryList"
                      bindLabel="industry"
                      [multiple]="true"
                      bindValue="industry"
                      formControlName="industry"
                      [addTag]="true"
                      (add)="onItemAddIndustry($event)"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-xl-12 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-2 col-form-label"
                    >Project Type</label
                  >
                  <div class="col-sm-10">
                    <ng-select
                      style="padding: 0px !important"
                      placeholder="Select Project Type"
                      class="form-select custom-ng-select-option"
                      [items]="projectTypeList"
                      bindLabel="type"
                      [multiple]="true"
                      bindValue="value"
                      formControlName="projectType"
                      [addTag]="true"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-xl-12 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-2 col-form-label"
                    >Categorisation</label
                  >
                  <div class="col-sm-10">
                    <ng-select
                      style="padding: 0px !important"
                      placeholder="Select Categorisation"
                      class="form-select custom-ng-select-option"
                      [items]="categorisationList"
                      bindLabel="categorisationtype"
                      [multiple]="false"
                      bindValue="categorisationvalue"
                      formControlName="categorisation"
                      [addTag]="true"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label"
                    >Documents link</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="documentsLink"
                      placeholder="Enter Documents Link"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label"
                    >Portal link</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="linkToPortal"
                      placeholder="Enter Portal Link"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label"
                    >login ID</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="loginID"
                      placeholder="Enter loginID"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label"
                    >Password</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="password"
                      class="form-control"
                      formControlName="password"
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label"
                    >Chatgpt Link</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="chatGptLink"
                      placeholder="Enter Chatgpt Link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 text-end">
            <button
              type="button"
              routerLink="/project-manager/project/bid-manager-to-action"
              class="btn btn-primary"
            >
              Back</button
            >&nbsp;
            <button type="submit" class="btn btn-primary">Publish</button>
          </div>
        </div>
      </form>
    </div>
  </section>
</main>
<!-- End #main -->
