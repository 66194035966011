<main id="main">
  <section class="casestudy-section">
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/supplier-user-profile"><a class="active">Profile</a></li>
            <li routerLink="/super-admin/admin-case-study-list"><a>Case Studies</a></li>
            <li routerLink="/super-admin/super-admin-supplier-users-list"><a>Users</a></li>
            <li routerLink="/super-admin/admin-case-study-list"><a>Activity</a></li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-12">
          <h4 class="text-theme"><strong>Supplier Information</strong></h4><br>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped table-bordered">
            <tbody>
              <tr>
                <th>Company Name</th>
                <td>{{ supplierDetails?.name }}</td>
              </tr>
              <tr>
                <th>Company Website</th>
                <td>{{ supplierDetails?.website }}</td>
              </tr>
              <tr>
                <th>Year of Establishment</th>
                <td>{{ supplierDetails?.yearOfEstablishment }}</td>
              </tr>
              <tr>
                <th>Registration Number</th>
                <td>{{ supplierDetails?.registerNumber }}</td>
              </tr>
              <tr>
                <th>Type of Company</th>
                <td>{{ supplierDetails?.typeOfCompany }}</td>
              </tr>
              <tr>
                <th>Industry/Sector</th>
                <td>{{ supplierDetails?.industry_Sector }}</td>
              </tr>
              <tr>
                <th>Company Address</th>
                <td>{{ supplierDetails?.companyAddress }}</td>
              </tr>
              <tr>
                <th>Developer/Engineer Teams</th>
                <td>{{ supplierDetails?.developerOrEngineerTeams }}</td>
              </tr>
              <tr>
                <th>Data Privacy Policies</th>
                <td>{{ supplierDetails?.dataPrivacyPolicies }}</td>
              </tr>
              <tr>
                <th>Security Certifications</th>
                <td>{{ supplierDetails?.securityCertifications }}</td>
              </tr>
              <tr>
                <th>Email Address</th>
                <td>{{ supplierDetails?.email }}</td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>{{ supplierDetails?.registerNumber }}</td>
              </tr>
              <tr>
                <th>Customer Support Contact</th>
                <td>{{ supplierDetails?.customerSupportContact }}</td>
              </tr>
              <tr>
                <th>VAT or GST Number</th>
                <td>{{ supplierDetails?.VATOrGSTNumber }}</td>
              </tr>
              <tr>
                <th>Company Directors/Owners</th>
                <td>{{ supplierDetails?.companyDirectors_Owners }}</td>
              </tr>
              <tr>
                <th>Compliance Certifications</th>
                <td>{{ supplierDetails?.complianceCertifications }}</td>
              </tr>
              <tr>
                <th>Products/Services Offered</th>
                <td>{{ supplierDetails?.products_ServicesOffered }}</td>
              </tr>
              <tr>
                <th>Technology Stack</th>
                <td>{{ supplierDetails?.technologyStack }}</td>
              </tr>
              <tr>
                <th>Licensing Details</th>
                <td>{{ supplierDetails?.licensingDetails }}</td>
              </tr>
              <tr>
                <th>IP/Patents</th>
                <td>{{ supplierDetails?.IP_Patents }}</td>
              </tr>
              <tr>
                <th>Employee Count</th>
                <td>{{ supplierDetails?.employeeCount }}</td>
              </tr>
              <tr>
                <th>Cybersecurity Practices</th>
                <td>{{ supplierDetails?.cybersecurityPractices }}</td>
              </tr>
              <!-- <tr>
                <th>Password</th>
                <td>{{ supplierDetails?.password }}</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</main>