import { Component } from '@angular/core';

@Component({
  selector: 'app-feasibility-analyst',
  templateUrl: './feasibility-analyst.component.html',
  styleUrls: ['./feasibility-analyst.component.scss']
})
export class FeasibilityAnalystComponent {

}
