<main id="main">
  <section>
    <div class="container-fluid">
      <form [formGroup]="productForm" (ngSubmit)="submitForm()">
        <div class="row">
          <div class="col-lg-5 col-xl-5 col-12">

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">Project Name</label>
              <div class="col-sm-8">
                <textarea formControlName="projectName" class="form-control"></textarea>
                <div *ngIf="productForm.get('projectName')?.invalid && productForm.get('projectName')?.touched"
                  class="text-danger">
                  Project Name is required.
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">BOS ID</label>
              <div class="col-sm-8">
                <input formControlName="BOSID" type="text" class="form-control" />
                <div *ngIf="productForm.get('BOSID')?.invalid && productForm.get('BOSID')?.touched" class="text-danger">
                  BOS ID is required.
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">Publish Date</label>
              <div class="col-sm-8">
                <input formControlName="publishDate" type="text" class="form-control" />
                <div *ngIf="productForm.get('publishDate')?.invalid && productForm.get('publishDate')?.touched"
                  class="text-danger">
                  Publish Date is required.
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">Submission Due Date</label>
              <div class="col-sm-8">
                <input formControlName="dueDate" type="date" class="form-control" />
                <div *ngIf="productForm.get('dueDate')?.invalid && productForm.get('dueDate')?.touched"
                  class="text-danger">
                  Due Date is required.
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12">
                    <input formControlName="bidsubmissiontime" type="text" class="form-control"
                      placeholder="Select Time">
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">Website</label>
              <div class="col-sm-8">
                <select formControlName="website" class="form-select">
                  <option disabled selected hidden value="">Website URL</option>
                  <option value="Proactis">Proactis</option>
                  <option value="FindmyTender">Find my Tender</option>
                  <option value="ContractFinder">Contract Finder</option>
                  <option value="Other">Other</option>
                </select>
                <div *ngIf="productForm.get('website')?.invalid && productForm.get('website')?.touched"
                  class="text-danger">
                  Website selection is required.
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">Notice Reference</label>
              <div class="col-sm-8">
                <input formControlName="noticeReference" type="text" class="form-control" />
                <div *ngIf="productForm.get('noticeReference')?.invalid && productForm.get('noticeReference')?.touched"
                  class="text-danger">
                  Notice Reference is required.
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">CPV Codes</label>
              <div class="col-sm-8">
                <input formControlName="CPVCodes" type="text" class="form-control" />
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-4 col-form-label">Login ID</label>
              <div class="col-sm-8">
                <input formControlName="loginID" type="text" class="form-control" autocomplete="off" readonly
                  (focus)="removeReadonly($event)">
              </div>
            </div>

            <div class="row mb-3">
              <label for="password" class="col-sm-4 col-form-label">Password</label>
              <div class="col-sm-8">
                <input formControlName="password" type="password" class="form-control" autocomplete="off" readonly
                  (focus)="removeReadonly($event)">
              </div>
            </div>
          </div>

          <div class="col-lg-7 col-xl-7 col-12">
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Category</label>
                  <div class="col-sm-8">
                    <ng-select style="padding: 0px !important" placeholder="Select Category"
                      class="form-select custom-ng-select-option" [items]="categoryList" bindLabel="category"
                      [multiple]="true" bindValue="category" formControlName="category" [addTag]="true"
                      (add)="onItemAddCategory($event)">
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Industry</label>
                  <div class="col-sm-8">
                    <ng-select style="padding: 0px !important" placeholder="Select Category"
                      class="form-select custom-ng-select-option" [items]="industryList" bindLabel="industry"
                      [multiple]="true" bindValue="industry" formControlName="industry" [addTag]="true"
                      (add)="onItemAddIndustry($event)">
                    </ng-select>
                  </div>
                </div>
              </div>

            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Description</label>
              <div class="col-sm-10">
                <textarea formControlName="description" class="form-control" rows="3" cols="100"></textarea>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Link to notice</label>
                  <div class="col-sm-8">
                    <input formControlName="link" type="text" class="form-control" />
                    <div *ngIf="productForm.get('link')?.invalid && productForm.get('link')?.touched"
                      class="text-danger">
                      Link to Notice is required.
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Contract Period</label>
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-6">
                        <input formControlName="periodOfContractStart" type="date" class="form-control"
                          placeholder="Start Date" />
                      </div>
                      <div class="col-6">
                        <input formControlName="periodOfContractEnd" type="date" class="form-control"
                          placeholder="End Date" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-xl-12 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">Project Type</label>
                  <div class="col-sm-10">
                    <ng-select style="padding: 0px !important" placeholder="Select Project Type"
                      class="form-select custom-ng-select-option" [items]="projectTypeList" bindLabel="type"
                      [multiple]="true" bindValue="value" formControlName="projectType" [addTag]="true">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-xl-12 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">Categorisation</label>
                  <div class="col-sm-10">
                    <ng-select style="padding: 0px !important" placeholder="Select Categorisation"
                      class="form-select custom-ng-select-option" [items]="categorisationList"
                      bindLabel="categorisationtype" [multiple]="false" bindValue="categorisationvalue"
                      formControlName="categorisation" [addTag]="true">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Mail ID</label>
              <div class="col-sm-10">
                <input formControlName="mailID" type="email" class="form-control" />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Client Type</label>
                  <div class="col-sm-8">
                    <select formControlName="clientType" class="form-select">
                      <option disabled selected hidden value="">Select Client Type</option>
                      <option value="PublicSector">Public Sector</option>
                      <option value="PrivateSector">Private Sector</option>
                    </select>
                    <div *ngIf="productForm.get('clientType')?.invalid && productForm.get('clientType')?.touched"
                      class="text-danger">
                      Client Type is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Client Name</label>
                  <div class="col-sm-8">
                    <input formControlName="clientName" type="text" class="form-control" />
                    <div *ngIf="productForm.get('clientName')?.invalid && productForm.get('clientName')?.touched"
                      class="text-danger">
                      Client Name is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-0">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Min value (GBP)</label>
                  <div class="col-sm-8">
                    <input formControlName="minValue" type="text" class="form-control" aria-label="Min value (GBP)"
                      (keypress)="NumberOnly($event)" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Max value (GBP)</label>
                  <div class="col-sm-8">
                    <input formControlName="maxValue" type="text" class="form-control" aria-label="Max value (GBP)"
                      (keypress)="NumberOnly($event)" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-0">
              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Link to portal</label>
                  <div class="col-sm-8">
                    <input formControlName="linkToPortal" type="text" class="form-control" aria-label="Link to portal">
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Documents link</label>
                  <div class="col-sm-8">
                    <input formControlName="documentsLink" type="text" class="form-control" aria-label="Documents link">
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-0">
              <div class="col-lg-12 col-xl-12 col-12">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">ChatGPT link</label>
                  <div class="col-sm-8">
                    <input formControlName="chatGptLink" type="text" class="form-control" aria-label="Documents link">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 text-end">
            <button type="button" routerLink="/process-manager/process-manager-tracker" class="btn btn-primary">
              Back</button>&nbsp;
            <button type="submit" class="btn btn-primary">Publish</button>
          </div>
        </div>
      </form>
    </div>
  </section>
</main>
<!-- End #main -->
