import { Component } from '@angular/core';

@Component({
  selector: 'app-add-login-details',
  templateUrl: './add-login-details.component.html',
  styleUrls: ['./add-login-details.component.scss']
})
export class AddLoginDetailsComponent {

}
