import { Component } from '@angular/core';

@Component({
  selector: 'app-supplier-admin',
  templateUrl: './supplier-admin.component.html',
  styleUrls: ['./supplier-admin.component.scss']
})
export class SupplierAdminComponent {

}
