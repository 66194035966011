import { Component } from '@angular/core';

@Component({
  selector: 'app-suppiler-admin-chat',
  templateUrl: './suppiler-admin-chat.component.html',
  styleUrls: ['./suppiler-admin-chat.component.scss']
})
export class SuppilerAdminChatComponent {

}
