<main>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
      <h5 class="modal-title">Add Comment</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
      <textarea formControlName="activeStatus" class="form-control" placeholder="Enter your comment here"></textarea>
      <div *ngIf="userForm.get('activeStatus')?.invalid && userForm.get('activeStatus')?.touched" class="text-danger">
        Comment is required.
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
      <button type="submit" class="btn btn-primary">Save Comment</button>
    </div>
  </form>
</main>.