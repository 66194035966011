<main id="main">
  <section class="casestudy-section">
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/supplier-user-profile">
              <a class="active">Profile</a>
            </li>
            <li routerLink="/super-admin/admin-case-study-list">
              <a>Historical Data</a>
            </li>
            <li routerLink="/super-admin/expertise-list">
              <a>Case Studies</a>
            </li>
            <li routerLink="/super-admin/resources-list">
              <a>Resources</a>
            </li>
            <li routerLink="/super-admin/supplier-user-activity">
              <a>Activity</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h4 class="text-theme mb-0"><strong>Supplier Information</strong></h4>
            <div class="d-flex gap-2">
              <button class="btn btn-primary btn-sm" (click)="goBack()">
                <i class="bi bi-arrow-left"></i> Back to List
              </button>
              <a class="btn btn-primary btn-sm" routerLink="/super-admin/supplier-user-profile-edit"
                [state]="supplierDetails">
                <i class="bi bi-pencil-fill"></i> Edit
              </a>
              <button class="btn btn-danger btn-sm" (click)="deleteSupplier(supplierDetails?._id)">
                <i class="bi bi-trash3-fill"></i> Delete
              </button>
            </div>
          </div>

          <!-- Loading spinner -->
          <div *ngIf="showLoader" class="text-center py-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <!-- Company Details -->
          <div *ngIf="!showLoader" class="card mb-4">
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-12">
                  <h5 class="text-primary mb-3">Company Details</h5>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Company Name</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.companyName" readonly>
                </div>
                <!-- <div class="col-md-6 mb-3">
                  <label class="form-label">Logo URL</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.logo" readonly>
                </div> -->
                <div class="col-md-6 mb-3">
                  <label class="form-label">Website</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.website" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Company Address</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.companyAddress" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Country</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.country" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Contact Email</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.email" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Contact Number</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.companyContactNumber" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Year of Establishment</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.yearOfEstablishment | date:'dd/MM/yyyy'" readonly>
                </div>
              </div>

              <!-- POC Details -->
              <div class="row mb-4">
                <div class="col-12">
                  <h5 class="text-primary mb-3">POC Details</h5>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">POC Name</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.poc_name" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">POC Phone</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.poc_phone" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">POC Email</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.poc_email" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">POC Role</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.poc_role" readonly>
                </div>
              </div>

              <!-- Business Details -->
              <div class="row mb-4">
                <div class="col-12">
                  <h5 class="text-primary mb-3">Business Details</h5>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Business Type</label>
                  <div class="border rounded p-3">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let type of supplierDetails?.typeOfCompany" class="badge bg-primary me-2 mb-2">
                        {{ type }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Industry Focus</label>
                  <div class="border rounded p-3">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let industry of supplierDetails?.industry_Sector" class="badge bg-primary me-2 mb-2">
                        {{ industry }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Company Size</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.employeeCount" readonly>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Certifications</label>
                  <div class="border rounded p-3">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let cert of supplierDetails?.certifications" class="badge bg-primary me-2 mb-2">
                        {{ cert }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Expertise</label>
                  <div class="border rounded p-3">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let exp of supplierDetails?.expertise" class="badge bg-primary me-2 mb-2">
                        {{ exp.name }}
                        <!-- <ul *ngIf="exp.subExpertise && exp.subExpertise.length" class="list-unstyled mt-2">
                          <li *ngFor="let sub of exp.subExpertise" class="badge bg-secondary me-2 mb-2">
                            {{ sub }}
                          </li>
                        </ul> -->
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Category</label>
                  <div class="border rounded p-3">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let cat of supplierDetails?.categoryList" class="badge bg-primary me-2 mb-2">
                        {{ cat }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Technologies</label>
                  <div class="border rounded p-3">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let tech of supplierDetails?.technologyStack" class="badge bg-primary me-2 mb-2">
                        {{ tech }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Key Clients -->
              <div class="row">
                <div class="col-12">
                  <h5 class="text-primary mb-3">Client Information</h5>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">Key Clients</label>
                  <div class="border rounded p-3">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let client of supplierDetails?.keyClients" class="badge bg-primary me-2 mb-2">
                        {{ client }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Status Information -->
              <div class="row mb-4">
                <div class="col-12">
                  <h5 class="text-primary mb-3">Status Information</h5>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="form-label">Current Status</label>
                  <div class="d-flex align-items-center">
                    <span class="badge" [ngClass]="supplierDetails?.active ? 'bg-success' : 'bg-danger'">
                      {{supplierDetails?.active ? 'Active' : 'Inactive'}}
                    </span>
                  </div>
                </div>
                <!-- <div class="col-md-4 mb-3">
                  <label class="form-label">Active Since</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.activeSince || 'N/A'" readonly>
                </div> -->
                <div class="col-md-4 mb-3">
                  <label class="form-label">Status Details</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.activeStatus || 'N/A'" readonly>
                </div>
                <div class="col-md-4 mb-3" *ngIf="!supplierDetails?.active">
                  <label class="form-label">Inactive Date</label>
                  <input type="text" class="form-control" [value]="supplierDetails?.inactiveDate | date:'dd/MM/yyyy HH:mm'" readonly>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<section class="casestudy-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10 col-8">
        <h4 class="text-theme mb-4"><strong>Users</strong></h4>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped align-middle">
        <thead>
          <tr>
            <th></th>
            <th>User Name</th>
            <th>Mail ID</th>
            <th>User ID</th>
            <th>Domain</th>
            <th>Department</th>
            <th>Joined</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td *ngIf="!manageUserList?.length" colspan="8">
              <app-no-record-found></app-no-record-found>
            </td>
          </tr>

          <tr
            *ngFor="let item of manageUserList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
            <td>
              <div class="avtar32">
                <img src="assets/img/face1.jpg" />
              </div>
            </td>
            <td><a href="javascript:;">{{item?.userName}}</a></td>
            <td><a href="javascript:;">{{item?.email}}</a></td>
            <td>{{item?._id}}</td>
            <td>{{item?.domain}}</td>
            <td>{{item?.department}}</td>
            <td>{{item?.doj | date : 'dd/MM/yyyy'}}</td>
            <td><button (click)="deleteUser(item?._id)" class="btn btn-sm btn-secondary">Remove</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
