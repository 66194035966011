<main id="main">
  <section>
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-12 col-xl-12 col-12 d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center">
            <h4 class="me-3"><strong>Project Title:</strong></h4>
            <h4>{{ projectDetails?.projectName }}</h4>
          </div>
          <div class="ms-auto d-flex">
            <a data-bs-toggle="modal" data-bs-target="#ViewStripAdd" class="btn btn-primary btn-sm text-dark me-2">
              Add Strips
            </a>

            <a routerLink="/feasibility-manager/feasibility-manager-projects-to-actions"
              class="btn btn-primary text-dark">Back</a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-xl-12 col-12 mb-12 mt-5 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2"><strong>BOS ID:</strong></div>
              <div class="col-md-10">{{ projectDetails?.BOSID || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Publish Date:</strong></div>
              <div class="col-md-10">
                {{
                projectDetails?.publishDate
                ? (projectDetails?.publishDate | date : "dd/MM/yyyy")
                : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Category:</strong></div>
              <div class="col-md-10">{{ projectDetails?.category || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Industry:</strong></div>
              <div class="col-md-10">{{ projectDetails?.industry || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Min value (GBP):</strong></div>
              <div class="col-md-10">
                GBP {{ projectDetails?.minValue || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Max value (GBP):</strong></div>
              <div class="col-md-10">
                GBP {{ projectDetails?.maxValue || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Website:</strong></div>
              <div class="col-md-10">{{ projectDetails?.website || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Link to notice:</strong></div>
              <div class="col-md-10">
                <a *ngIf="projectDetails?.link; else noLink" href="{{ projectDetails?.link }}" target="_blank">Link to
                  Notice</a>
                <ng-template #noLink>-</ng-template>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Created Date:</strong></div>
              <div class="col-md-10">
                {{
                projectDetails?.createdAt
                ? (projectDetails?.createdAt | date : "dd/MM/yyyy")
                : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Due Date & Time:</strong></div>
              <div class="col-md-10">
                {{
                projectDetails?.dueDate
                ? (projectDetails?.dueDate | date : "dd/MM/yyyy, h:mm a")
                : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>CPV Codes:</strong></div>
              <div class="col-md-10">{{ projectDetails?.CPVCodes || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Notice Reference:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.noticeReference || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Status:</strong></div>
              <div class="col-md-10">{{ projectDetails?.status || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Project Type:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.projectType || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Mail Id:</strong></div>
              <div class="col-md-10">{{ projectDetails?.mailID || "-" }}</div>
            </div>

            <div class="row">
              <div class="col-md-2"><strong>Client Type:</strong></div>
              <div class="col-md-10">{{ projectDetails?.clientType || "-" }}</div>
            </div>


            <div class="row">
              <div class="col-md-2"><strong>Client Name:</strong></div>
              <div class="col-md-10">{{ projectDetails?.clientName || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Categorisation:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.categorisation || "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row" *ngIf="loginUser?.role === 'FeasibilityAdmin'">
        <div class="col-md-2"><strong>Select Assign User:</strong></div>
        <ng-select id="assignTo" class="form-select custom-ng-select-option" placeholder="Select Assign User"
          [multiple]="true" bindLabel="userName" bindValue="_id" [items]="userList"
          [(ngModel)]="projectDetails.assignedUsers" (change)="
            appointFeasibilityUser(projectDetails.assignedUsers, projectDetails)
          ">
        </ng-select>
      </div><br /> -->

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Project Description</strong></p>
        </div>
      </section>
      <br />

      <p>{{ projectDetails?.description }}</p>

      <section class="pageheader bg-dark mb-3" *ngIf="projectDetails?.status == 'Awaiting'">
        <div class="container">
          <p><strong>Assign Feasibility User : {{projectDetails?.assignFeasibilityUser[0]?.name}}</strong></p>
        </div>
      </section>

      <div class="row align-items-end mb-4" *ngIf="projectDetails?.status == 'Awaiting'">
        <div class="col-lg-5 col-md-5 col-sm-12">
          <div class="detail-item">
            <!-- <p><strong>Assign Bid User : {{projectDetails?.assignBidmanager[0]?.name}}</strong></p> -->
            <ng-select style="padding: 0px !important" placeholder="Select Assign User"
              class="form-select custom-ng-select-option" bindLabel="userName" bindValue="_id"
              [items]="FeasibilityuserList" [(ngModel)]="assignTo">
            </ng-select>
          </div>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-12">
          <div class="detail-item">
            <label>Due Date</label>
            <div class="input-group">
              <input class="form-control" placeholder="DD-MM-YYYY" [(ngModel)]="dueDate" ngbDatepicker
                #endDatePicker="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="endDatePicker.toggle()"
                type="button"></button>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 text-end">
          <button type="button" class="btn btn-primary" (click)="assignUser()">
            Submit
          </button>
        </div>
      </div>

      <!-- <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Client & FOI Detils</strong></p>
        </div>
      </section> -->

      <!-- <div class="row mt-5">
        <div class="col-lg-4 col-xl-4 col-12 mb-4">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-4 col-xl-4 col-12">
                  <h6 class="text-theme"><strong>Client Detils</strong></h6>
                </div>
                <div class="col-lg-7 col-xl-7 col-12">
                  <p class="p-13 mb-0">
                    Mail ID : {{ projectDetails?.mailID }}
                  </p>
                  <p class="p-13 mb-0">
                    Client Type : {{ projectDetails?.clientType }}
                  </p>
                  <p class="p-13 mb-0">
                    Client Name : {{ projectDetails?.clientName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-4 col-12 mb-4">
          <div class="row align-items-center">
            <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
              <p class="mb-0">FOI Screenshot</p>
            </div>
            <div class="row align-items-center" *ngIf="projectDetails?.mailScreenshots?.length == 0">
              <div class="col-lg-12 col-xl-12 col-12 mob-mb1">
                <p class="mb-0">No Record Found.</p>
              </div>
            </div>
            <ng-container *ngIf="projectDetails?.mailScreenshots?.length > 0">
              <div class="row align-items-center" *ngFor="let foiScreenshot of projectDetails?.mailScreenshots">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                  <p class="mb-0">{{ foiScreenshot?.name }}</p>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a (click)="openDocument(foiScreenshot)" class="btn btn-small w-100" data-bs-toggle="modal"
                    data-bs-target="#ViewScreenshot"><i class="bi bi-eye-fill"></i> View</a>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a (click)="
                      download(foiScreenshot?.link?.url, foiScreenshot?.name)
                    " target="_blank" class="btn btn-small w-100"><i class="bi bi-file-earmark-arrow-down-fill"></i>
                    Download</a>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
              <p class="mb-0">FOI Document</p>
            </div>
            <div class="row align-items-center" *ngIf="projectDetails?.fois?.length == 0">
              <div class="col-lg-12 col-xl-12 col-12 mob-mb1">
                <p class="mb-0">No Record Found.</p>
              </div>
            </div>
            <ng-container *ngIf="projectDetails?.fois?.length > 0">
              <div class="row align-items-center" *ngFor="let foiDocument of projectDetails?.fois">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                  <p class="mb-0">{{ foiDocument?.name }}</p>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a (click)="openDocument(foiDocument)" class="btn btn-small w-100" data-bs-toggle="modal"
                    data-bs-target="#ViewDocumentModal"><i class="bi bi-eye-fill"></i> View</a>
                </div>
                <div class="col-lg-4 col-xl-4 col-6">
                  <a style="
                      padding-left: 0 !important;
                      padding-right: 0px !important;
                    " (click)="
                      download(foiDocument?.link?.url, foiDocument?.name)
                    " target="_blank" class="btn btn-small w-100"><i class="bi bi-file-earmark-arrow-down-fill"></i>
                    Download</a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-4 col-xl-4 col-12 mb-4">
          <div class="card">
            <div class="card-body text-center">
              <ng-container *ngIf="!isEditing; else editMode">
                <p class="p-13">
                  <strong>
                    Period of Contract:
                    {{
                    projectDetails?.periodOfContractStart
                    | date : "dd/MM/yyyy"
                    }}
                    -
                    {{
                    projectDetails?.periodOfContractEnd | date : "dd/MM/yyyy"
                    }}
                  </strong>
                </p>
                <p class="p-13">
                  <strong>Project Type: {{ projectDetails?.projectType }}</strong>
                </p>
                <a class="btn btn-secondary sm ml-2" (click)="toggleEdit()"><i class="bi bi-pencil-fill"></i></a>
              </ng-container>
              <ng-template #editMode>
                <p class="p-13">
                  <strong>
                    Period of Contract:
                    <input type="date" [(ngModel)]="projectDetails.periodOfContractStart" />
                    -
                    <input type="date" [(ngModel)]="projectDetails.periodOfContractEnd" />
                  </strong>
                </p>
                <p class="p-13">
                  <strong>
                    Project Type:
                    <input type="text" [(ngModel)]="projectDetails.projectType" />
                  </strong>
                </p>
                <a class="btn btn-primary sm" title="Save" (click)="saveChanges('save', true)"><i
                    class="bi bi-check2"></i></a>&nbsp;
                <a class="btn btn-warning ml-2" title="Cancel" (click)="toggleEdit()"><i class="bi bi-x-lg"></i></a>
              </ng-template>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Upload Sub-contracting</strong></p>
        </div>
      </section>
      <br /> -->

      <!-- <div class="row align-items-center">
        <div class="col-lg-9 col-xl-9 col-12">
          <div class="row">
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <label class="display-block">Sub-contracting</label> <br />
                <div class="upload-btn-wrapper">
                  <label for="subContract" class="btn-upload-file padding-lr-5">
                    <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                    Query Image
                  </label>
                  <input id="subContract" type="file" name="subContract" style="height: 5px; width: 5px" (change)="
                      uploadDocument(
                        $event,
                        documentUploadType.subContractDocument
                      )
                    " />
                  <br />
                  <p>{{ subContractDocument?.fileName }}</p>

                  <div class="d-flex justify-content-between">
                    <a *ngIf="subContractDocument" (click)="openUploadedDocument(subContractDocument)"
                      class="btn btn-small me-2" data-bs-toggle="modal" data-bs-target="#ViewUploadedDocument">
                      <i class="bi bi-eye-fill"></i> View
                    </a>

                    <a *ngIf="subContractDocument" (click)="
                        removeDocument(documentUploadType.subContractDocument)
                      " class="btn btn-danger">
                      <i class="bi bi-trash-fill"></i> Remove
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <label class="display-block">Economical partnership</label>
                <br />
                <div class="upload-btn-wrapper">
                  <label for="economicalQuryDocument" class="btn-upload-file padding-lr-5">
                    <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                    Query Image
                  </label>
                  <input type="file" id="economicalQuryDocument" name="economicalQuryDocument"
                    style="height: 5px; width: 5px" (change)="
                      uploadDocument(
                        $event,
                        documentUploadType.economicalPartnershipQuery
                      )
                    " />
                  <br />
                  <p>{{ economicalPartnershipQueryFile?.fileName }}</p>

                  <div class="d-flex justify-content-between">
                    <a *ngIf="economicalPartnershipQueryFile" (click)="
                        openUploadedDocument(economicalPartnershipQueryFile)
                      " class="btn btn-small me-2" data-bs-toggle="modal" data-bs-target="#ViewUploadedDocument">
                      <i class="bi bi-eye-fill"></i> View
                    </a>

                    <a *ngIf="economicalPartnershipQueryFile" (click)="
                        removeDocument(
                          documentUploadType.economicalPartnershipQuery
                        )
                      " class="btn btn-danger">
                      <i class="bi bi-trash-fill"></i> Remove
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <label class="display-block">Other</label>
                <br />
                <div class="upload-btn-wrapper">
                  <label class="btn-upload-file" for="otherDocument">
                    <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                    Query Image
                  </label>
                  <input type="file" name="myfile" id="otherDocument" style="height: 5px; width: 5px" (change)="
                      uploadDocument(
                        $event,
                        documentUploadType.otherQueryDocument
                      )
                    " />
                  <br />
                  <p>{{ documentUploadType?.otherQueryDocument?.fileName }}</p>

                  <ng-container *ngFor="let document of FeasibilityOtherDocuments">
                    <ng-container *ngIf="
                        document?.type === documentUploadType.otherQueryDocument
                      ">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <a (click)="openUploadedDocument(document?.file)" class="btn btn-small me-2"
                          data-bs-toggle="modal" data-bs-target="#ViewUploadedDocument">
                          <i class="bi bi-eye-fill"></i> View
                        </a>

                        <a (click)="removeOtherDocument(document)" class="btn btn-danger">
                          <i class="bi bi-trash-fill"></i> Remove
                        </a>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-check form-check-inline mt-3">
                <input class="form-check-input" type="radio" name="inlineRadioOptionsForSubContract" id="inlineRadio1"
                  value="true" [checked]="subContracting === true" (change)="subContactChange('true')" />
                <label class="form-check-label" for="inlineRadio1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptionsForSubContract" id="inlineRadio2"
                  value="true" [checked]="subContracting === false" (change)="subContactChange('false')" />
                <label class="form-check-label" for="inlineRadio2">No</label>
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <div class="upload-btn-wrapper">
                  <label for="economicalResponseDocument" class="btn-upload-file padding-lr-5">
                    <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                    Response
                  </label>
                  <input type="file" id="economicalResponseDocument" name="myfile" style="height: 5px; width: 5px"
                    (change)="
                      uploadDocument(
                        $event,
                        documentUploadType.economicalPartnershipResponse
                      )
                    " />
                  <br />
                  <p *ngIf="economicalPartnershipResponceFile">
                    {{ economicalPartnershipResponceFile?.fileName }}
                  </p>

                  <div class="d-flex justify-content-between">
                    <a *ngIf="economicalPartnershipResponceFile" (click)="
                        openUploadedDocument(economicalPartnershipResponceFile)
                      " class="btn btn-small me-2" data-bs-toggle="modal" data-bs-target="#ViewUploadedDocument">
                      <i class="bi bi-eye-fill"></i> View
                    </a>

                    <a *ngIf="economicalPartnershipResponceFile" (click)="
                        removeDocument(
                          documentUploadType.economicalPartnershipResponse
                        )
                      " class="btn btn-danger">
                      <i class="bi bi-trash-fill"></i> Remove
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div class="form-group">
                <div class="upload-btn-wrapper">
                  <label class="btn-upload-file" for="otherDocument1">
                    <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                    Document
                  </label>
                  <input type="file" name="myfile" style="height: 5px; width: 5px" id="otherDocument1" (change)="
                      uploadDocument($event, documentUploadType.otherDocument)
                    " />
                  <br />
                  <ng-container *ngFor="let document of FeasibilityOtherDocuments">
                    <ng-container *ngIf="
                        document?.type === documentUploadType.otherDocument
                      ">
                      <p>{{ document.file?.fileName }}</p>

                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <a (click)="openUploadedDocument(document?.file)" class="btn btn-small me-2"
                          data-bs-toggle="modal" data-bs-target="#ViewUploadedDocument">
                          <i class="bi bi-eye-fill"></i> View
                        </a>

                        <a (click)="removeOtherDocument(document)" class="btn btn-danger">
                          <i class="bi bi-trash-fill"></i> Remove
                        </a>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-xl-3 col-12">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-12 col-xl-12 col-12">
                  <textarea [(ngModel)]="comment" placeholder="Enter Comment" class="form-control" rows="3"
                    cols="100"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Documents</strong></p>
        </div>
      </section>
      <br />

      <div class="row mb-4">
        <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
          <h5>Client Documents</h5>
          <div class="card">
            <ul class="project-document">
              <li *ngFor="let item of projectDetails?.clientDocument">
                <div class="row">
                  <div class="col-5 text-center">
                    <p class="mb-0">
                      {{ item?.name }}
                    </p>
                  </div>
                  <div class="col-7 text-center">
                    <a href="href:;" class="btn btn-primarysm me-2" data-bs-toggle="modal"
                      data-bs-target="#ViewClientDoc" (click)="openDocument(item)"><i class="bi bi-eye-fill"></i>
                      View</a>
                    <a href="href:;" (click)="download(item?.file?.url, item?.name)" class="btn btn-primarysm"><i
                        class="bi bi-file-earmark-arrow-down-fill"></i>
                      Download</a>
                  </div>
                </div>
              </li>
            </ul>
            <button data-bs-toggle="modal" data-bs-target="#addClientDocument" type="button"
              class="btn btn-dark btn-sm add-card-btn">
              <i class="bi bi-plus-lg"></i>
            </button>
            <!-- (click)="hideShowForm()" -->
          </div>
        </div>

        <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
          <h5>WestGate Documents</h5>
          <div class="card">
            <ul class="project-document">
              <!-- Document List -->
              <li *ngFor="let item of projectDetails?.projectComment" class="list-group-item">
                <div class="row">
                  <div class="col-5 text-center">
                    <p class="mb-0">{{ item?.comment }}</p>
                  </div>
                  <div class="col-7 text-center">
                    <a href="href:;" class="btn btn-primarysm me-2" data-bs-toggle="modal"
                      data-bs-target="#ViewClientDoc" (click)="openDocument(item)">
                      <i class="bi bi-eye-fill"></i> View
                    </a>
                    <a href="href:;" class="btn btn-primarysm" (click)="download(item?.file?.url, item?.name)">
                      <i class="bi bi-file-earmark-arrow-down-fill"></i>
                      Download
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <button data-bs-toggle="modal" data-bs-target="#addWestgateDocument" type="button"
              class="btn btn-dark btn-sm add-card-btn">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-xl-12 col-12 mb-2">
        <div class="card shadow-sm p-4">
          <!-- Header -->
          <div class="card-header bg-primary text-white mb-3">
            <h5 class="mb-0">Feasibility Status : {{projectDetails?.status}}</h5>
          </div>

          <!-- Status Buttons -->
          <div class="d-flex justify-content-between flex-wrap mb-4"
            *ngIf="projectDetails?.status !== 'Fail' && projectDetails?.status !== 'Passed'">
            <button class="btn btn-outline-primary flex-fill m-1" [class.active]="status === 'InProgress'"
              (click)="statusChange('InProgress')">
              In-Progress
            </button>
            <button class="btn btn-outline-secondary flex-fill m-1" [class.active]="status === 'InHold'"
              (click)="statusChange('InHold')">
              In-Hold
            </button>
            <button class="btn btn-outline-success flex-fill m-1" [class.active]="status === 'Passed'"
              (click)="statusChange('Passed')">
              Pass
            </button>
            <button class="btn btn-outline-danger flex-fill m-1" [class.active]="status === 'Fail'"
              (click)="statusChange('Fail')">
              Fail
            </button>
            <button class="btn btn-outline-warning flex-fill m-1" [class.active]="status === 'DocumentsNotFound'"
              (click)="statusChange('DocumentsNotFound')">
              Document Not Found
            </button>
          </div>

          <!-- Dynamic Comment Section -->
          <div *ngIf="status" class="status-section">
            <h6 class="text-secondary mb-3">{{ status }} Comments</h6>

            <!-- Display Existing Comments -->
            <div *ngIf="commentData?.length" class="comment-list comments-section">
              <div class="comment-item border rounded p-3 mb-2" *ngFor="let item of commentData">
                <!-- <div class="d-flex justify-content-between align-items-center">
                  <span class="text-muted"><strong>Comment:</strong> {{ item.comment }}</span>
                  <span class="text-muted"><strong>Date:</strong>
                    {{ item.date | date : "dd/MM/yyyy, h:mm a" }}</span>
                </div> -->
                <div class="comment mb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0 fw-bold">
                      {{ item?.userDetails?.name }}
                    </p>
                    <small class="text-muted">
                      {{ item?.date | date : "dd/MM/yyyy, h:mm a" }}
                    </small>
                  </div>
                  <small class="text-muted d-block"><strong>Status :</strong> {{ item?.status }}</small>
                  <p class="mt-2">{{ item?.comment }}</p>
                </div>
              </div>
            </div>
            <!-- Add New Comment -->
            <div class="add-comment d-flex gap-3 align-items-center mt-3 mb-2" *ngIf="status !== 'Fail'">
              <input type="text" class="form-control w-80" [formControl]="statusComment"
                placeholder="Add your comment" />
              <button class="btn btn-primary" type="button" (click)="pushStatus()">
                <i class="bi bi-plus-lg"></i> Add
              </button>
            </div>

            <!-- Fail Specific Fields -->
            <div *ngIf="status === 'Fail' && projectDetails?.status !== 'Fail'" class="mt-4">
              <div class="d-flex align-items-center gap-3">
                <select class="form-select" [(ngModel)]="selectedFailReason">
                  <option disabled selected hidden value="">
                    Select Fail Reason
                  </option>
                  <option value="Accreditation">Accreditation</option>
                  <option value="Time Constraints">Time Constraints</option>
                  <option value="Certification">Certification</option>
                  <option value="Case Study">Case Study</option>
                  <option value="Pre-Market">Pre-Market</option>
                  <option value="Certifications from Subcontractor">
                    Certifications from Subcontractor
                  </option>
                  <option value="Delivery of goods/Hardware">
                    Delivery of goods/Hardware
                  </option>
                  <option value="Financial Conditions">
                    Financial Conditions
                  </option>
                  <option value="Not related">Not related</option>
                  <option value="No Subcontracting">No Subcontracting</option>
                  <option value="Other">Other</option>
                </select>
                <button class="btn btn-outline-primary" type="button" (click)="addFailReason()">
                  <i class="bi bi-plus-lg"></i>
                </button>
              </div>

              <!-- Display added reasons and comments -->
              <div class="mt-3">
                <div class="reason-item border rounded p-3 mb-2"
                  *ngFor="let reason of failStatusReasons; let i = index">
                  <div class="d-flex align-items-center">
                    <span class="me-3"><strong>Reason:</strong> {{ reason.tag }}</span>
                    <input type="text" class="form-control me-3" style="flex: 1" placeholder="Add your comment"
                      [(ngModel)]="reason.comment" />
                    <button class="btn btn-sm btn-danger" type="button" (click)="removeFailReason(i)">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-end mb-4">
              <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
                <a (click)="saveFeasibilityStatus('save')" [class.disabled]="!isCommentValid() && status !== 'Fail'"
                  class="btn btn-primary w-100">Save Status</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div class="row justify-content-end mb-4">
        <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
          <a (click)="summaryDetail('save')" class="btn btn-dark w-100">Save</a>
        </div>
      </div>

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>To-Do Task Comments</strong></p>
        </div>
      </section>
      <br />

      <div class="modal-body d-flex">
        <div class="flex-grow-1 pe-3">
          <div class="comments-section">
            <ng-container *ngIf="commentData!.length > 0; else noTasks">
              <div *ngFor="let comment of commentData" class="task-item">
                <ng-container>
                  <div class="comment mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 fw-bold">
                        {{ comment?.userDetails?.name }}
                      </p>
                      <small class="text-muted">
                        {{ comment?.date | date : "dd/MM/yyyy, h:mm a" }}
                      </small>
                    </div>
                    <small class="text-muted d-block"><strong>Status :</strong> {{ comment?.status }}</small>
                    <p class="mt-2">{{ comment?.comment }}</p>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <ng-template #noTasks>
            <p class="text-muted">No status comments available.</p>
          </ng-template>
        </div>
      </div>

      <div class="modal-body d-flex">
        <div class="flex-grow-1 pe-3">
          <div class="comments-section">
            <ng-container>
              <div *ngFor="let task of filteredTasks" class="task-item">
                <ng-container *ngIf="task?.comments?.length > 0; else noComments">
                  <div *ngFor="let comment of task.comments" class="comment mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 fw-bold">
                        {{ comment?.userDetail?.name }}
                      </p>
                      <small class="text-muted">
                        {{ comment?.date | date : "dd/MM/yyyy, h:mm a" }}
                      </small>
                    </div>
                    <small class="text-muted d-block">{{
                      comment?.userDetail?.role
                      }}</small>
                    <p class="mt-2">{{ comment?.comment }}</p>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-template #noComments>
        <p class="text-muted">No comments available.</p>
      </ng-template>

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Project Logs</strong></p>
        </div>
      </section>
      <br />

      <div class="modal-body d-flex">
        <div class="flex-grow-1 pe-3">
          <div class="comments-section">
            <div *ngIf="projectDetails?.logs?.length > 3" class="text-end mt-3">
              <a class="btn btn-link" data-bs-toggle="modal" data-bs-target="#ViewProjectLogs">
                View All
              </a>
            </div>
            <!-- List of logs -->
            <ul class="SecondarytaskList mt-3">
              <li *ngFor="let log of showAllLogs ? logs : logs.slice(0, 3)" class="mb-3">
                <div class="comment-item">
                  <div class="d-flex justify-content-between">
                    <p class="mb-0" [innerHTML]="log?.log"></p>
                    <span class="text-muted">
                      {{ log?.date | date : "dd/MM/yyyy, h:mm a" }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- No records found -->
            <p *ngIf="logs?.length === 0" class="text-center">
              No records found
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>

</main>

<div class="modal fade" id="ViewProjectLogs" tabindex="-1" aria-labelledby="ViewProjectLogsLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewProjectLogsLabel">Project Logs</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-flex">
        <div class="flex-grow-1 pe-3">
          <div class="comments-section">
            <ul class="SecondarytaskList mt-3">
              <li *ngFor="let log of projectDetails?.logs" class="mb-3">
                <div class="comment-item">
                  <div class="d-flex justify-content-between">
                    <p class="mb-0" [innerHTML]="log?.log"></p>
                    <span class="text-muted">
                      {{ log?.date | date : "dd/MM/yyyy, h:mm a" }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- No records found -->
            <p *ngIf="logs?.length === 0" class="text-center">
              No records found
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ViewScreenshot" tabindex="-1" aria-labelledby="ViewScreenshotLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewScreenshotLabel">
          FOI Screenshot : {{ selectedDocument?.name }}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img [src]="selectedDocument?.link?.url" class="img-fluid" />
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addClientDocument" tabindex="-1" aria-labelledby="addClientDocumentLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewScreenshotLabel">
          Add Client Document
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="viewClientDocumentForm">
          <div class="card p-3 mt-3">
            <div class="row g-3 align-items-center">
              <!-- Document Name Input -->
              <div class="col-md-8 col-sm-12">
                <input type="text" [(ngModel)]="documentName" class="form-control" placeholder="Enter Document Name"
                  aria-label="Document Name" />
              </div>
              <!-- Upload Button -->
              <div class="col-md-4 col-sm-12">
                <label class="btn btn-outline-primary w-100">
                  <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                  <input type="file" id="uploadClientDocument" (change)="
                      uploadDocument($event, documentUploadType.clientDocument)
                    " hidden aria-label="Upload Document" />
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addWestgateDocument" tabindex="-1" aria-labelledby="addWestgateDocumentLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewScreenshotLabel">
          Add Westgate Document
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="viewClientDocumentForm">
          <div class="card p-3 mt-3">
            <div class="row g-3 align-items-center">
              <!-- Comment Input -->
              <div class="col-md-8 col-sm-12">
                <input type="text" [(ngModel)]="commentName" [ngModelOptions]="{ standalone: true }"
                  class="form-control" placeholder="Enter Document Name" aria-label="Document Name" />
              </div>
              <!-- Upload Button -->
              <div class="col-md-4 col-sm-12">
                <label class="btn btn-outline-primary w-100">
                  <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                  <input type="file" id="uploadClientDocument" (change)="uploadDocuments($event)" hidden
                    aria-label="Upload Document" />
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ViewClientDoc" tabindex="-1" aria-labelledby="ViewClientDocLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewClientDocLabel">
          Client Document : {{ selectedDocument?.name }}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="alert alert-danger" role="alert">
        ⚠ Important Notice : If you are unable to view any documents, please
        note that the files may have been uploaded as a ZIP file. Kindly
        download the ZIP file to access the documents.
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isPdf(selectedDocument?.file?.url); else otherFormats">
          <ngx-extended-pdf-viewer [src]="selectedDocument?.file?.url" useBrowserLocale="true"
            height="600px"></ngx-extended-pdf-viewer>
        </ng-container>
        <ng-template #otherFormats>
          <iframe *ngIf="isWordOrExcel(selectedDocument?.file?.url)"
            [src]="getDocumentViewerUrl(selectedDocument?.file?.url)" style="width: 100%; height: 600px"></iframe>
          <img *ngIf="isImage(selectedDocument?.file?.url)" [src]="selectedDocument?.file?.url" class="img-fluid" />
        </ng-template>
      </div>
      <!-- <img [src]="selectedDocument?.file?.url" class="img-fluid" /> -->
    </div>
  </div>
</div>

<div class="modal fade" id="ViewDocumentModal" tabindex="-1" aria-labelledby="ViewDocumentModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between">
        <h5 class="modal-title" id="ViewDocumentModalLabel">
          FOI Document ({{ selectedDocument?.name }})
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="alert alert-danger" role="alert">
        ⚠ Important Notice : If you are unable to view any documents, please
        note that the files may have been uploaded as a ZIP file. Kindly
        download the ZIP file to access the documents.
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isPdf(selectedDocument?.link?.url); else otherFormats">
          <ngx-extended-pdf-viewer [src]="selectedDocument?.link?.url" useBrowserLocale="true"
            height="600px"></ngx-extended-pdf-viewer>
        </ng-container>
        <ng-template #otherFormats>
          <iframe *ngIf="isWordOrExcel(selectedDocument?.link?.url)"
            [src]="getDocumentViewerUrl(selectedDocument?.link?.url)" style="width: 100%; height: 600px"></iframe>
          <img *ngIf="isImage(selectedDocument?.link?.url)" [src]="selectedDocument?.link?.url" class="img-fluid" />
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ViewUploadedDocument" tabindex="-1" aria-labelledby="ViewUploadedDocumentModal"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between">
        <h5 class="modal-title" id="ViewUploadedDocumentModal">Document</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="alert alert-danger" role="alert">
        ⚠ Important Notice : If you are unable to view any documents, please
        note that the files may have been uploaded as a ZIP file. Kindly
        download the ZIP file to access the documents.
      </div>
      <div class="modal-body" style="text-align: center">
        <ng-container *ngIf="isPdf(uploadedDocument?.url); else otherFormatsForDocument">
          <ngx-extended-pdf-viewer [src]="uploadedDocument?.url" useBrowserLocale="true"
            height="600px"></ngx-extended-pdf-viewer>
        </ng-container>
        <ng-template #otherFormatsForDocument>
          <iframe *ngIf="isWordOrExcel(uploadedDocument?.url)" [src]="getDocumentViewerUrl(uploadedDocument?.url)"
            style="width: 100%; height: 600px"></iframe>
          <img *ngIf="isImage(uploadedDocument?.url)" [src]="uploadedDocument?.url" class="img-fluid" />
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="loginDetailModal" tabindex="-1" aria-labelledby="ViewLogin" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" [formGroup]="loginDetailForm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewLogin">Add Login Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label>Company Name</label>
        <input [readonly]="loginModalMode" type="text" formControlName="companyName" class="form-control" />
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['companyName'].touched && loginDetailForm.controls['companyName']?.errors?.['required']">
          Please enter Company Name
        </div>
        <label>Website Link</label>
        <input [readonly]="loginModalMode" type="text" formControlName="link" class="form-control" />
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['link'].touched && loginDetailForm.controls['link']?.errors?.['required']">
          Please enter Website Link
        </div>
        <label>Login Id</label>
        <input [readonly]="loginModalMode" type="text" formControlName="loginID" class="form-control" />
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['loginID'].touched && loginDetailForm.controls['loginID']?.errors?.['required']">
          Please enter Login Id
        </div>
        <label>Password</label>
        <div style="position: relative">
          <input [readonly]="loginModalMode" [type]="password" formControlName="password" class="form-control" />
          <span class="showicon" style="
              position: absolute;
              top: 0;
              right: 0;
              margin-top: 1rem;
              margin-right: 1rem;
            " *ngIf="!showPassword" (click)="showHidePass()"><i class="bi bi-eye-fill"></i></span>
          <span class="showicon" style="
              position: absolute;
              top: 0;
              right: 0;
              margin-top: 1rem;
              margin-right: 1rem;
            " *ngIf="showPassword" (click)="showHidePass()"><i class="bi bi-eye-slash-fill"></i></span>
        </div>
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['password'].touched && loginDetailForm.controls['password']?.errors?.['required']">
          Please enter Password
        </div>
        <div *ngIf="!loginModalMode" class="row justify-content-end">
          <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end mt-3">
            <button (click)="addLoginInfo()" data-bs-dismiss="modal" aria-label="Close"
              [disabled]="!loginDetailForm.valid" class="btn btn-dark w-100">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<section *ngFor="let item of projectStrips">
  <div class="container-fluid">
    <!-- Black Strip Header -->
    <div class="bg-dark py-3">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <div class="container d-flex justify-content-between align-items-center">
            <p class="mb-1 text-white fw-bold">{{ item?.text }}</p>
          </div>
          <br />
          <!-- Add Button -->
          <div *ngIf="item.type === 'Image'">
            <button class="btn btn-primary btn-sm" data-bs-toggle="modal"
              [attr.data-bs-target]="'#imageModal_' + item._id">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />

    <!-- Redesigned Description -->
    <div class="bg-white py-3" *ngIf="item?.type != 'Image'">
      <p class="text-secondary mb-0 fw-bold"> </p>
      <p class="text-dark mb-0">{{ item?.description }}</p>
    </div>

    <!-- <div class="container py-4">
          <div class="row mt-3" *ngIf="item?.images?.length">
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3" *ngFor="let image of item?.images">
              <div class="card h-100">
                <div class="card-body">
                  <p class="card-text fw-semibold">{{ image?.imageText }}</p>
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-outline-secondary btn-sm" data-bs-toggle="modal" (click)="openViewImage(image)"
                      data-bs-target="#viewstripimage">
                      <i class="bi bi-eye-fill"></i> View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

    <div class="row align-items-center">
      <div class="col-lg-12 col-xl-12 col-12">
        <div class="d-flex flex-row flex-wrap">
          <div class="col-lg-auto col-xl-auto col-md-auto col-sm-auto col-auto me-3" *ngFor="let image of item?.images">
            <div class="form-group">
              <label class="display-block">{{ image?.imageText }}</label> <br />
              <div class="upload-btn-wrapper">
                <div class="d-flex justify-content-between">
                  <a *ngIf="subContractDocument" (click)="openViewImage(image)" class="btn btn-small me-2"
                    data-bs-toggle="modal" data-bs-target="#viewstripimage">
                    <i class="bi bi-eye-fill"></i> View
                  </a>
                  <!-- Uncomment if remove functionality is needed -->
                  <!-- <a *ngIf="subContractDocument" (click)="removeDocument(documentUploadType.subContractDocument)" class="btn btn-danger">
                        <i class="bi bi-trash-fill"></i> Remove
                      </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for Adding Image Details -->
    <div class="modal fade" [id]="'imageModal_' + item._id" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Image Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Dynamic Input Fields -->
            <div *ngFor="let field of imageFields; let i = index" class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Enter Text" [(ngModel)]="field.text" />
              <input type="file" class="form-control" accept="image/*" (change)="onFileChange($event, i)" />
              <button type="button" class="btn btn-danger" (click)="removeField(i)"
                [disabled]="imageFields.length === 1">
                Delete
              </button>
            </div>
            <button type="button" class="btn btn-secondary btn-sm mt-3" (click)="addField()">
              Add More
            </button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveFields(item._id)" data-bs-dismiss="modal">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- View StripAdd Modal -->
<div class="modal fade" id="ViewStripAdd" tabindex="-1" aria-labelledby="ViewStripAddLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" [formGroup]="addStripForm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewLogin">Add Strip Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="modal-body">
        <!-- Radio Buttons to Select Upload Type -->
        <div class="form-group d-flex align-items-center mb-4">
          <label class="me-3">Select Type:</label>
          <div class="form-check me-3">
            <input type="radio" id="textOption" class="form-check-input" formControlName="type" value="Text"
              (change)="selectUploadType(true)" />
            <label for="textOption" class="form-check-label">Text</label>
          </div>
          <div class="form-check">
            <input type="radio" id="imageOption" class="form-check-input" formControlName="type" value="Image"
              (change)="selectUploadType(false)" />
            <label for="imageOption" class="form-check-label">Image</label>
          </div>
        </div>

        <!-- Conditional Rendering Based on Upload Type -->
        <div *ngIf="uploadType; else imageUploadSection">
          <!-- Text Upload Section -->
          <div class="form-group mb-3">
            <label for="textInput" class="form-label">Strip Heading :</label>
            <input type="text" id="textInput" class="form-control" placeholder="Enter Text" formControlName="text" />
            <div *ngIf="
                addStripcontrol.text.touched && addStripcontrol.text.invalid
              " class="text-danger">
              Text is required.
            </div>
          </div>

          <div class="form-group mb-3">
            <label for="textInput" class="form-label">Strip Description :</label>
            <textarea type="text" id="textInput" class="form-control" placeholder="Enter Description"
              formControlName="description"></textarea>
            <div *ngIf="
                addStripcontrol.description.touched &&
                addStripcontrol.description.invalid
              " class="text-danger">
              Description is required.
            </div>
          </div>

          <div class="form-group mb-3">
            <ng-select id="assignTo" class="form-select custom-ng-select-option" placeholder="Select Role"
              [multiple]="true" bindLabel="name" [items]="ForTitleuserList" formControlName="roles">
            </ng-select>
          </div>

        </div>

        <!-- Image Upload Section -->
        <ng-template #imageUploadSection>
          <div class="form-group mb-3">
            <label for="imageTextInput" class="form-label">Strip Heading :</label>
            <input type="text" id="imageTextInput" class="form-control" placeholder="Enter Text"
              formControlName="imageText" />
            <div *ngIf="
                addStripcontrol.imageText.touched &&
                addStripcontrol.imageText.invalid
              " class="text-danger">
              Image Text is required.
            </div>
          </div>

          <div class="form-group mb-3">
            <ng-select id="assignTo" class="form-select custom-ng-select-option" placeholder="Select Role"
              [multiple]="true" bindLabel="name" [items]="ForTitleuserList" formControlName="roles">
            </ng-select>
          </div>

        </ng-template>

        <div class="row justify-content-end mt-4">
          <div class="col-auto">
            <button (click)="addtitle()" data-bs-dismiss="modal" aria-label="Close" class="btn btn-dark">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- View Strip image Modal -->
<div class="modal fade" id="viewstripimage" tabindex="-1" aria-labelledby="viewstripimageLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between">
        <h5 class="modal-title" id="ViewDocumentModalLabel">
          Strip Image ({{ selectViewImage?.imageText }})
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isPdf(selectViewImage?.url); else otherFormats">
          <ngx-extended-pdf-viewer [src]="selectViewImage?.url" useBrowserLocale="true"
            height="600px"></ngx-extended-pdf-viewer>
        </ng-container>
        <ng-template #otherFormats>
          <iframe *ngIf="isWordOrExcel(selectViewImage?.url)" [src]="getDocumentViewerUrl(selectViewImage?.url)"
            style="width: 100%; height: 600px"></iframe>
          <img *ngIf="isImage(selectViewImage?.url)" [src]="selectViewImage?.url" class="img-fluid" />
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
