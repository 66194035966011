import { Component } from '@angular/core';

@Component({
  selector: 'app-pm-case-studies',
  templateUrl: './pm-case-studies.component.html',
  styleUrls: ['./pm-case-studies.component.scss']
})
export class PmCaseStudiesComponent {

}
