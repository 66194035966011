<body class="body-welcome-page">
  <main id="main">
    <section class="p-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6 col-12 desktop-pt200">
            <h1 class="Heading-h1"><strong>Welcome back!</strong> </h1>
            <a routerLink="/uk-writer/uk-writer-projects-all" class="btn btn-small">View Projects</a>
            <p class="mt-5">Total Projects Reviwed : {{ukwriterdashboardlist?.totalProjectsReviewed}}</p>
          </div>
        </div>
      </div>
    </section>
  
  
  </main><!-- End #main -->
</body>
