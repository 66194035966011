  <main id="main">
  <section class="chat-section">
    <div class="container">
      <div class="row justify-content-end">
        <!-- col-lg-9 col-xl-9 col-md-12 col-sm-12 -->
        <div class=" col-12">
          <div id="chatWindow">
            <div class="chatHistory">
              <ul>
                <li>
                  <div class="sent">
                    <p class="team-name">Team A</p>
                    <p>How the hell am I supposed to get a jury to believe you when I am not even sure that I do?!</p>
                    <p class="recived-time"><span class="read-tick"><i class="bi bi-check2-all"></i></span>3:15PM</p>
                  </div>
                </li>
                <li>
                  <div class="replies">
                    <p class="team-name">Team B</p>
                    <p>How the hell am I supposed to get a jury to believe you when I am not even sure that I do?!</p>
                    <p class="recived-time"><span class="unread-tick"><i class="bi bi-check2-all"></i></span>Just Now</p>
                  </div>
                </li>
                <li>
                  <div class="sent">
                    <p class="team-name">Team A</p>
                    <p>How the hell am I supposed to get a jury to believe you when I am not even sure that I do?!</p>
                    <p class="recived-time"><span class="read-tick"><i class="bi bi-check2-all"></i></span>3:15PM</p>
                  </div>
                </li>
                <li>
                  <div class="replies">
                    <p class="team-name">Team B</p>
                    <p>How the hell am I supposed to get a jury to believe you when I am not even sure that I do?!</p>
                    <p class="recived-time"><span class="unread-tick"><i class="bi bi-check2-all"></i></span>Just Now</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="chatsendBox">
              <div class="row align-items-center">
                <div class="col-lg-9 col-xl-9 col-12">
                  <div class="row align-items-center">
                    <div class="col-auto text-center">
                      <button type="button" class="btn text-theme"><i class="bi bi-mic-fill"></i></button>
                    </div>
                    <div class="col-9 p-0">
                      <input type="text" class="form-control border-0 ps-0" placeholder="Enter your text here!">
                    </div>
                    <div class="col-auto text-center">
                      <button type="button" class="btn text-theme"><i class="bi bi-send-fill p-22"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-xl-3 col-12">
                  <ul class="floatingbuttons">
                    <li><a href="javascript:;"><i class="bi bi-paperclip"></i></a></li>
                    <li><a href="javascript:;"><i class="bi bi-at"></i></a></li>
                    <li><a href="javascript:;"><i class="bi bi-emoji-smile-fill"></i></a></li>
                    <li><a href="javascript:;"><i class="bi bi-play-circle-fill"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <a href="javascript:;" class="google-meet"><img src="assets/img/google-meet.svg"/></a>
          </div>
          <div class="Mobile-chatsendBox">
            <div class="row align-items-center">
              <div class="col-lg-9 col-xl-9 col-12">
                <div class="row align-items-center">
                  <div class="col-2 text-center">
                    <button type="button" class="btn text-theme"><i class="bi bi-mic-fill"></i></button>
                  </div>
                  <div class="col-8 p-0">
                    <input type="text" class="form-control border-0 ps-0" placeholder="Enter your text here!">
                  </div>
                  <div class="col-2 text-center">
                    <button type="button" class="btn text-theme"><i class="bi bi-send-fill p-22"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-xl-3 col-12">
                <ul class="floatingbuttons">
                  <li><a href="javascript:;"><i class="bi bi-paperclip"></i></a></li>
                  <li><a href="javascript:;"><i class="bi bi-at"></i></a></li>
                  <li><a href="javascript:;"><i class="bi bi-emoji-smile-fill"></i></a></li>
                  <li><a href="javascript:;"><i class="bi bi-play-circle-fill"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
