import { Component } from '@angular/core';

@Component({
  selector: 'app-uk-writer-matched-projects',
  templateUrl: './uk-writer-matched-projects.component.html',
  styleUrls: ['./uk-writer-matched-projects.component.scss']
})
export class UkWriterMatchedProjectsComponent {

}
