<section>
  <div class="container-fluids mb-3">
    <div class="row align-items-center">
      <nav>
        <ul class="projects-nav-tab">
          <li routerLink="/super-admin/todo-tasks">
            <a>List View</a>
          </li>
          <li routerLink="/super-admin/resources-productivity-view">
            <a>Resources Productivity View</a>
          </li>
          <li routerLink="/super-admin/team-productivity-view">
            <a class="active">Team Productivity View</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h4 class="text-theme mb-4"><strong>Team Productivity View</strong></h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xl-12 col-12" style="cursor: pointer;">
        <!-- User List -->
        <ul class="taskuserList">
          <li *ngFor="let user of displayedUsers">
            <span [ngClass]="{
                'selected-user': selectedUserIds.includes(user._id)
              }" (click)="toggleUserSelection(user._id)">
              {{ user.userName }} - {{ user?.taskcount }}
            </span>
          </li>
          <!-- View More / View Less Link -->
          <li *ngIf="userList.length > 7">
            <a href="javascript:;" (click)="toggleView()">
              {{ showAll ? "- View Less" : "+ View More" }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row align-items-end">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="startDate">Start Date</label>
                  <input type="date" class="form-control" id="startDate" [(ngModel)]="startDate">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="endDate">End Date</label>
                  <input type="date" class="form-control" id="endDate" [(ngModel)]="endDate">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <button class="btn btn-primary w-100" (click)="onDateRangeChange()">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="chart-controls mb-3">
              <button class="btn btn-sm btn-primary me-2" (click)="randomizeData()">Randomize</button>
              <button class="btn btn-sm btn-success me-2" (click)="addDataset()">Add Dataset</button>
              <button class="btn btn-sm btn-info me-2" (click)="addData()">Add Data</button>
              <button class="btn btn-sm btn-warning me-2" (click)="removeDataset()">Remove Dataset</button>
              <button class="btn btn-sm btn-danger" (click)="removeData()">Remove Data</button>
            </div>
            <canvas id="teamProductivityChart"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
