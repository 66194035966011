<main id="main">
    <section>
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                    <div class="form-group mb-0">
                        <span class="input-icon"><i class="bi bi-search"></i></span>
                        <input type="search" [formControl]="searchText" class="form-control control-search"
                            placeholder="Search by BOS ID & Project Title.">
                    </div>
                </div>
            </div>
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th width="100px">Project Title</th>
                            <!-- <th width="150px">Project Description</th> -->
                            <th>BOS ID</th>
                            <th>Publish Date</th>
                            <th>Category</th>
                            <th>Industry</th>
                            <th>Min value (GBP)</th>
                            <th>Max value (GBP)</th>
                            <th>Website</th>
                            <th width="100px">Link to notice</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th>Due Date &amp; Time</th>
                            <th>Supplier Count</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>


                        <tr>
                            <td *ngIf="!projectList?.length" colspan="15">
                                <app-no-record-found></app-no-record-found>
                            </td>
                        </tr>

                        <tr
                            *ngFor="let item of projectList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                            <td><a href="javascript:;">{{item?.projectName}}</a></td>
                            <!-- <td><a title="{{ item?.description }}">{{ item?.description | slice:0:10 }}</a></td> -->
                            <td v-align="middle">{{item?.BOSID}}</td>
                            <td>{{item?.publishDate | date:'dd/MM/yyyy'}}</td>
                            <td>{{item?.category}}</td>
                            <td>{{item?.industry}}</td>
                            <td>{{item?.minValue}}</td>
                            <td>{{item?.maxValue}}</td>
                            <td>{{item?.website}}</td>
                            <td><a href='{{item?.link}}' target="_blank">Link to notice</a></td>
                            <td>{{item?.createdAt | date:'dd/MM/yyyy'}}</td>
                            <td>{{item?.status}}</td>
                            <td>{{item?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                            <td>{{item?.select[0]?.companySelect?.length || 0}}</td>
                            <td>
                                <a (click)="supplierDetails(item?._id)" class="btn btn-sm btn-primarysm"
                                    title="View Details">View Suppliers</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 align-items-center">
                <div class="row">
                    <div class="pagination">
                        <pagination-controls [idMaxSize]="100"  (pageChange)="paginate($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <!-- <nav>
                <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                        <span class="page-link">Previous</span>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;">1</a></li>
                    <li class="page-item active" aria-current="page">
                        <span class="page-link">2</span>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="javascript:;">Next</a>
                    </li>
                </ul>
            </nav> -->
        </div>
    </section>
</main><!-- End #main -->