import { Component } from '@angular/core';

@Component({
  selector: 'app-boss-user-home',
  templateUrl: './boss-user-home.component.html',
  styleUrls: ['./boss-user-home.component.scss']
})
export class BossUserHomeComponent {

}
