<main id="main">
  <section class="casestudy-section">
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/super-admin-supplier">
              <a class="active">Supplier</a>
            </li>
            <!-- <li routerLink="/super-admin/expertise-view">
              <a >Expertise-view</a>
            </li> -->
            <li routerLink="/super-admin/role-wise-resources-list">
              <a>Resources-view</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container-fluid">
      <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10 col-8 d-flex justify-content-between">
        <h4 class="text-theme mb-4"><strong>Expertise View</strong></h4>
        <!-- Back to Supplier Button-->
        <div class="col-lg-5 col-xl-5 col-md-5 col-6 d-flex justify-content-end gap-2 mb-2">
          <button class="btn btn-secondary btn-sm" (click)="navigateToSupplier()">
            Back to Supplier
          </button>
        </div>

      </div>

      <div class="d-flex align-items-center flex-grow-1">
        <div class="form-group mb-0 flex-grow-1">
          <span class="input-icon"><i class="bi bi-search"></i></span>
          <input class="form-control control-search" placeholder="Search by Expertise" type="text"
            [(ngModel)]="searchText" (input)="searchtext()" />
        </div>
      </div>

      <!-- Date Filters -->
      <div class="row mt-3 mb-3">
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2">
          <label class="form-label">Start Date</label>
          <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="startDate" name="startDate">
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2">
          <label class="form-label">End Date</label>
          <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="endDate" name="endDate">
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2 d-flex align-items-end gap-2">
          <button class="btn btn-primary" (click)="applyDateFilter()" style="height: 38px;">
          Search
          </button>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-striped align-middle">
          <thead>
            <tr>
              <th>Expertise</th>
              <th>Total Sub Expertise</th>
              <th>Total Supplier</th>
              <th>Active Supplier</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!expertiseList?.length">
              <td colspan="3">
                <app-no-record-found></app-no-record-found>
              </td>
            </tr>
            <tr
              *ngFor="let item of expertiseList | paginate: { itemsPerPage: pagesize, currentPage: page, totalItems: totalRecords }">
              <td>{{ item?.expertise }}</td>
              <td>{{ item?.subExpertiseCount || 0 }}</td>
              <td>{{ item?.totalSupplierCount || 0 }}</td>
              <td>{{ item?.activeSupplierCount || 0 }}</td>
              <td>
                <a class="btn btn-primary btn-sm" style="cursor: pointer"
                  (click)="navigateToSubExpertise(item)">View</a>
              </td>
              <!-- <td>
                <a class="btn btn-primary btn-sm" style="cursor: pointer" data-bs-toggle="modal"
                  data-bs-target="#viewAllDocuments" (click)="showDocuments(item)">
                  View
                </a>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</main>
<div class="modal fade" id="viewAllDocuments" tabindex="-1" aria-labelledby="viewAllProjectsLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between">
        <h6>Supplier List</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-striped align-middle">
            <thead>
              <tr>
                <th>Supplier Name</th>
                <th>Document</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!expertiseWiseSupplierList?.length">
                <td colspan="2">No supplier available for this expertise</td>
              </tr>
              <tr *ngFor="let item of expertiseWiseSupplierList">
                <td>{{ item?.name }}</td>
                <td>
                  <button class="btn btn-primary btn-sm" (click)="viewSupplierDocuments(item)">View</button>
                </td>
                <!-- <td>
                  <div class="d-flex gap-2">
                    <a class="btn btn-primary btn-sm" [href]="item.fileUrl" target="_blank" title="Download">
                      <i class="bi bi-download"></i>
                    </a>
                    <a (click)="deleteDoc(item?._id)" class="btn btn-danger btn-sm" title="Delete">
                      <i class="bi bi-trash3-fill"></i>
                    </a>
                  </div>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Second Modal for Viewing Documents -->
<ng-template #viewDocumentsModal>
  <div class="modal-header">
    <h6>Documents for Supplier</h6>
    <button type="button" class="btn-close" (click)="modalService.dismissAll()"></button>
  </div>
  <div class="modal-body">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>File Name</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!supplierFiles?.length">
          <td colspan="2">No documents available</td>
        </tr>
        <tr *ngFor="let file of supplierFiles">
          <td>{{ file?.fileName }}</td>
          <td>
            <a [href]="file?.fileUrl" class="btn btn-success btn-sm" target="_blank">Download</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>