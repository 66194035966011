<body class="body-login" style="overflow: hidden; height: 100vh;">
  <div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="card shadow p-4" style="max-width: 500px; width: 100%">
      <h4 class="text-center text-theme mb-4"><strong>Change Password</strong></h4>
      <form class="needs-validation" [formGroup]="changePasswordForm" (ngSubmit)="forgotpassword()">

        <!-- Old Password -->
        <div class="mb-3">
          <label class="fw-bold">Old Password</label>
          <div class="input-group">
            <input [type]="showOldPassword ? 'text' : 'password'" class="form-control" placeholder="Enter old password"
              formControlName="oldPassword" required>
            <button type="button" class="btn btn-outline-secondary" (click)="showOldPassword = !showOldPassword">
              <i class="bi" [ngClass]="showOldPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
            </button>
          </div>
          <div class="text-danger small mt-1" *ngIf="changePasswordForm.controls.oldPassword?.touched &&
                      changePasswordForm.controls.oldPassword?.errors?.['required']">
            Please enter your old password.
          </div>
        </div>

        <!-- New Password -->
        <div class="mb-3">
          <label class="fw-bold">New Password</label>
          <div class="input-group">
            <input [type]="showNewPassword ? 'text' : 'password'" class="form-control" placeholder="Enter new password"
              formControlName="newPassword" required>
            <button type="button" class="btn btn-outline-secondary" (click)="showNewPassword = !showNewPassword">
              <i class="bi" [ngClass]="showNewPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
            </button>
          </div>
          <div class="text-danger small mt-1" *ngIf="changePasswordForm.controls.newPassword?.touched &&
                      changePasswordForm.controls.newPassword?.errors?.['required']">
            Please enter your new password.
          </div>
        </div>

        <div class="mb-3">
          <label class="fw-bold">Confirm Password</label>
          <div class="input-group">
            <input [type]="confirmPassword ? 'text' : 'password'" class="form-control" placeholder="Enter new password"
              formControlName="confirm_password" required>
            <button type="button" class="btn btn-outline-secondary" (click)="confirmPassword = !confirmPassword">
              <i class="bi" [ngClass]="confirmPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
            </button>
          </div>
          <div class="text-danger small mt-1" *ngIf="changePasswordForm.getError('mismatch')">
            Confirm password don't match with set password
          </div>
        </div>

        <!-- Submit Button -->
        <button type="submit" class="btn btn-primary w-100 mt-3">Change Password</button>
      </form>
    </div>
  </div>
</body>
