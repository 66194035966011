<main id="main">
  <section class="casestudy-section">
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/supplier-user-profile">
              <a>Profile</a>
            </li>
            <li routerLink="/super-admin/admin-case-study-list">
              <a>Historical Data</a>
            </li>
            <li routerLink="/super-admin/expertise-list">
              <a>Case Studies</a>
            </li>
            <li routerLink="/super-admin/resources-list">
              <a>Resources</a>
            </li>
            <li routerLink="/super-admin/supplier-user-activity">
              <a  class="active">Activity</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-12">
          <h4 class="text-theme"><strong>sdf Supplier Login Activity</strong></h4><br>
        </div>
      </div>
      <div class="row">
       <div class="col-12">
        <div class="table-responsive">
          <table border="1" class="table" style="width: 100%; text-align: center;">
            <thead>
              <tr>
                <th *ngFor="let date of dates">{{ date | date : 'dd/MM/yyyy' }}</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td *ngIf="!rows?.length" colspan="7">
                  <app-no-record-found></app-no-record-found>
                </td>
              </tr>

              <tr *ngFor="let row of rows">
                <td *ngFor="let time of row">{{ time }}</td>
              </tr>
            </tbody>
          </table>
        </div>
       </div>
      </div>
    </div>
  </section>
</main>
