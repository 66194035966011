<main id="main">
  <section class="casestudy-section">
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/super-admin-supplier">
              <a>Supplier</a>
            </li>
            <!-- <li routerLink="/super-admin/expertise-view" *ngIf="isToggled">
              <a>Expertise-view</a>
            </li> -->
            <li routerLink="/super-admin/role-wise-resources-list">
              <a class="active">Resources-view</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-8 col-xl-8 col-md-8 col-sm-8 col-8">
            <h4 class="text-theme mb-4">Roles List</h4>
          </div>
          <!-- <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-4 d-flex align-items-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="toggleSwitch" [(ngModel)]="isToggled">
              <label class="form-check-label ms-2" for="toggleSwitch">Experties-view
                {{ isToggled ? 'ON' : 'OFF' }}
              </label>
            </div>
          </div> -->
          <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-4">
            <button class="btn btn-primary btn-sm" routerLink="/super-admin/roles-list">
              <i class="bi bi-person-badge"></i> Roles
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center flex-grow-1">
        <div class="form-group mb-0 flex-grow-1">
          <span class="input-icon"><i class="bi bi-search"></i></span>
          <input class="form-control control-search" placeholder="Search roles" type="text" [(ngModel)]="searchText"
            (input)="searchtext()" />
        </div>
      </div>
      <!-- Date Filters -->
      <div class="row mt-3 mb-3">
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2">
          <label class="form-label">Start Date</label>
          <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="startDate" name="startDate">
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2">
          <label class="form-label">End Date</label>
          <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="endDate" name="endDate">
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2 d-flex align-items-end">
          <button class="btn btn-primary w-100" (click)="applyDateFilter()" style="height: 38px;">
            Search
          </button>
        </div>
      </div>
      <!-- Loading spinner -->
      <div *ngIf="loading" class="text-center py-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

       <!-- Roles table -->
       <div *ngIf="!isLoading" class="table-responsive">
        <table class="table table-striped align-middle">
          <thead>
            <tr>
              <th>Role Name</th>
              <th>Total Supplier</th>
              <th>Active Supplier</th>
              <th>Total Candidate</th>
              <th>Active Candidate</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td *ngIf="!rolesList?.length" colspan="13">
                <app-no-record-found></app-no-record-found>
              </td>
            </tr>

            <tr *ngFor="let role of rolesList">
              <td>{{role?.name}}</td>
              <td>{{role?.totalSuppliersCount}}</td>
              <td>{{role?.activeSuppliersCount}}</td>
              <td>{{role?.activeSuppliersCount}}</td>
              <td>{{role?.activeCandidatesCount}}</td>
              <td>
                <button class="btn btn-sm btn-outline-primary" (click)="viewRoleDetails(role)">View Resources</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>



      <div *ngIf="!loading && (!rolesList || rolesList.length === 0)" class="text-center">
        <p>No roles found.</p>
      </div>
    </div>
  </section>
</main>