<header id="header">
  <div class="container d-flex align-items-center">
    <a href="index.html" class="logo me-auto"><img src="assets/img/westgate.svg" alt="" class="img-fluid"></a>
    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link active" href="index.html">Home</a></li>
        <li><a class="nav-link" href="projects-all.html"><i class="bi bi-circle-fill text-danger p-8 "></i>
            &nbsp;Projects</a></li>
        <li class="dropdown"><a href="javascript:;">
            <div class="login-avtar">
              <img src="assets/img/face1.jpg" />
            </div>
            <span>Bid Submission</span> <i class="bi bi-chevron-down"></i>
          </a>
          <ul>
            <li><a routerLink="/bid-submission/profile-setting">Profile Setting</a></li>
            <li><a href="javascript:;" (click)="logout()">Logout</a></li>
          </ul>
        </li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>
    <!-- .navbar -->
  </div>
</header><!-- End Header -->
