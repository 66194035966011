<!-- ======= Header ======= -->
<header id="header">
    <div class="container d-flex align-items-center">
      <a href="javascript:;" class="logo me-auto"><img src="assets/img/westgate.svg" alt="" class="img-fluid"></a>
      <nav id="navbar" class="navbar">
        <ul>
          <li class="nav-link noarrowdrop" *ngFor="let item of navUrlArr">
            <a [class.active]="(item.route.includes(router.url))" class="nav-link " data-toggle="tab"
              [routerLink]="item.route">
              <i *ngIf="item.title == 'Live Projects'" class="bi bi-dot text-danger p-22"></i>
              <span>{{item.title}}</span>
            </a>
          </li>
          <li class="dropdown"><a href="href:;">
              <div class="login-avtar">
                <img src="assets/img/face1.jpg" />
              </div>
              <span>{{loginUser?.userName}}</span> <i class="bi bi-chevron-down"></i>
            </a>
            <ul>
              <li><a routerLink="/project-coordinator/project-coordinator-profile">Profile Setting</a></li>
              <li><a style="cursor: pointer;" (click)="logout()">Logout</a></li>
            </ul>
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header><!-- End Header -->
