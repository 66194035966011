<main id="main">
    <section>
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                    <div class="form-group mb-0">
                        <span class="input-icon"><i class="bi bi-search"></i></span>
                        <input type="search" class="form-control control-search" placeholder="Search">
                    </div>
                </div>
            </div>
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th width="200px">Project Title</th>
                            <th>BOS ID</th>
                            <th>Publish Date</th>
                            <th>Category</th>
                            <th>Industry</th>
                            <th>Value</th>
                            <th>Project Type</th>
                            <th>Status</th>
                            <th>Due Date &amp; Time</th>
                            <th></th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a href="javascript:;">Provision of MyBedsLife Student Engagement App Support Services</a></td>
                            <td v-align="middle">546552</td>
                            <td>2023-05-31</td>
                            <td>Website</td>
                            <td>School</td>
                            <td>£50000</td>
                            <td>Development</td>
                            <td>In-Progress</td>
                            <td>25-11-2014 & 5:00PM</td>
                            <td><i class="bi bi-check-circle-fill text-success"></i> CS(3/3)</td>
                            <td>
                                <a href="" class="btn btn-sm btn-primarysm" title="View Details">View Details</a>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    </section>

</main><!-- End #main -->