<main id="main">
    <section>
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-6 col-12 desktop-pt200">
                    <h1 class="Heading-h1"><strong>Welcome back!</strong> </h1>
                    <h2 class="Heading-h2 mb-5"><strong>Connect</strong> with your perfect project and <strong>start
                            growing!</strong></h2>
                    <a routerLink="/supplier-admin/project-list" class="btn btn-small">View Projects</a>
                </div>
                <div class="col-lg-6 col-xl-6 col-12 mobile-hide">
                    <div class="supplierTeam-wrapper">
                        <img src="assets/img/welcome-page-team.png" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</main><!-- End #main -->