<main id="main">
    <section>
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                    <div class="form-group mb-0">
                        <span class="input-icon"><i class="bi bi-search"></i></span>
                        <input class="form-control control-search" placeholder="Search" type="text"
                            [(ngModel)]="searchText" (input)="searchtext()">
                    </div>
                </div>
            </div>
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th width="100px">Project Title</th>
                            <th>BOS ID</th>
                            <th>Publish Date</th>
                            <th width="50px">Category</th>
                            <th width="50px">Industry</th>
                            <th>Min value (GBP)</th>
                            <th>Max value (GBP)</th>
                            <th>Website</th>
                            <th width="80px">Link to notice</th>
                            <th>Created Date</th>
                            <th>Time Due (Mail)</th>
                            <th>Due Date & Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of FOIList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                            <td><a>{{item?.projectName}}</a></td>
                            <td v-align="middle">{{item?.BOSID}}</td>
                            <td>{{item?.publishDate | date:'dd/MM/yyyy'}}</td>
                            <td>{{item?.category}}</td>
                            <td>{{item?.industry}}</td>
                            <td>{{item?.minValue}}</td>
                            <td>{{item?.maxValue}}</td>
                            <td>{{item?.website}}</td>
                            <td width="80px"><a href='{{item?.link}}'>{{item?.link}}</a></td>
                            <td>{{item?.createdAt | date:'dd/MM/yyyy'}}</td>
                            <td>{{dateDifference}}</td>
                            <td>{{item?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                            <td>
                                <a (click)="foiDetails(item?._id)" class="btn btn-primary btn-sm me-2"><i
                                        class="bi bi-eye-fill"></i></a>
                                <a (click)="addFoiDetails(item?._id)" class="btn btn-primary btn-sm"><i
                                        class="bi bi-file-earmark-arrow-up-fill"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 align-items-center">
                <div class="row">
                    <div class="pagination">
                        <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </section>


</main><!-- End #main -->