<main id="main">
    <section>
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                    <div class="form-group mb-0">
                        <span class="input-icon"><i class="bi bi-search"></i></span>
                        <input type="search" class="form-control control-search" placeholder="Search" />
                    </div>
                </div>
                <div class="col-lg-2 col-xl-2 col-12">
                    <a routerLink="/feasibility-user/feasibility-project-list"
                        class="btn btn-primary text-dark">Back</a>
                </div>
            </div>

            <!-- Project Details -->
            <div class="table-responsive mt-5 mb-3">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th width="100px">Project Title</th>
                            <!-- <th>Project Description</th> -->
                            <th>BOS ID</th>
                            <th>Publish Date</th>
                            <th>Category</th>
                            <th>Industry</th>
                            <th>Min value (GBP)</th>
                            <th>Max value (GBP)</th>
                            <th>Website</th>
                            <th>Link to notice</th>
                            <th>Created Date</th>
                            <th>Due Date & Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a href="javascript:;">{{projectDetails?.projectName}}</a>
                            </td>
                            <!-- <td><a title="{{ projectDetails?.description }}">{{ projectDetails?.description | slice:0:10 }}</a></td> -->
                            <td v-align="middle">{{projectDetails?.BOSID}}</td>
                            <td>{{projectDetails?.publishDate | date:'dd/MM/yyyy'}}</td>
                            <td>{{projectDetails?.category}}</td>
                            <td>{{projectDetails?.industry}}</td>
                            <td>£ {{projectDetails?.minValue}}</td>
                            <td>£ {{projectDetails?.maxValue}}</td>
                            <td><a>{{projectDetails?.website}}</a></td>
                            <td><a href='{{projectDetails?.link}}' target="_blank">Link to Notice</a></td>
                            <td>{{projectDetails?.createdAt | date:'dd/MM/yyyy'}}</td>
                            <td>{{projectDetails?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                            <td>
                                <!-- <a (click)="editProjectDetails(projectDetails?._id)" class="btn btn-secondary sm ml-2" title="Edit"><i
                        class="bi bi-pencil-fill"></i></a>&nbsp; -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <section class="pageheader bg-dark">
                <div class="container ">
                    <p><strong>Project Description</strong></p>
                </div>
            </section><br>

            <p>{{ projectDetails?.description }}</p>

            <section class="pageheader bg-dark">
                <div class="container">
                    <p><strong>Client & FOI Detils</strong></p>
                </div>
            </section>


            <!--client details-->
            <div class="row mt-5">
                <div class="col-lg-4 col-xl-4 col-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-xl-4 col-12">
                                    <h6 class="text-theme"><strong>Client Detils</strong></h6>
                                </div>
                                <div class="col-lg-7 col-xl-7 col-12">
                                    <p class="p-13 mb-0">
                                        Mail ID : {{ projectDetails?.mailID }}
                                    </p>
                                    <p class="p-13 mb-0">
                                        Client Type : {{ projectDetails?.clientType }}
                                    </p>
                                    <p class="p-13 mb-0">
                                        Client Name : {{ projectDetails?.clientName }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-12 mb-4">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                            <p class="mb-0">FOI Screenshot</p>
                        </div>
                        <div class="row align-items-center" *ngIf="projectDetails?.mailScreenshots?.length == 0">
                            <div class="col-lg-12 col-xl-12 col-12 mob-mb1">
                                <p class="mb-0">No Record Found.</p>
                            </div>
                        </div>
                        <ng-container *ngIf="projectDetails?.mailScreenshots?.length > 0">
                            <div class="row align-items-center"
                                *ngFor="let foiScreenshot of projectDetails?.mailScreenshots">
                                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                                    <p class="mb-0">{{ foiScreenshot?.name }}</p>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-6">
                                    <a (click)="openDocument(foiScreenshot)" class="btn btn-small w-100"
                                        data-bs-toggle="modal" data-bs-target="#ViewScreenshot"><i
                                            class="bi bi-eye-fill"></i> View</a>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-6">
                                    <a (click)="
                                download(foiScreenshot?.link?.url, foiScreenshot?.name)
                              " target="_blank" class="btn btn-small w-100"><i
                                            class="bi bi-file-earmark-arrow-down-fill"></i>
                                        Download</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row align-items-center mt-3">
                        <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                            <p class="mb-0">FOI Document</p>
                        </div>
                        <div class="row align-items-center" *ngIf="projectDetails?.fois?.length == 0">
                            <div class="col-lg-12 col-xl-12 col-12 mob-mb1">
                                <p class="mb-0">No Record Found.</p>
                            </div>
                        </div>
                        <ng-container *ngIf="projectDetails?.fois?.length > 0">
                            <div class="row align-items-center" *ngFor="let foiDocument of projectDetails?.fois">
                                <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
                                    <p class="mb-0">{{ foiDocument?.name }}</p>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-6">
                                    <a (click)="openDocument(foiDocument)" class="btn btn-small w-100"
                                        data-bs-toggle="modal" data-bs-target="#ViewDocumentModal"><i
                                            class="bi bi-eye-fill"></i> View</a>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-6">
                                    <a style="
                                padding-left: 0 !important;
                                padding-right: 0px !important;
                              " (click)="
                                download(foiDocument?.link?.url, foiDocument?.name)
                              " target="_blank" class="btn btn-small w-100"><i
                                            class="bi bi-file-earmark-arrow-down-fill"></i>
                                        Download</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-12 mb-4">
                    <div class="card">
                        <div class="card-body text-center">
                            <ng-container *ngIf="!isEditing; else editMode">
                                <p class="p-13">
                                    <strong> Period of Contract: {{ projectDetails?.periodOfContractStart | date :
                                        "dd/MM/yyyy" }} -
                                        {{projectDetails?.periodOfContractEnd | date : "dd/MM/yyyy" }}
                                    </strong>
                                </p>
                                <p class="p-13">
                                    <strong>Project Type: {{ projectDetails?.projectType }}</strong>
                                </p>
                                <a class="btn btn-secondary sm ml-2" (click)="toggleEdit()"><i
                                        class="bi bi-pencil-fill"></i></a>
                            </ng-container>
                            <ng-template #editMode>
                                <p class="p-13">
                                    <strong>
                                        Period of Contract:
                                        <input type="date" [(ngModel)]="projectDetails.periodOfContractStart" />
                                        -
                                        <input type="date" [(ngModel)]="projectDetails.periodOfContractEnd" />
                                    </strong>
                                </p>
                                <p class="p-13">
                                    <strong>
                                        Project Type:
                                        <input type="text" [(ngModel)]="projectDetails.projectType" />
                                    </strong>
                                </p>
                                <a class="btn btn-primary sm" title="Save" (click)="saveChanges('save', true)"><i
                                        class="bi bi-check2"></i></a>&nbsp;
                                <a class="btn btn-warning ml-2" title="Cancel" (click)="toggleEdit()"><i
                                        class="bi bi-x-lg"></i></a>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <section class="pageheader bg-dark">
                <div class="container ">
                    <p><strong>Upload Sub-contracting</strong></p>
                </div>
            </section><br>

            <div class="row align-items-center">
                <div class="col-lg-9 col-xl-9 col-12">
                    <div class="row">

                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label class="display-block">Sub-contracting</label> <br>
                                <div class="upload-btn-wrapper">
                                    <label for="subContract" class="btn-upload-file padding-lr-5">
                                        <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload Query Image
                                    </label>
                                    <input id="subContract" type="file" name="subContract"
                                        style="height: 5px; width: 5px"
                                        (change)="uploadDocument($event, documentUploadType.subContractDocument)" />
                                    <br>
                                    <p>{{ subContractDocument?.fileName }}</p>

                                    <div class="d-flex justify-content-between">
                                        <a *ngIf="subContractDocument"
                                            (click)="openUploadedDocument(subContractDocument)"
                                            class="btn btn-small me-2" data-bs-toggle="modal"
                                            data-bs-target="#ViewUploadedDocument">
                                            <i class="bi bi-eye-fill"></i> View
                                        </a>

                                        <a *ngIf="subContractDocument"
                                            (click)="removeDocument(documentUploadType.subContractDocument)"
                                            class="btn btn-danger">
                                            <i class="bi bi-trash-fill"></i> Remove
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label class="display-block">Economical partnership</label>
                                <br>
                                <div class="upload-btn-wrapper">
                                    <label for="economicalQuryDocument" class="btn-upload-file padding-lr-5">
                                        <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload Query Image
                                    </label>
                                    <input type="file" id="economicalQuryDocument" name="economicalQuryDocument"
                                        style="height: 5px; width: 5px"
                                        (change)="uploadDocument($event, documentUploadType.economicalPartnershipQuery)" />
                                    <br>
                                    <p>{{ economicalPartnershipQueryFile?.fileName }}</p>

                                    <div class="d-flex justify-content-between">
                                        <a *ngIf="economicalPartnershipQueryFile"
                                            (click)="openUploadedDocument(economicalPartnershipQueryFile)"
                                            class="btn btn-small  me-2" data-bs-toggle="modal"
                                            data-bs-target="#ViewUploadedDocument">
                                            <i class="bi bi-eye-fill"></i> View
                                        </a>

                                        <a *ngIf="economicalPartnershipQueryFile"
                                            (click)="removeDocument(documentUploadType.economicalPartnershipQuery)"
                                            class="btn btn-danger">
                                            <i class="bi bi-trash-fill"></i> Remove
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label class="display-block">Other</label>
                                <br />
                                <div class="upload-btn-wrapper">
                                    <label class="btn-upload-file" for="otherDocument">
                                        <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload Query Image
                                    </label>
                                    <input type="file" name="myfile" id="otherDocument" style="height: 5px; width: 5px"
                                        (change)="uploadDocument($event, documentUploadType.otherQueryDocument)" />
                                    <br>
                                    <p>{{ documentUploadType?.otherQueryDocument?.fileName }}</p>

                                    <!-- Loop through the uploaded documents -->
                                    <ng-container *ngFor="let document of FeasibilityOtherDocuments">
                                        <ng-container *ngIf="document?.type === documentUploadType.otherQueryDocument">

                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <a (click)="openUploadedDocument(document?.file)"
                                                    class="btn btn-small me-2" data-bs-toggle="modal"
                                                    data-bs-target="#ViewUploadedDocument">
                                                    <i class="bi bi-eye-fill"></i> View
                                                </a>

                                                <a (click)="removeOtherDocument(document)" class="btn btn-danger">
                                                    <i class="bi bi-trash-fill"></i> Remove
                                                </a>
                                            </div>

                                        </ng-container>
                                    </ng-container>
                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                            <div class="form-check form-check-inline mt-3">
                                <input class="form-check-input" type="radio" name="inlineRadioOptionsForSubContract"
                                    id="inlineRadio1" value="true" [checked]="subContracting === true"
                                    (change)="subContactChange('true')" />
                                <label class="form-check-label" for="inlineRadio1">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptionsForSubContract"
                                    id="inlineRadio2" value="true" [checked]="subContracting === false"
                                    (change)="subContactChange('false')" />
                                <label class="form-check-label" for="inlineRadio2">No</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <div class="upload-btn-wrapper">
                                    <label for="economicalResponseDocument" class="btn-upload-file padding-lr-5">
                                        <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload Response
                                    </label>
                                    <input type="file" id="economicalResponseDocument" name="myfile"
                                        style="height: 5px; width: 5px"
                                        (change)="uploadDocument($event, documentUploadType.economicalPartnershipResponse)" />
                                    <br>
                                    <p *ngIf="economicalPartnershipResponceFile">{{
                                        economicalPartnershipResponceFile?.fileName }}</p>

                                    <div class="d-flex justify-content-between">
                                        <a *ngIf="economicalPartnershipResponceFile"
                                            (click)="openUploadedDocument(economicalPartnershipResponceFile)"
                                            class="btn btn-small me-2" data-bs-toggle="modal"
                                            data-bs-target="#ViewUploadedDocument">
                                            <i class="bi bi-eye-fill"></i> View
                                        </a>


                                        <a *ngIf="economicalPartnershipResponceFile"
                                            (click)="removeDocument(documentUploadType.economicalPartnershipResponse)"
                                            class="btn btn-danger">
                                            <i class="bi bi-trash-fill"></i> Remove
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <div class="upload-btn-wrapper">
                                    <label class="btn-upload-file" for="otherDocument1">
                                        <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload Document
                                    </label>
                                    <input type="file" name="myfile" style="height: 5px; width: 5px" id="otherDocument1"
                                        (change)="uploadDocument($event, documentUploadType.otherDocument)" />
                                    <br>

                                    <!-- Loop through the uploaded documents -->
                                    <ng-container *ngFor="let document of FeasibilityOtherDocuments">
                                        <ng-container *ngIf="document?.type === documentUploadType.otherDocument">
                                            <!-- Display document file name -->
                                            <p>{{ document.file?.fileName }}</p>

                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <a (click)="openUploadedDocument(document?.file)"
                                                    class="btn btn-small me-2" data-bs-toggle="modal"
                                                    data-bs-target="#ViewUploadedDocument">
                                                    <i class="bi bi-eye-fill"></i> View
                                                </a>


                                                <a (click)="removeOtherDocument(document)" class="btn btn-danger">
                                                    <i class="bi bi-trash-fill"></i> Remove
                                                </a>
                                            </div>

                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3 col-xl-3 col-12">
                    <div class="card ">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-lg-12 col-xl-12 col-12">
                                    <textarea [(ngModel)]="comment" placeholder="Enter Comment" class="form-control"
                                        rows="3" cols="100"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="pageheader bg-dark">
                <div class="container ">
                    <p><strong>Documents</strong></p>
                </div>
            </section><br>
            <div class="row mb-4">
                <!-- <div class="col-12">
                  <h4 class="text-theme mb-4"><strong>Documents</strong></h4>
                </div> -->
                <div class="col-lg-5 col-xl-5 col-12 mob-mb1">
                    <h5>Client Documents</h5>
                    <div class="card">
                        <ul class="project-document">
                            <li *ngFor="let item of projectDetails?.clientDocument">
                                <div class="row">
                                    <div class="col-5 text-center">
                                        <p class="mb-0">
                                            {{ item?.name }}
                                        </p>
                                    </div>
                                    <div class="col-7 text-center">
                                        <a href="href:;" class="btn btn-primarysm me-2" data-bs-toggle="modal"
                                            data-bs-target="#ViewClientDoc" (click)="openDocument(item)"><i
                                                class="bi bi-eye-fill"></i>
                                            View</a>
                                        <a href="href:;" (click)="download(item?.file?.url, item?.name)"
                                            class="btn btn-primarysm"><i class="bi bi-file-earmark-arrow-down-fill"></i>
                                            Download</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <button (click)="hideShowForm()" type="button" class="btn btn-dark btn-sm add-card-btn">
                            <i class="bi bi-plus-lg"></i>
                        </button>
                    </div>
                    <ng-container *ngIf="viewClientDocumentForm">
                        <div class="d-flex p-2 justify-content-between">
                            <div class="row align-items-center">
                                <div class="col-9">
                                    <div class="form-group">
                                        <input type="text" [(ngModel)]="documentName" placeholder="Enter Document Name"
                                            class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-6">
                                <div class="btn btn-small w-100">
                                    <label for="uploadClientDocument"><i class="bi bi-file-earmark-arrow-up-fill"></i>
                                        Upload
                                    </label>
                                    <input type="file" id="uploadClientDocument" (change)="
                              uploadDocument($event, documentUploadType.clientDocument)
                            " name="myfile" style="height: 1px; width: 1px" />
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-lg-4 col-xl-7 col-12">

                    <div class="form-group">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptionsForComment"
                                        id="comment1" value="InProgress" (change)="statusChange('InProgress')"
                                        [checked]="status == 'InProgress'" />
                                    <label class="form-check-label" for="comment1">In-Progress</label>
                                </div>
                            </div>

                            <div class="col-12 d-flex flex-column gap-2"
                                *ngIf="this.status == 'InProgress' && commentData?.length">
                                <div class="d-flex gap-3" *ngFor="let item of commentData">
                                    <input disabled type="date" [value]="item.date" class="form-control"
                                        placeholder="Date" />
                                    <input disabled type="text" [value]="item.comment" class="form-control"
                                        placeholder="Comments for In-Progress" />
                                </div>
                            </div>
                            <div class="col-12 d-flex gap-3 mt-1" *ngIf="this.status == 'InProgress'">
                                <input type="date" class="form-control" [formControl]="statusDate" placeholder="Date" />
                                <input *ngIf="this.status == 'InProgress'" type="text" class="form-control"
                                    placeholder="Comments for In-Progress" [formControl]="statusComment" />
                                <button (click)="pushStatus()" type="button" class="btn btn-dark btn-sm">
                                    <i class="bi bi-plus-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptionsForComment"
                                        id="comment2" value="InHold" (change)="statusChange('InHold')"
                                        [checked]="status == 'InHold'" />
                                    <label class="form-check-label" for="comment2">In-Hold</label>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column gap-2"
                                *ngIf="this.status == 'InHold' && commentData?.length">
                                <div class="d-flex gap-3" *ngFor="let item of commentData">
                                    <input disabled type="date" [value]="item.date" class="form-control"
                                        placeholder="Date" />
                                    <input disabled type="text" [value]="item.comment" class="form-control"
                                        placeholder="Comments for In-Hold" />
                                </div>
                            </div>
                            <div class="col-12 d-flex gap-3 mt-1" *ngIf="this.status == 'InHold'">
                                <input type="date" class="form-control" [formControl]="statusDate" placeholder="Date" />
                                <input type="text" class="form-control" placeholder="Comments for In-Hold"
                                    [formControl]="statusComment" />
                                <button (click)="pushStatus()" type="button" class="btn btn-dark btn-sm">
                                    <i class="bi bi-plus-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptionsForComment"
                                        id="comment3" value="Passed" (change)="statusChange('Passed')"
                                        [checked]="status == 'Passed'" />
                                    <label class="form-check-label text-success" for="comment3">Pass</label>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column gap-2"
                                *ngIf="this.status == 'Passed' && commentData?.length">
                                <div class="d-flex gap-3" *ngFor="let item of commentData">
                                    <input disabled type="date" [value]="item.date" class="form-control"
                                        placeholder="Date" />
                                    <input disabled type="text" [value]="item.comment" class="form-control"
                                        placeholder="Comments for Pass" />
                                </div>
                            </div>

                            <div class="col-12 d-flex gap-3 mt-1" *ngIf="this.status == 'Passed'">
                                <input type="date" class="form-control" [formControl]="statusDate" placeholder="Date" />
                                <input type="text" class="form-control" placeholder="Comments for Pass"
                                    [formControl]="statusComment" />
                                <button (click)="pushStatus()" type="button" class="btn btn-dark btn-sm">
                                    <i class="bi bi-plus-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptionsForComment"
                                        id="comment4" value="Fail" (change)="statusChange('Fail')"
                                        [checked]="status == 'Fail'" />
                                    <label class="form-check-label text-danger" for="comment4">Fail</label>
                                </div>
                            </div>
                            <div class="col-8" *ngIf="this.status == 'Fail'">
                            </div>
                            <div class="col-12 d-flex flex-column gap-2"
                                *ngIf="this.status == 'Fail' && commentData?.length">
                                <div class="d-flex gap-3" *ngFor="let item of commentData">
                                    <input disabled type="date" [value]="item.date" class="form-control"
                                        placeholder="Date" />
                                    <input disabled type="text" [value]="item.comment" class="form-control"
                                        placeholder="Comments for Fail" />
                                </div>
                            </div>
                            <div class="col-12 d-flex gap-3 mt-1" *ngIf="this.status == 'Fail'">
                                <input type="date" class="form-control" [formControl]="statusDate" placeholder="Date" />
                                <input type="text" class="form-control" placeholder="Comments for Fail"
                                    [formControl]="statusComment" />
                                <button (click)="pushStatus()" type="button" class="btn btn-dark btn-sm">
                                    <i class="bi bi-plus-lg"></i>
                                </button>
                            </div>
                            <div class="col-12 d-flex gap-3" *ngIf="this.status == 'Fail'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="pageheader bg-dark">
                <div class="container ">
                    <p><strong>Login Details</strong></p>
                </div>
            </section><br>

            <div class="row">
                <div class="col-lg-4 col-xl-4 col-12">
                    <div class="row align-items-center"
                        *ngFor="let item of projectDetails?.loginDetail; let i = index ;">
                        <div class="col-lg-6 col-xl-6 col-12">
                            <p>
                                <strong>{{ item?.name }}</strong>
                            </p>
                        </div>
                        <div class="col-lg-6 col-xl-6 col-10">
                            <a href="href:;" class="btn btn-primarysm me-2" (click)="viewLoginDetail(item)"
                                data-bs-toggle="modal" data-bs-target="#loginDetailModal"><i class="bi bi-eye-fill"></i>
                                View</a>
                            <a href="href:;" data-bs-toggle="modal" (click)="editLoginDetail(item,i)"
                                data-bs-target="#loginDetailModal" class="btn btn-primarysm"><i
                                    class="bi bi-file-earmark-arrow-up-fill"></i> Edit</a>
                        </div>
                    </div>
                    <ng-container *ngIf="viewLoginForm">
                        <div class="d-flex p-2 justify-content-between">
                            <div class="row align-items-center">
                                <div class="col-9">
                                    <div class="form-group">
                                        <input type="text" [(ngModel)]="loginName" class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-6">
                                <div class="btn btn-small w-100">
                                    <label for="uploadLoginDetail"><i class="bi bi-file-earmark-arrow-up-fill"></i>
                                        Upload
                                    </label>
                                    <input [disabled]="!loginName" data-bs-toggle="modal" (click)="openLoginDetail()"
                                        data-bs-target="#loginDetailModal" id="uploadLoginDetail" name="myfile"
                                        style="height: 0.5px; width: 0.5px" />
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-xl-6 col-12 text-end">
                            <button (click)="viewLoginForm = !viewLoginForm" type="button" class="btn btn-sm btn-dark">
                                <i class="bi bi-plus-lg"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
                    <a (click)="summaryDetail('save')" class="btn btn-dark w-100">Save</a>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="bg-dark">
        <div class="container">
            <form [formGroup]="summaryForm" (ngSubmit)="submitForm()">
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-2 col-xl-2 col-md-3 col-sm-3 col-12">
                            <p class="mb-0">Summary Note Questions</p>
                        </div>
                        <div class="col-lg-10 col-xl-10 col-md-8 col-sm-8 col-12">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" formControlName="summaryQuestionFor" type="radio"
                                    name="summaryQuestionFor" id="inlineRadio1" value="Supplier"
                                    [checked]="summaryForm.get('summaryQuestionFor')?.value == 'Supplier'">
                                <label class="form-check-label" for="inlineRadio1">Supplier</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" formControlName="summaryQuestionFor" type="radio"
                                    name="summaryQuestionFor" id="inlineRadio2" value="UKWriter"
                                    [checked]="summaryForm.get('summaryQuestionFor')?.value == 'UKWriter'">
                                <label class="form-check-label" for="inlineRadio2">UK Writer</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-xl-4 col-12">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="questionName"
                                placeholder="Enter question name">
                            <span class="text-danger"
                                *ngIf="summaryForm.controls['questionName'].touched && summaryForm.controls['questionName'].errors?.['required']">
                                Please enter question name
                            </span>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" rows="6" formControlName="question"
                                placeholder="Enter question"></textarea>
                            <span class="text-danger"
                                *ngIf="summaryForm.controls['question'].touched && summaryForm.controls['question'].errors?.['required']">
                                Please enter question
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-12">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="refrenceDocument"
                                placeholder="Enter document reference name">
                            <span class="text-danger"
                                *ngIf="summaryForm.controls['refrenceDocument'].touched && summaryForm.controls['refrenceDocument'].errors?.['required']">
                                Please enter reference document details
                            </span>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="weightage"
                                placeholder="Enter weightage">
                            <span class="text-danger"
                                *ngIf="summaryForm.controls['weightage'].touched && summaryForm.controls['weightage'].errors?.['required']">
                                Please enter weightage
                            </span>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="instructions"
                                placeholder="instructions">
                            <span class="text-danger"
                                *ngIf="summaryForm.controls['instructions'].touched && summaryForm.controls['instructions'].errors?.['required']">
                                Please enter instructions
                            </span>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">{{ isEditMode ? 'Update' : 'Add' }}</button>
                        </div>
                    </div>
                </div>
            </form>


          
            <div class="row">
                <div class="col-12">
                    <p><strong>Added Questions</strong></p>
                    <div class="table-responsive">
                        <table class="table table-dark table-borderless">
                            <thead>
                                <tr>
                                    <td>Summary Note Questions For </td>
                                    <th>Question Name</th>
                                    <th>Question</th>
                                    <th>Document Ref. Name</th>
                                    <th>Weightage</th>
                                    <th>Instructions</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let summary of summaryquestionList">
                                    <td>{{summary.summaryQuestionFor}}</td>
                                    <td>{{summary.questionName}}</td>
                                    <td>{{summary.question}}</td>
                                    <td>{{summary.refrenceDocument}}</td>
                                    <td><strong>{{summary.weightage}}</strong></td>
                                    <td>{{summary.instructions}}</td>
                                    <td>
                                     
                                        <a (click)="editSummary(summary)" class="btn btn-outline-secondary"><i
                                                class="bi bi-pencil-fill"></i></a>&nbsp;
                                        <a (click)="deleteSummary(summary?._id)" class="btn btn-outline-secondary"><i
                                                class="bi bi-x-circle"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    </section> -->
    <section>
        <div class="container-fluid">
            <form [formGroup]="eligibilityForm" (ngSubmit)="submitEligibility()">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label>Case Study Numbers</label>
                            <input type="number" class="form-control" formControlName="caseStudyRequired">
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label>Certifications</label>
                            <input type="text" class="form-control" formControlName="certifications">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label>Policy</label>
                            <input type="text" class="form-control" formControlName="policy">
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-12 text-center">
                    <button type="submit" (click)="submitEligibilityForm()" class="btn btn-dark">Submit</button>
                </div>
            </div><br>

            <section class="pageheader bg-dark">
                <div class="container">
                    <p><strong>Project Manager </strong></p>
                </div>
            </section><br>

        </div>
    </section>

    <section style="padding-top: 10px; padding-bottom: 10px;">
        <div class="container-fluid">
            <div class="table-responsive match-detail">
                <table class="table table-striped mb-0 align-middle">
                    <thead>
                        <tr>
                            <th width="100px" style="min-width: 100px"></th>
                            <th width="12%">Supplier Name</th>
                            <th width="5%">Supplier ID</th>
                            <th width="5%">Admin Name</th>
                            <th width="10%">Company Selection</th>
                            <th width="10%">Handover Call</th>
                            <th width="5%"></th>
                            <th width="100px" style="min-width: 100px"></th>
                        </tr>
                    </thead>
                    <tbody class="bg-f9 mb-2">
                        <ng-container *ngFor="let supplier of userDetail">
                            <ng-container class="mb-3">
                                <tr>
                                    <td></td>
                                    <td>{{ supplier.companyName }}</td>
                                    <td>{{ supplier._id }}</td>
                                    <td>{{supplier.name}}</td>
                                    <td>
                                        <div class="form-group">
                                            <ng-select style="padding: 0px !important;"
                                                class="form-select custom-ng-select-option" [items]="companyDetails"
                                                bindLabel="name" [multiple]="true" bindValue="name"
                                                [(ngModel)]="supplier['company']">
                                            </ng-select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <input type="date" [(ngModel)]="supplier.startDate" class="form-control"
                                                    placeholder="Start Date" style="height: 30px" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <button (click)="selectSupplier(supplier)"
                                                class="btn btn-sm btn-primary px-4 py-1"
                                                style="white-space: nowrap; font-size: 11px">
                                                {{ supplier?._id == selectedSupplier?._id ? 'Selected' : 'Select'}}
                                            </button>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="8" class="inner-table p-0">
                                        <table class="table w-100 mb-0">
                                            <tr>
                                                <td width="156px"></td>
                                                <td colspan="3" *ngFor="let casestudy of casestudylist">
                                                    <div class="d-flex align-items-center bg-transparent">
                                                        <div class="d-flex align-items-center me-1">
                                                            <span class="w-space pe-2">{{casestudy?.name}}</span>
                                                            <a href="javascript:;"
                                                                class="btn btn-primary fonts-12 rounded-pill px-4 py-1 w-space"
                                                                (click)="openDocument(casestudy)" data-bs-toggle="modal"
                                                                data-bs-target="#ViewCaseModal"><i
                                                                    class="bi bi-eye-fill me-2"></i> View</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td colspan="3">
                                                    <div class="d-flex align-items-center bg-transparent">
                                                    </div>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="mb-3">
                                    <td colspan="8" class="p-0">
                                        <table class="table w-100 mb-0">
                                            <tr>
                                                <td width="156px"></td>
                                                <td class="text-danger" style="width: 120px">
                                                    <span class="bg-transparent mt-1"> Drop Reason</span>
                                                </td>
                                                <td colspan="5">
                                                    <input type="text" class="w-100 p-1 px-2 rounded fonts-12"
                                                        style="border: 1px solid #e6e6e6" placeholder="Add reason"
                                                        aria-label="Username" [(ngModel)]="supplier['reason']"
                                                        aria-describedby="basic-addon1" />
                                                </td>
                                                <td>
                                                    <div class="bg-transparent w-auto d-flex align-items-center">
                                                        <button (click)="dropUser(supplier)"
                                                            class="btn btn-outline-white btn-white bg-white text-danger rounded px-5 py-1 me-3"
                                                            style="white-space: nowrap; font-size: 11px">
                                                            Drop
                                                        </button>
                                                    </div>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

    <!-- <section class="bg-dark">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h5 class="text-white mb-4"><strong>Summary Note Questions</strong></h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-dark table-borderless" style="background-color: #252525;">
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Weightage</th>
                                <th>Status</th>
                                <th>Deadline</th>
                                <th>Assign User</th>
                                <th>Add Review Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="350">1. Project management approach and project team outlined. Resilience –
                                    ability to
                                    deliver.</td>
                                <td>35%</td>
                                <td><span class="text-success">Verified</span></td>
                                <td>01-02-2014 <a href="javascript:;" class="editbtn"><i
                                            class="bi bi-pencil-fill"></i></a></td>
                                <td>Ayush <a href="javascript:;" class="editbtn"><i class="bi bi-pencil-fill"></i></a>
                                </td>
                                <td> dolor sit amet, consectetur...... <a href="javascript:;" class="editbtn"><i
                                            class="bi bi-pencil-fill"></i></a></td>
                                <td>
                                    <a href="question-answer-details.html" class="btn btn-small-white me-2"><i
                                            class="bi bi-eye-fill"></i> View Solution</a>
                                    <a href="javascript:;" class="btn btn-small"><i
                                            class="bi bi-file-earmark-arrow-down-fill"></i>
                                        Download</a>
                                </td>
                            </tr>
                            <tr>
                                <td width="350">1. Project management approach and project team outlined. Resilience –
                                    ability to
                                    deliver.</td>
                                <td>35%</td>
                                <td><span class="text-success">Verified</span></td>
                                <td>01-02-2014 <a href="javascript:;" class="editbtn"><i
                                            class="bi bi-pencil-fill"></i></a></td>
                                <td>Ayush <a href="javascript:;" class="editbtn"><i class="bi bi-pencil-fill"></i></a>
                                </td>
                                <td> dolor sit amet, consectetur...... <a href="javascript:;" class="editbtn"><i
                                            class="bi bi-pencil-fill"></i></a></td>
                                <td>
                                    <a href="question-answer-details.html" class="btn btn-small-white me-2"><i
                                            class="bi bi-eye-fill"></i> View Solution</a>
                                    <a href="javascript:;" class="btn btn-small"><i
                                            class="bi bi-file-earmark-arrow-down-fill"></i>
                                        Download</a>
                                </td>
                            </tr>
                            <tr>
                                <td width="350">1. Project management approach and project team outlined. Resilience –
                                    ability to
                                    deliver.</td>
                                <td>35%</td>
                                <td><span class="text-success">Verified</span></td>
                                <td>01-02-2014 <a href="javascript:;" class="editbtn"><i
                                            class="bi bi-pencil-fill"></i></a></td>
                                <td>Ayush <a href="javascript:;" class="editbtn"><i class="bi bi-pencil-fill"></i></a>
                                </td>
                                <td> dolor sit amet, consectetur...... <a href="javascript:;" class="editbtn"><i
                                            class="bi bi-pencil-fill"></i></a></td>
                                <td>
                                    <a href="question-answer-details.html" class="btn btn-small-white me-2"><i
                                            class="bi bi-eye-fill"></i> View Solution</a>
                                    <a href="javascript:;" class="btn btn-small"><i
                                            class="bi bi-file-earmark-arrow-down-fill"></i>
                                        Download</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section> -->
    <!-- <section class="p-0">
        <div class="bg-light-gray p-2 text-center">
            UK Writer
        </div>
        <section class="bg-primary">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h5 class="text-white mb-4"><strong>Summary Note Questions</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-primary table-borderless" style="background-color: #252525;">
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Weightage</th>
                                    <th>Status</th>
                                    <th>Deadline</th>
                                    <th>Assign User</th>
                                    <th>Add Review Note</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="350">1. Project management approach and project team outlined. Resilience
                                        – ability to
                                        deliver.</td>
                                    <td>35%</td>
                                    <td><span class="text-success">Verified</span></td>
                                    <td>01-02-2014 <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a>
                                    </td>
                                    <td>Ayush <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a></td>
                                    <td> dolor sit amet, consectetur...... <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a></td>
                                    <td>
                                        <a href="question-answer-details.html" class="btn btn-small-white me-2"><i
                                                class="bi bi-eye-fill"></i> View Solution</a>
                                        <a href="javascript:;" class="btn btn-outline-purewhite full-rounded"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="350">1. Project management approach and project team outlined. Resilience
                                        – ability to
                                        deliver.</td>
                                    <td>35%</td>
                                    <td><span class="text-success">Verified</span></td>
                                    <td>01-02-2014 <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a>
                                    </td>
                                    <td>Ayush <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a></td>
                                    <td> dolor sit amet, consectetur...... <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a></td>
                                    <td>
                                        <a href="question-answer-details.html" class="btn btn-small-white me-2"><i
                                                class="bi bi-eye-fill"></i> View Solution</a>
                                        <a href="javascript:;" class="btn btn-outline-purewhite full-rounded"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="350">1. Project management approach and project team outlined. Resilience
                                        – ability to
                                        deliver.</td>
                                    <td>35%</td>
                                    <td><span class="text-success">Verified</span></td>
                                    <td>01-02-2014 <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a>
                                    </td>
                                    <td>Ayush <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a></td>
                                    <td> dolor sit amet, consectetur...... <a href="javascript:;" class="editbtn"><i
                                                class="bi bi-pencil-fill"></i></a></td>
                                    <td>
                                        <a href="question-answer-details.html" class="btn btn-small-white me-2"><i
                                                class="bi bi-eye-fill"></i> View Solution</a>
                                        <a href="javascript:;" class="btn btn-outline-purewhite full-rounded"><i
                                                class="bi bi-file-earmark-arrow-down-fill"></i> Download</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </section> -->

    <!-- <section class="p-0">
        <div class="bg-light-gray p-2 text-center">
            Bid Submission
        </div>
        <section class="bg-dark">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h5 class="text-white mb-4"><strong>Summary Note Questions</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-dark table-borderless" style="background-color: #252525;">
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Weightage</th>
                                    <th>Status</th>
                                    <th>Deadline</th>
                                    <th>Assign User</th>
                                    <th>Add Review Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let question of  summaryQuestionList">
                                    <tr *ngIf="question?.summaryQuestionFor == 'Supplier'">
                                        <td width="350">{{ question?.question }}</td>
                                        <td>{{ question?.weightage }} % </td>
                                        <td>
                                            <span *ngIf="question?.verify" class="text-success"> Verified</span>
                                            <span *ngIf="!question?.verify"> Not Verified</span>
                                        </td>
                                        <td> {{ question?.deadline | date:'dd-MM-YYYY' }}</td>
                                        <td>Ayush</td>
                                        <td> {{ question?.comment }} </td>
                                        <td>
                                            <a (click)="questionDetails(question)" class="btn btn-small me-2"><i
                                                    class="bi bi-eye-fill"></i>
                                                View Solution</a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div class="row">
                            <p *ngIf="summaryQuestionList?.length == 0" style="text-align: center;"> No Record Found !</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-primary">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h5 class="text-white mb-4"><strong>Summary Note Questions - UK</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-primary table-borderless" style="background-color: #252525;">
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Weightage</th>
                                    <th>Status</th>
                                    <th>Deadline</th>
                                    <th>Assign User</th>
                                    <th>Add Review Note</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let questionUk of  summaryQuestionList">
                                    <tr *ngIf="questionUk?.summaryQuestionFor == 'UKWriter'">
                                        <td width="350">{{ questionUk?.question }}</td>
                                        <td>{{ questionUk?.weightage }} % </td>
                                        <td>
                                            <span *ngIf="questionUk?.verify" class="text-success"> Verified</span>
                                            <span *ngIf="!questionUk?.verify"> Not Verified</span>
                                        </td>
                                        <td> {{ questionUk?.deadline | date:'dd-MM-YYYY' }}</td>
                                        <td>Ayush</td>
                                        <td> {{ questionUk?.comment }} </td>
                                        <td>
                                            <a (click)="questionDetails(questionUk)" class="btn btn-small me-2"><i
                                                    class="bi bi-eye-fill"></i>
                                                View Solution</a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <p *ngIf="summaryQuestionList?.length == 0" style="text-align: center;"> No Record Found !</p>
                    </div>
                </div>
            </div>
        </section>
    </section> -->


    <!-- <section class="p-0">
        <div class="bg-light-gray p-2 text-center">
            Project Co-Ordinator
        </div>
    </section> -->


    <!-- <section>
        <div class="container-fluid">
            <h5 class="mb-3"><strong>Add Supporting docs</strong></h5>
            <div class="row" [formGroup]="supportDocument">
                <div class="col-lg-6 col-xl-6 col-12" formArrayName="document">
                    <div *ngFor="let skill of document?.controls; let i=index" class="form-group"
                        x-data="{ fileName: '' }">
                        <div class="input-group" [formGroupName]="i">
                            <input type="text" class="form-control file-control" formControlName="key"
                                placeholder="Upload Image" x-model="fileName">
                            <input type="file" x-ref="file" class="d-none" [id]="'fileInput'+ i" name="myfile"
                                style="height: 10px; width: 10px;" (change)="addFiles($event, i)">
                            <label [for]="'fileInput'+ i" class="browse btn btn-primary border-radius6 me-2"
                                type="button" x-on:click.prevent="$refs.file.click()"><i
                                    class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                                Document</label>
                            <button class="me-2 btn btn-primary" (click)="addDocument()">Add</button>
                            <button class="me-2 btn btn-secondary" (click)="removeDocument(i)">Remove</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- <section>
        <div class="container-fluid">
            <h5 class="mb-3"><strong>Add Project Stage <button (click)="addStage()" type="button"
                        class="btn btn-dark btn-sm"><i class="bi bi-plus-lg"></i></button> </strong></h5>
            <div class="row" [formGroup]="projectStage">
                <div class="col-lg-8 col-xl-8 col-12" formArrayName="stage">
                    <div class="row" *ngFor="let stage of stage?.controls; let i=index" [formGroupName]="i">
                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label class="display-block">&nbsp;</label>
                                <input type="text" formControlName="text" class="form-control" placeholder="Stage 1">
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label>Start Date</label>
                                <input type="date" formControlName="startDate" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label>End Date</label>
                                <input type="date" formControlName="endDate" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12">
                            <div class="form-group d-flex align-items-center">
                                <button (click)="removeStage(i)" class="btn btn-secondary sm">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" class="btn btn-success" (click)="updateProject()">Submit</button>
                </div>
            </div>
        </div>
    </section> -->


    <!-- <section>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-9 col-xl-9 col-md-12 col-sm-12 col-12">
                    <div id="chatWindow">
                        <div class="chatHistory">
                            <ul>
                                <li>
                                    <div class="sent">
                                        <p class="team-name">Team A</p>
                                        <p>How the hell am I supposed to get a jury to believe you when I am not even
                                            sure that I do?!</p>
                                        <p class="recived-time"><span class="read-tick"><i
                                                    class="bi bi-check2-all"></i></span>3:15PM</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="replies">
                                        <p class="team-name">Team B</p>
                                        <p>How the hell am I supposed to get a jury to believe you when I am not even
                                            sure that I do?!</p>
                                        <p class="recived-time"><span class="unread-tick"><i
                                                    class="bi bi-check2-all"></i></span>Just Now
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="sent">
                                        <p class="team-name">Team A</p>
                                        <p>How the hell am I supposed to get a jury to believe you when I am not even
                                            sure that I do?!</p>
                                        <p class="recived-time"><span class="read-tick"><i
                                                    class="bi bi-check2-all"></i></span>3:15PM</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="replies">
                                        <p class="team-name">Team B</p>
                                        <p>How the hell am I supposed to get a jury to believe you when I am not even
                                            sure that I do?!</p>
                                        <p class="recived-time"><span class="unread-tick"><i
                                                    class="bi bi-check2-all"></i></span>Just Now
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="chatsendBox" style="display: block;">
                            <div class="row align-items-center">
                                <div class="col-lg-9 col-xl-9 col-12">
                                    <div class="row align-items-center">
                                        <div class="col-auto text-center">
                                            <button type="button" class="btn text-theme"><i
                                                    class="bi bi-mic-fill"></i></button>
                                        </div>
                                        <div class="col-9 p-0 mob-mb1">
                                            <input type="text" class="form-control border-0 ps-0"
                                                placeholder="Enter your text here!">
                                        </div>
                                        <div class="col-auto text-center mobile-hide">
                                            <button type="button" class="btn text-theme"><i
                                                    class="bi bi-send-fill p-22"></i></button>
                                        </div>
                                        <div class="col-12 mob-mb1 desktop-hide mobile-show">
                                            <button type="button" class="btn btn-primary w-100">Send</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-xl-3 col-12">
                                    <ul class="floatingbuttons">
                                        <li><a href="javascript:;"><i class="bi bi-paperclip"></i></a></li>
                                        <li><a href="javascript:;"><i class="bi bi-at"></i></a></li>
                                        <li><a href="javascript:;"><i class="bi bi-emoji-smile-fill"></i></a></li>
                                        <li><a href="javascript:;"><i class="bi bi-play-circle-fill"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <a href="javascript:;" class="google-meet"><img src="assets/img/google-meet.svg"></a>

                    </div>

                </div>
            </div>
        </div>
    </section> -->


</main>

<!-- View Document Modal -->
<div class="modal fade" id="ViewDocumentModal" tabindex="-1" aria-labelledby="ViewDocumentModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between;">
                <h5 class="modal-title" id="ViewDocumentModalLabel">FOI Document ({{selectedDocument?.name}})</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="isPdf(selectedDocument?.link?.url); else otherFormats">
                    <ngx-extended-pdf-viewer [src]="selectedDocument?.link?.url" useBrowserLocale="true"
                        height="600px"></ngx-extended-pdf-viewer>
                </ng-container>
                <ng-template #otherFormats>
                    <iframe *ngIf="isWordOrExcel(selectedDocument?.link?.url)"
                        [src]="getDocumentViewerUrl(selectedDocument?.link?.url)"
                        style="width: 100%; height: 600px;"></iframe>
                    <img *ngIf="isImage(selectedDocument?.link?.url)" [src]="selectedDocument?.link?.url"
                        class="img-fluid" />
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- Login detail From modal -->
<div class="modal fade" id="loginDetailModal" tabindex="-1" aria-labelledby="ViewLogin" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" [formGroup]="loginDetailForm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ViewLogin">Add Login Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label>Company Name</label>
                <input type="text" formControlName="companyName" class="form-control" />
                <div class="text-danger" style="color: red"
                    *ngIf="loginDetailForm.controls['companyName'].touched && loginDetailForm.controls['companyName']?.errors?.['required']">
                    Please Company Name
                </div>
                <label>Website Link</label>
                <input type="text" formControlName="link" class="form-control" />
                <div class="text-danger" style="color: red"
                    *ngIf="loginDetailForm.controls['link'].touched && loginDetailForm.controls['link']?.errors?.['required']">
                    Please enter Website Link
                </div>
                <label>Login Id</label>
                <input type="text" formControlName="loginID" class="form-control" />
                <div class="text-danger" style="color: red"
                    *ngIf="loginDetailForm.controls['loginID'].touched && loginDetailForm.controls['loginID']?.errors?.['required']">
                    Please enter Login Id
                </div>
                <label>Password</label>
                <input [type]="password" formControlName="password" class="form-control" />
                <span class="showicon" *ngIf="!showPassword" (click)="showHidePass()"><i
                        class="bi bi-eye-fill"></i></span>
                <span class="showicon" *ngIf="showPassword" (click)="showHidePass()"><i
                        class="bi bi-eye-slash-fill"></i></span>
                <div class="text-danger" style="color: red"
                    *ngIf="loginDetailForm.controls['password'].touched && loginDetailForm.controls['password']?.errors?.['required']">
                    Please enter Password
                </div>
                <div class="row justify-content-end">
                    <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
                        <button (click)="addLoginInfo()" data-bs-dismiss="modal" aria-label="Close"
                            [disabled]="!loginDetailForm.valid" class="btn btn-dark w-100">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="ViewUploadedDocument" tabindex="-1" aria-labelledby="ViewUploadedDocumentModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between;">
                <h5 class="modal-title" id="ViewUploadedDocumentModal">Document</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="isPdf(uploadedDocument?.url); else otherFormatsForDocument">
                    <ngx-extended-pdf-viewer [src]="uploadedDocument?.url" useBrowserLocale="true"
                        height="600px"></ngx-extended-pdf-viewer>
                </ng-container>
                <ng-template #otherFormatsForDocument>
                    <iframe *ngIf="isWordOrExcel(uploadedDocument?.url)"
                        [src]="getDocumentViewerUrl(uploadedDocument?.url)"
                        style="width: 100%; height: 600px;"></iframe>
                    <img *ngIf="isImage(uploadedDocument?.url)" [src]="uploadedDocument?.url" class="img-fluid" />
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ViewClientDoc" tabindex="-1" aria-labelledby="ViewClientDocLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ViewClientDocLabel">
                    Client Document : {{ selectedDocument?.name }}
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modal-body">
                    <ng-container *ngIf="isPdf(selectedDocument?.file?.url); else otherFormats">
                        <ngx-extended-pdf-viewer [src]="selectedDocument?.file?.url" useBrowserLocale="true"
                            height="600px"></ngx-extended-pdf-viewer>
                    </ng-container>
                    <ng-template #otherFormats>
                        <iframe *ngIf="isWordOrExcel(selectedDocument?.file?.url)"
                            [src]="getDocumentViewerUrl(selectedDocument?.file?.url)"
                            style="width: 100%; height: 600px;"></iframe>
                        <img *ngIf="isImage(selectedDocument?.file?.url)" [src]="selectedDocument?.file?.url"
                            class="img-fluid" />
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- View Document Modal -->
<div class="modal fade" id="ViewCaseModal" tabindex="-1" aria-labelledby="ViewDocumentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between;">
                <h5 class="modal-title" id="ViewDocumentModalLabel">Case Study ({{selectedDocument?.name}})</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="isPdf(selectedDocument?.link?.url); else otherFormats">
                    <ngx-extended-pdf-viewer [src]="selectedDocument?.link?.url" useBrowserLocale="true"
                        height="600px"></ngx-extended-pdf-viewer>
                </ng-container>
                <ng-template #otherFormats>
                    <iframe *ngIf="isWordOrExcel(selectedDocument?.link?.url)"
                        [src]="getDocumentViewerUrl(selectedDocument?.link?.url)"
                        style="width: 100%; height: 600px;"></iframe>
                    <img *ngIf="isImage(selectedDocument?.link?.url)" [src]="selectedDocument?.link?.url"
                        class="img-fluid" />
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>