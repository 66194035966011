<!-- ======= Header ======= -->
<header id="header">
  <div class="container-fluid d-flex align-items-center">
    <a routerLink="/supplier-admin/supplier-dashboard-header" class="logo mee-auto"><img src="assets/img/newlogoo.png" alt=""
        class="img-fluid"></a>
    <nav id="navbar" class="navbar w-100">
      <!-- <ul>
          <li><a class="nav-link active" routerLink="/supplier-admin/supplier-dashboard-header">Dashboard</a></li>

          <li><a class="nav-link" routerLink="/supplier-admin/project-list">Projects</a></li>

          <li><a class="nav-link" routerLink="/supplier-admin/case-studies-list">Case Studies</a></li>

          <li><a class="nav-link" routerLink="/supplier-admin/manage-user">Manage Users</a></li>

          <li class="dropdown"><a href="javascript:;">
              <div class="login-avtar">
                <img src="assets/img/face1.jpg" />
              </div>
              <span>{{loginUser?.userName}}</span> <i class="bi bi-chevron-down"></i>
            </a>
            <ul>
              <li><a routerLink="/supplier-admin/supplier-user-profile">Profile Seting</a></li>
              <li><a style="cursor: pointer;" (click)="logout()">Logout</a></li>
            </ul>
          </li>

        </ul> -->
      <ul class="mx-auto">
        <li class="nav-link noarrowdrop" *ngFor="let item of navUrlArr">
          <a [class.active]="(item.route.includes(router.url))" class="nav-link " data-toggle="tab"
            [routerLink]="item.route">
            <span>{{item.titel}}</span>
          </a>
        </li>
        <!-- Notification  -->
        <!-- <li class="nav-link noarrowdrop">
            <a style="cursor: pointer;"
                    id="notificationDropdown" data-toggle="dropdown">
                    <i class="bi bi-list mobile-nav-toggle"></i>
                    <span class="count"></span>
              </a>
          </li> -->

      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>


    <nav class="navbar">
      <ul class="d-block">
        <li class="nav-link noarrowdrop">
          <a style="cursor: pointer;" id="notificationDropdown" (click)="toggleDropdown()" data-toggle="dropdown">
            <i class="bi bi-bell" style="font-size: 17px;"></i> <span class="count-badge">{{projectNotificationCount?.unreadNotifications}}</span>
          </a>
          <div #notificationDiv class="dropdown-menu dropdown-menu-right navbar-dropdown notificationDropdown-box"
            aria-labelledby="notificationDropdown" [ngClass]="{'show-menu': clicked}">
            <div class="dropdown-header">
              <div class="row align-items-center">
                <div class="col-8">
                  <h5 class="mb-0 p-12">Notifications</h5>
                </div>
                <div class="col-4">
                  <a routerLink="/supplier-admin/notification" (click)="closeDropdown()" style="font-size: 12px;">View all</a>
                </div>
              </div>
            </div>
            <ul class="notification-list">
              <li *ngFor="let item of firstFourNotifications">
                <a>
                  <p class="mb-0">{{item?.title}}</p>
                  <p class="mb-0 preview-subject font-weight-normal p-12">{{item?.discription}}</p>
                  <p class="mb-0 font-weight-light small-text mb-0 text-muted p-12">
                    {{item?.updatedAt | date:'dd/MM/yyyy, h:mm a'}}
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>


    <nav class="navbar">
      <ul class="d-block">
        <li class="dropdown"><a href="javascript:;">
            <div class="login-avtar">
              <img src="assets/img/face1.jpg" />
            </div>
            <span class="mobile-hide">{{loginUser?.userName}}</span> <i class="bi bi-chevron-down"></i>
          </a>
          <ul>
            <li><a routerLink="/supplier-admin/supplier-user-profile">Profile Seting</a></li>
            <li><a style="cursor: pointer;" (click)="logout()">Logout</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</header><!-- End Header -->
