<div class="welcome-page" style="height: 100vh; overflow: auto">
  <main id="main">
    <section class="p-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6 col-12 desktop-pt200">
            <h1 class="Heading-h1"><strong>Welcome back!</strong></h1>
            <a
              routerLink="/project-coordinator/project-coordinator-projects-list"
              class="btn btn-primary btn-small mb-4"
              >View Projects</a
            >
            <div>
              <p class="fonts-20 opacity-7 mb-1">
                Total Projects Reviewed :
                {{ dashboardData?.totalProjects || 0 }}
              </p>
              <!-- <p class="fonts-20 opacity-7 mb-1">
                Total Projects Finalized :
                {{ dashboardData?.totalProjectsFinalized || 0 }}
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
