<main id="main">
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
          <div class="table-responsive mt-5 mb-3">
            <table class="table table-striped align-middle">
              <thead>
                <tr>
                  <th width="100px">Project Title</th>
                  <!-- <th>Project Description</th> -->
                  <th>BOS ID</th>
                  <th style="cursor: pointer;" (click)="sort('publishDate')">Publish Date <i class="bi bi-sort-up"></i>
                  </th>
                  <th>Category</th>
                  <th>Industry</th>
                  <th>Min value (GBP)</th>
                  <th>Max value (GBP)</th>
                  <th>Website</th>
                  <th>Link to notice</th>
                  <th style="cursor: pointer;" (click)="createddatesort('createdAt')">Created Date <i
                      class="bi bi-sort-up"></i></th>
                  <th style="cursor: pointer;" (click)="duedatesort('dueDate')">Due Date & Time <i
                      class="bi bi-sort-up"></i></th>
                  <th [width]="120"></th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td *ngIf="!projectList?.length" colspan="14">
                    <app-no-record-found></app-no-record-found>
                  </td>
                </tr>

                <tr
                  *ngFor="let item of projectList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                  <td><a (click)="projectDetails(item?._id)" href="javascript:;">{{item?.projectName}}</a></td>
                  <!-- <td><a title="{{ item?.description }}">{{ item?.description | slice:0:10 }}</a></td> -->
                  <td v-align="middle">{{item?.BOSID}}</td>
                  <td>{{item?.publishDate | date:'dd/MM/yyyy'}}</td>
                  <td>{{item?.category}}</td>
                  <td>{{item?.industry}}</td>
                  <td>£ {{item?.minValue}}</td>
                  <td>£ {{item?.maxValue}}</td>
                  <td><a>{{item?.website}}</a></td>
                  <td><a href='{{item?.link}}' target="_blank">Link to notice</a></td>
                  <td>{{item?.createdAt | date:'dd/MM/yyyy'}}</td>
                  <td>{{item?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                  <td>
                    <!-- <a class="btn btn-primary btn-sm me-2" style="cursor: pointer;"
                      (click)="projectDetails(item?._id)"><i class="bi bi-eye-fill"></i></a> -->&nbsp;&nbsp;
                    <a class="btn btn-primary btn-sm" style="cursor: pointer;"
                      (click)="editProjectDetails(item?._id)"><i class="bi bi-pencil-fill"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mt-3 align-items-center">
            <div class="row">
              <div class="pagination">
                <pagination-controls (pageChange)="paginate($event)" [idMaxSize]="100"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>