<main id="main">

  <section class="casestudy-section">
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/supplier-user-profile">
              <a>Profile</a>
            </li>
            <li routerLink="/super-admin/admin-case-study-list">
              <a class="active">Historical Data</a>
            </li>
            <li routerLink="/super-admin/expertise-list">
              <a>Case Studies</a>
            </li>
            <li routerLink="/super-admin/resources-list">
              <a>Resources</a>
            </li>
            <li routerLink="/super-admin/supplier-user-activity">
              <a>Activity</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-10 col-12">
            <h4 class="text-theme"><strong>Historical Data</strong></h4>
          </div>
          <div class="col-lg-2 col-xl-2 col-12">
            <nav id="navbar" class="navbar">
              <ul>
                <li class="dropdown">
                  <span class="btn btn-outline-white w-100">+ Add Case Study</span>
                  <ul>
                    <li><a routerLink="/super-admin/add-new-case-study">Single Entry</a></li>
                    <li><a (click)="openAddTeamModal()">Bulk Entry</a></li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
          <div class="table-responsive mt-5 mb-3">
            <table class="table table-striped align-middle">
              <thead>
                <tr>
                  <th width="100px">Project Name</th>
                  <th>Industry</th>
                  <th>Type</th>
                  <th>Start Date</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td *ngIf="!caseStudyList?.length" colspan="13">
                    <app-no-record-found></app-no-record-found>
                  </td>
                </tr>

                <tr
                  *ngFor="let item of caseStudyList |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
                  <td>{{item?.name}}</td>
                  <td>{{item?.industry}}</td>
                  <td>{{item?.type}}</td>
                  <td>{{item?.date | date:'dd/MM/yyyy'}}</td>
                  <td><a title="{{ item?.description }}">{{ item?.description | slice:0:10 }}</a></td>
                  <td>
                    <div class="d-flex gap-2">
                      <button class="btn btn-sm btn-primary" (click)="viewCaseStudyDetails(item)" title="View Details">
                        <i class="bi bi-eye"></i> View
                      </button>
                      <a class="btn btn-primary btn-sm" style="cursor: pointer;"
                          routerLink="/super-admin/add-new-case-study" [state]="item">
                        <i class="bi bi-pencil-fill"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="row">
          <div class="pagination">
            <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<div class="modal fade" id="ViewDocumentModal" tabindex="-1" aria-labelledby="ViewDocumentModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between;">
        <h5 class="modal-title" id="ViewDocumentModalLabel">Case Study ({{selectedDocument?.name}})</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isPdf(selectedDocument?.link?.url); else otherFormats">
          <ngx-extended-pdf-viewer [src]="selectedDocument?.link?.url" useBrowserLocale="true"
            height="600px"></ngx-extended-pdf-viewer>
        </ng-container>
        <ng-template #otherFormats>
          <iframe *ngIf="isWordOrExcel(selectedDocument?.link?.url)"
            [src]="getDocumentViewerUrl(selectedDocument?.link?.url)" style="width: 100%; height: 600px;"></iframe>
          <img *ngIf="isImage(selectedDocument?.link?.url)" [src]="selectedDocument?.link?.url" class="img-fluid" />
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ViewTemplate" tabindex="-1" aria-labelledby="ViewDocumentModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between;">
        <h5 class="modal-title" id="ViewDocumentModalLabel">Case Study Template</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container>
          <!-- <ngx-extended-pdf-viewer [src]="selectedCasestudy" useBrowserLocale="true"
                      height="600px"></ngx-extended-pdf-viewer> -->
          <iframe *ngIf="isWordOrExcel(selectedCasestudy)" [src]="getDocumentViewerUrl(selectedCasestudy)"
            style="width: 100%; height: 600px;"></iframe>
        </ng-container>
        <!-- <ng-template #otherFormats>
                  <iframe *ngIf="isWordOrExcel(selectedCasestudy)"
                      [src]="getDocumentViewerUrl(selectedCasestudy)"
                      style="width: 100%; height: 600px;"></iframe>
                  <img *ngIf="isImage(selectedCasestudy)" [src]="selectedCasestudy"
                      class="img-fluid" />
              </ng-template> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="caseStudyDetailsModal" tabindex="-1" aria-labelledby="caseStudyDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="caseStudyDetailsModalLabel">Case Study Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- Left Column -->
          <div class="col-lg-6 col-xl-6 col-12">
            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Start Date</label>
              <div class="col-sm-8">
                <input type="date" class="form-control" [value]="selectedCaseStudy?.date | date:'yyyy-MM-dd'" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Project/Application name</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.name" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Type</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.type" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Industry</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.industry" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Description</label>
              <div class="col-sm-8">
                <textarea class="form-control" rows="3" readonly>{{selectedCaseStudy?.description}}</textarea>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Problem</label>
              <div class="col-sm-8">
                <textarea class="form-control" rows="3" readonly>{{selectedCaseStudy?.problem}}</textarea>
              </div>
            </div>
          </div>

          <!-- Right Column -->
          <div class="col-lg-6 col-xl-6 col-12">
            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Duration (in months)</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.contractDuration" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Technologies</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.technologies" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Solution Provided</label>
              <div class="col-sm-8">
                <textarea class="form-control" rows="3" readonly>{{selectedCaseStudy?.solutionProvided}}</textarea>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Contract Value</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.contractValue" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Resources Used</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.resourcesUsed" readonly>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Client Name</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" [value]="selectedCaseStudy?.clientName" readonly>
              </div>
            </div>
          </div>
        </div>

        <!-- Bottom Row -->
        <div class="row">
          <div class="col-lg-6 col-xl-6 col-12">
            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Results Achieved</label>
              <div class="col-sm-8">
                <textarea class="form-control" rows="3" readonly>{{selectedCaseStudy?.resultAchieved}}</textarea>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-6 col-12">
            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Lessons Learned</label>
              <div class="col-sm-8">
                <textarea class="form-control" rows="3" readonly>{{selectedCaseStudy?.lessonsLearned}}</textarea>
              </div>
            </div>
          </div>
        </div>

        <!-- Document Section -->
        <div class="row mt-3" *ngIf="selectedCaseStudy?.link?.url">
          <div class="col-12">
            <div class="row mb-3">
              <label class="col-sm-4 col-form-label fw-bold">Document</label>
              <div class="col-sm-8">
                <div class="d-flex gap-2">
                  <button class="btn btn-sm btn-primary" (click)="openDocument(selectedCaseStudy)">
                    <i class="bi bi-eye"></i> View Document
                  </button>
                  <button class="btn btn-sm btn-success" (click)="download(selectedCaseStudy?.link?.url, selectedCaseStudy?.name)">
                    <i class="bi bi-download"></i> Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
