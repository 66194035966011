<main id="main">
  <section>
    <div class="container-fluid mb-5">
      <div class="row">
        <div class="col-12 mb-3">
          <h4 class="text-theme"><strong>Welcome Admin!</strong></h4>
        </div>
        <div class="col-12 mb-3">
          <div class="row">
            <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-6">
              <div class="form-group">
                <input class="form-control" type="date" [formControl]="trackerStartDate" />
              </div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-6">
              <div class="form-group">
                <input class="form-control" type="date" [formControl]="trackerEndDate" />
              </div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-12 d-flex gap-2">
              <button class="btn btn-primary" type="button" (click)="onSubmitDaterange()">Submit</button>
              <button class="btn btn-primary" type="button" (click)="export()">Download Excel</button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center gap-3">
                <!-- DPS Checkbox -->
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="dps" (change)="updateCategorisation('DPS', $event)" />
                  <label class="form-check-label" for="dps">DPS</label>
                </div>

                <!-- Framework Checkbox -->
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="Framework" (change)="updateCategorisation('Framework', $event)" />
                  <label class="form-check-label" for="Framework">Framework</label>
                </div>

                <!-- DTD Checkbox -->
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="dtd" (change)="updateCategorisation('DTD', $event)" />
                  <label class="form-check-label" for="dtd">DTD</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5">
      <div class="row">
        <div class="col-lg-6 col-xl-6 col-12">
          <div class="statistic-card mb-4">
            <div class="card-body card-header card-header-blue">
              <h5 class="text-theme">
                <strong>Admin Dashboard Report</strong>
              </h5>
              <br />
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>{{ superdashboardlist?.projectsPosted?.count }}</h2>
                    <p>Projects Posted</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsPosted?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>{{ superdashboardlist?.projectsAwaiting?.count }}</h2>
                    <p>Projects Awaiting</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsAwaiting?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>{{ superdashboardlist?.projectsInProgress?.count }}</h2>
                    <p>Projects In-Progress</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsInProgress?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>{{ superdashboardlist?.projectsInHold?.count }}</h2>
                    <p>Projects In-Hold</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsInHold?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{ superdashboardlist?.TotalDocumentsNotFound?.count }}
                    </h2>
                    <p>Total Documents Not Found</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.TotalDocumentsNotFound?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>{{ superdashboardlist?.projectsPassed?.count }}</h2>
                    <p>Projects Passed</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsPassed?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>{{ superdashboardlist?.projectsFail?.count }}</h2>
                    <p>Projects Failed</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsFail?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{ superdashboardlist?.projectsBidStatusAwaiting?.count }}
                    </h2>
                    <p>Bid Status Awaiting</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusAwaiting?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{
                      superdashboardlist?.projectsBidStatusInSolution?.count
                      }}
                    </h2>
                    <p>Bid Status In-Solution</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusInSolution
                      ?.maxValue/ 1000000) | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{
                      superdashboardlist?.projectsBidStatusWaitingForResult
                      ?.count
                      }}
                    </h2>
                    <p>Bid Status Waiting for Result</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusWaitingForResult
                      ?.maxValue/ 1000000) | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{
                      superdashboardlist
                      ?.projectsBidStatusDroppedAfterFeasibility?.count
                      }}
                    </h2>
                    <p>Bid Status Dropped After Feasibility</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist
                      ?.projectsBidStatusDroppedAfterFeasibility?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{ superdashboardlist?.projectsBidStatusAwarded?.count }}
                    </h2>
                    <p>Bid Status Awarded</p>
                    <p class="text-success">
                      Value: £
                      {{
                      superdashboardlist?.projectsBidStatusAwarded?.maxValue
                      | number : "1.2-2"
                      }}
                      GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{
                      superdashboardlist?.projectsBidStatusNotAwarded?.count
                      }}
                    </h2>
                    <p>Bid Status Not Awarded</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusNotAwarded
                      ?.maxValue/ 1000000) | number : "1.2-2"
                      }}
                      M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{ superdashboardlist?.projectsBidStatusToAction?.count }}
                    </h2>
                    <p>Bid Status To Action</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusToAction?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                     M GBP
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{ superdashboardlist?.projectsBidStatusNosuppliermatched?.count }}
                    </h2>
                    <p>No Supplier Matched</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusNosuppliermatched?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                     M GBP
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{
                      superdashboardlist?.projectsBidStatusGoNoGoStage1?.count
                      }}
                    </h2>
                    <p>GO-NO GO STAGE 1</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusGoNoGoStage1
                      ?.maxValue/ 1000000) | number : "1.2-2"
                      }}
                     M GBP
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{
                      superdashboardlist
                      ?.projectsBidStatusSupplierConfirmation?.count
                      }}
                    </h2>
                    <p>SUPPLIER CONFIRMATION</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist
                      ?.projectsBidStatusSupplierConfirmation?.maxValue/ 1000000)
                      | number : "1.2-2"
                      }}
                     M GBP
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                  <div class="ProjectStatistic">
                    <h2>
                      {{
                      superdashboardlist?.projectsBidStatusGoNoGoStage2?.count
                      }}
                    </h2>
                    <p>GO-NO GO STAGE 2</p>
                    <p class="text-success">
                      Value: £
                      {{
                      (superdashboardlist?.projectsBidStatusGoNoGoStage2
                      ?.maxValue/ 1000000) | number : "1.2-2"
                      }}
                     M GBP
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-6 col-12">
          <div class="statistic-card mb-4">
            <div class="card-body">
              <h5 class="text-theme"><strong>Suppliers Statistics</strong></h5>
              <div class="table-responsive mt-3">
                <table class="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th width="200px">Supplier Name</th>
                      <th>Last Login</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td *ngIf="!supplierUserList?.length" colspan="3">
                        <app-no-record-found></app-no-record-found>
                      </td>
                    </tr>

                    <tr *ngFor="
                        let item of supplierUserList
                          | paginate
                            : {
                                itemsPerPage: pagesize,
                                currentPage: page,
                                totalItems: totalRecords
                              };
                        let i = index
                      ">
                      <td>{{ item?.name }}</td>
                      <td>
                        {{
                        item?.lastLogin
                        ? (item.lastLogin | date : "dd/MM/yyyy h:mm:ss a")
                        : "-"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="row">
                  <div class="pagination">
                    <pagination-controls (pageChange)="paginate($event)" [idMaxSize]="100"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-xl-6 col-12">
          <div class="statistic-card mb-4">
            <div class="card-body">
              <h5 class="text-theme mb-0">
                <strong>Category Wise Report</strong>
              </h5>
              <div class="table-responsive mt-3 mb-3">
                <table class="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th width="200px">Category</th>
                      <th>Total Projects</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td *ngIf="!categoryWise?.length" colspan="2">
                        <app-no-record-found></app-no-record-found>
                      </td>
                    </tr>
                    <tr
                      *ngFor="
                        let item of categoryWise | paginate : { itemsPerPage: pagesize, currentPage: page, totalItems: totalRecords }; let i = index ">
                      <td>
                        <a href="javascript:;" (click)="onCategoryClick(item?.name)">{{ item?.name || "Unknown" }}</a>
                      </td>
                      <td>
                        {{ item?.totalProjects !== null ? item.totalProjects : "-" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-12">
          <div class="statistic-card mb-4">
            <div class="card-body">
              <h5 class="text-theme mb-0">
                <strong>Product Type Wise Report</strong>
              </h5>
              <div class="table-responsive mt-3 mb-3">
                <table class="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th width="200px">Product Type</th>
                      <th>Total Projects</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td *ngIf="!projectTypeWise?.length" colspan="2">
                        <app-no-record-found></app-no-record-found>
                      </td>
                    </tr>
                    <tr
                      *ngFor=" let item of projectTypeWise | paginate : { itemsPerPage: pagesize, currentPage: page, totalItems: totalRecords }; let i = index ">
                      <td>
                        <a href="javascript:;" (click)="projectTypeClick(item?.name)">{{ item?.name }}</a>
                      </td>
                      <td>
                        {{ item?.totalProjects !== null ? item.totalProjects : "-" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
