<body class="bg-blue">
    <section class="pageheader pt-5">
        <div class="container">
            <div class="row justify-content-end">
                <div class="col-2">
                    <a (click)="back()" class="btn btn-primary text-dark">Back</a>
                </div>
            </div>
        </div>
    </section>
    <main id="main">
        <section>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <!-- <p class="mb-0"> Question Name </p> -->
                                <label>Question Name</label>
                                <input type="text" class="form-control" [value]="questionDetails?.questionName" disabled>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <!-- <p class="mb-0"> Question Name </p> -->
                                <label>Question</label>
                                <textarea class="form-control" rows="3"
                                cols="100" disabled>{{questionDetails?.question}}</textarea>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <!-- <p class="mb-0"> Reference Doscument & Page number </p> -->
                                <label>Reference Doscument & Page number</label>
                                <input type="text" class="form-control" [value]="questionDetails?.refrenceDocument" disabled>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <!-- <p class="mb-0"> Weightage </p> -->
                                <label>Weightage</label>
                                <input type="text" class="form-control" [value]="questionDetails?.weightage" disabled>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body">
                                <!-- <p class="mb-0"> Deadline </p> -->
                                <label>Deadline</label>
                                <input type="text" class="form-control" [value]="questionDetails?.deadline | date : 'dd MMM YYYY'" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-7 col-md-8 col-sm-8 col-12">
                        <div class="card">
                            <div class="card-body">
                                <p> <strong>Instructions from WestGate/ Spectrum</strong> </p>
                                <textarea class="form-control" rows="7"
                                  cols="100" disabled>{{questionDetails?.instructions}}</textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</body>
