import { Component } from '@angular/core';

@Component({
  selector: 'app-expired-project',
  templateUrl: './expired-project.component.html',
  styleUrls: ['./expired-project.component.scss']
})
export class ExpiredProjectComponent {

}
