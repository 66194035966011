<div class="container-fluid py-4">
  <!-- Loading Spinner -->
  <ngx-spinner *ngIf="showLoader" type="ball-clip-rotate" size="medium" color="#5cb85c">
    <p style="color: white">Loading...</p>
  </ngx-spinner>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <div class="card-header bg-light">
          <div class="d-flex justify-content-between align-items-center">
            <h4>{{ modalTask?.task }}</h4>
            <button class="btn btn-sm btn-outline-secondary" (click)="goBack()">
              <i class="bi bi-arrow-left"></i> Back
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-lg-8 col-xl-8 col-12 mob-mb1">
              <div class="mb-3" *ngIf="modalTask?.discription">
                <h6><strong>Description</strong></h6>
                <p>{{ modalTask?.discription }}</p>
                <p *ngIf="!modalTask?.discription">No Record Found.</p>
              </div>

              <div class="mb-3" *ngIf="modalTask?.project">
                <h6><strong>Assigned Project</strong></h6>
                <a (click)="projectDetails(modalTask?.project?._id)" href="javascript:;">{{ modalTask?.project?.projectName
                  }}</a>
              </div>
              <div class="mb-3" *ngIf="modalTask?.project">
                <h6><strong>Status</strong></h6>
                <p> {{ modalTask?.project?.status }}</p>
              </div>
              <div class="mb-3" *ngIf="modalTask?.project">
                <h6><strong>Bid Status</strong></h6>
                <p> {{ modalTask?.project?.bidManagerStatus}}</p>
              </div>

              <div class="comments-section">
                <h6><strong>Comment</strong></h6>
                <form [formGroup]="commentForm">
                  <div class="mb-2">
                    <div class="NgxEditor__Wrapper">
                      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" style="padding: 15px !important;">
                      </ngx-editor-menu>
                      <ngx-editor [editor]="editor" rows="5" formControlName="description" [disabled]="false"
                        [placeholder]="'Type here...'"></ngx-editor>
                    </div>
                    <div class="d-flex align-items-center gap-2 mt-2">
                      <button class="btn btn-sm btn-primary" (click)="addCommentWithEditor(modalTask?._id)">
                        Comment
                      </button>
                      <button class="btn btn-sm btn-primary" (click)="onChangeMyday('MyDay')">
                        Add to My Day
                      </button>
                      <button class="btn btn-sm btn-success" (click)="onChange('completedTask', true)">
                        Completed Task
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="comments-section">
                <ul class="SecondarytaskList mt-3">
                  <li *ngFor="let comment of transformData(modalTask?.datewiseComments); let i = index"
                    class="comment-item p-2" [ngClass]="{ 'admin-comment': comment?.userDetail?.role === 'Admin' }">
                    <h6 *ngIf="comment?.comment == 'No comments available for this date'"> {{ comment?.commentDate | date :
                      "dd/MM/yyyy" }} </h6>
                    <div class="row align-items-center mb-3">
                      <div class="col">
                        <div class="d-flex justify-content-between">
                          <p class="mb-0 user-name">{{ comment?.userDetail?.name }}</p>
                          <small class="comment-date">{{
                            comment?.date | date : "dd/MM/yyyy, h:mm a"
                            }}</small>
                        </div>
                        <small class="user-role d-block">
                          {{ comment?.userDetail?.role === 'BOS' ? 'USER' : comment?.userDetail?.role }}
                        </small>
                      </div>
                    </div>
                    <p class="mb-1" [innerHTML]="comment?.comment"></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
