<div class="container-fluid py-4">
  <!-- Loading Spinner -->
  <ngx-spinner *ngIf="showLoader" type="ball-clip-rotate" size="medium" color="#5cb85c">
    <p style="color: white">Loading...</p>
  </ngx-spinner>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <div class="card-header bg-light">
          <div class="d-flex justify-content-between align-items-center">
            <h4>{{ modalTask?.task }}</h4>
            <button class="btn btn-sm btn-outline-secondary" (click)="goBack()">
              <i class="bi bi-arrow-left"></i> Back
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-lg-8 col-xl-8 col-12 mob-mb1">
              <div class="mb-3">
                <h6><strong>Description</strong></h6>
                <p  [innerHTML]="modalTask?.discription"></p>
                <p *ngIf="!modalTask?.discription">No Record Found.</p>
              </div>

              <div class="mb-3" *ngIf="modalTask?.project?.projectName">
                <h6><strong>Assigned Project</strong></h6>
                <a (click)="projectDetails(modalTask?.project?._id)" href="javascript:;">{{
                  modalTask?.project?.projectName }}</a>
              </div>
              <div class="mb-3" *ngIf="modalTask?.project">
                <h6><strong>Status</strong></h6>
                <p>{{ modalTask?.project?.status }}</p>
              </div>
              <div class="mb-3" *ngIf="modalTask?.project">
                <h6><strong>Bid Status</strong></h6>
                <p>{{ modalTask?.project?.bidManagerStatus }}</p>
              </div>

              <div class="comments-section">
                <h6><strong>Comment</strong></h6>
                <div class="mb-2">
                  <form [formGroup]="commentForm" (ngSubmit)="addComment(modalTask?._id)">
                    <div class="NgxEditor__Wrapper">
                      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" style="padding: 15px !important;">
                      </ngx-editor-menu>
                      <ngx-editor [editor]="editor" rows="5" formControlName="description" [disabled]="false"
                        [placeholder]="'Type here...'"></ngx-editor>
                    </div>
                    <button class="btn btn-sm btn-primary mt-2" type="submit" [disabled]="commentForm.invalid">
                      Comment
                    </button>
                  </form>
                </div>
                <div *ngFor="let comment of modalTask.comments; let i = index" class="mb-3">
                  <strong>{{ comment?.user }} </strong>
                  <small class="text-muted">{{ comment?.time }}</small>
                  <p class="mb-1">{{ comment?.text }}</p>
                </div>
              </div>

              <div class="comments-section">
                <!-- Pinned Comments Section -->
                <div *ngIf="modalTask?.datewiseComments?.pinnedComments?.length > 0" class="mb-4">
                  <h6 class="mt-3"><i class="bi bi-pin-fill text-primary"></i> <strong>Pinned Comments</strong></h6>
                  <ul class="SecondarytaskList">
                    <li *ngFor="let comment of modalTask.datewiseComments.pinnedComments"
                        class="comment-item p-3 pinned-comment"
                        [ngClass]="{ 'admin-comment': comment?.userDetail?.role === 'Admin' }">
                      <div class="row align-items-center mb-2">
                        <div class="col">
                          <div class="d-flex justify-content-between">
                            <p class="mb-0 user-name">{{ comment?.userDetail?.name }}</p>
                            <small class="comment-date" *ngIf="comment?.date">
                              {{ comment?.date | date : "dd/MM/yyyy, h:mm a" }}
                            </small>
                          </div>
                          <small class="user-role d-block">
                            {{ comment?.userDetail?.role === "BOS" ? "USER" : comment?.userDetail?.role }}
                          </small>
                        </div>
                      </div>
                      <p class="comment-text" [innerHTML]="comment?.comment"></p>
                      <div class="d-flex justify-content-end">
                        <button class="btn btn-sm pin-btn" (mousedown)="togglePinComment(comment, modalTask); $event.stopPropagation()">
                          <i class="bi bi-pin-fill text-primary"></i> Unpin
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>

                <h6 class="mt-3"><strong>All Comments</strong></h6>
                <div class="comments-scroll-container">
                  <ul class="SecondarytaskList mt-3">
                    <li *ngFor="let comment of transformData(modalTask?.datewiseComments); let i = index"
                      class="comment-item p-3 no-bg">

                      <h6 *ngIf="comment?.comment == 'No comments available for this date'">
                        {{ comment?.commentDate | date : "dd/MM/yyyy" }}
                      </h6>

                      <div class="row align-items-center mb-3">
                        <div class="col">
                          <div class="d-flex justify-content-between">
                            <p class="mb-0 user-name">{{ comment?.userDetail?.name }}</p>
                            <small class="comment-date" *ngIf="comment?.date">
                              {{ comment?.date | date : "dd/MM/yyyy, h:mm a" }}
                            </small>
                          </div>
                          <small class="user-role d-block">
                            {{
                            comment?.userDetail?.role === "BOS"
                            ? "USER"
                            : comment?.userDetail?.role
                            }}
                          </small>
                        </div>
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <div *ngIf="!comment.isEditing" class="d-flex justify-content-between align-items-center">

                          <p class="comment-text" [innerHTML]="comment?.comment"></p>

                          <div *ngIf="comment?.commentId" class="d-flex gap-2">
                            <button class="btn btn-sm pin-btn"
                              (mousedown)="togglePinComment(comment, modalTask); $event.stopPropagation()">
                              <i class="bi"
                                [ngClass]="{'bi-pin-fill': comment?.pinnedAt, 'bi-pin': !comment?.pinnedAt}"></i>
                            </button>
                            <button class="btn btn-sm delete-btn"
                              (mousedown)="deleteComment(comment?.commentId); $event.stopPropagation()">
                              <i class="bi bi-trash3"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-12">
              <div class="details-container border-left ps-3">
                <h5 class="details-header mb-3">Details</h5>

                <!-- Due Date Section -->
                <div class="detail-item mb-3">
                  <label for="dueDate" class="form-label"><strong>Due Date:</strong></label>
                  <div class="input-group">
                    <input id="dueDate" class="form-control" style="max-width: 330px" type="date"
                      [(ngModel)]="dueDateValue" (ngModelChange)="onChange('dueDate', $event)" />
                  </div>
                </div>

                <!-- Assignee and Priority in one row -->
                <div class="row g-2 mb-3">
                  <!-- Assignee Section -->
                  <div class="col-12 col-md-6">
                    <label for="assignTo" class="form-label"><strong>Assignee:</strong></label>
                    <ng-select id="assignTo" class="form-select custom-ng-select-option" placeholder="Select Assign User"
                      [multiple]="true" bindLabel="userName" bindValue="_id" [items]="userList" [(ngModel)]="assignTo"
                      (ngModelChange)="onChange('assignTo', $event)">
                    </ng-select>
                  </div>

                  <!-- Category Section -->
                  <div class="col-12 col-md-6">
                    <label for="category" class="form-label"><strong>Select Priority:</strong></label>
                    <ng-select id="category" class="form-select custom-ng-select-option" placeholder="Pick a Category"
                      [items]="categoryList" [(ngModel)]="selectedCategory"
                      (ngModelChange)="onChange('pickACategory', $event)">
                    </ng-select>
                  </div>
                </div>

                <!-- Task Status and Task Type in one row -->
                <div class="row g-2 mb-3">
                  <!-- task status Section -->
                  <div class="col-12 col-md-6">
                    <label for="taskstatus" class="form-label"><strong>Task Status:</strong></label>
                    <ng-select id="taskstatus" class="form-select custom-ng-select-option" placeholder="Pick a Status"
                      [items]="statusTaskList" [(ngModel)]="selectedStatus"
                      (ngModelChange)="onChange('taskStatus', $event)">
                    </ng-select>
                  </div>

                  <!-- task type Section -->
                  <div class="col-12 col-md-6">
                    <label for="type" class="form-label"><strong>Task Type:</strong></label>
                    <ng-select id="type" class="form-select custom-ng-select-option" placeholder="Pick a Type"
                      [items]="taskTypeList" [(ngModel)]="selectedTaskType" (ngModelChange)="onChange('type', $event)">
                    </ng-select>
                  </div>
                </div>

                <!-- Buttons in one row -->
                <div class="detail-item mb-3">
                  <div class="row g-2">
                    <div class="col-12 mb-2">
                      <button class="btn btn-primary w-100" [(ngModel)]="selectedStatus" (click)="onChangeMyday('MyDay')">
                        Add to My Day
                      </button>
                    </div>
                    <div class="col-12 mb-2">
                      <button class="btn btn-success w-100" (click)="onChange('completedTask', true)">
                        Completed
                      </button>
                    </div>
                    <div class="col-12 mb-2">
                      <button class="btn btn-secondary w-100" (click)="onChange('completedTask', false)">
                        Not Completed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>