import { Component } from '@angular/core';

@Component({
  selector: 'app-uk-writer',
  templateUrl: './uk-writer.component.html',
  styleUrls: ['./uk-writer.component.scss']
})
export class UkWriterComponent {

}
