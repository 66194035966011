import { Component } from '@angular/core';

@Component({
  selector: 'app-feasibility-login-details',
  templateUrl: './feasibility-login-details.component.html',
  styleUrls: ['./feasibility-login-details.component.scss']
})
export class FeasibilityLoginDetailsComponent {

}
