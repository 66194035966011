<div class="modal fade">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="ViewScreenshotLabel">FOI Screenshot</h5>
              <button type="button" class="btn-close"></button>
          </div>
          <div class="modal-body">
              <img src="assets/img/screenshot.png" class="img-fluid" />
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
          </div>
      </div>
  </div>
</div>
