<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<main id="main">
  <section>
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-12 col-xl-12 col-12 d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center">
            <h4 class="me-3"><strong>Project Title:</strong></h4>
            <h4>{{ projectDetails?.projectName }}</h4>
          </div>
          <div class="ms-auto d-flex">
            <a data-bs-toggle="modal" data-bs-target="#ViewStripAdd" class="btn btn-primary btn-sm text-dark me-2">
              Add Strips
            </a>
            <a class="btn btn-primary btn-sm text-dark me-2" style="cursor: pointer"
              (click)="editProjectDetails(projectDetails?._id)">
              Edit <i class="bi bi-pencil-fill"></i>
            </a>
            <a (click)="deleteProject(projectDetails?._id)" class="btn btn-danger btn-sm me-2">
              Delete <i class="bi bi-trash3-fill"></i>
            </a>
            <a class="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#ViewProjectLogs">
              Logs
            </a>
            <a (click)="goBack()" class="btn btn-primary btn-sm text-dark me-2">
              Back
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-xl-12 col-12 mb-12 mt-5 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2"><strong>BOS ID:</strong></div>
              <div class="col-md-10">{{ projectDetails?.BOSID || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Publish Date:</strong></div>
              <div class="col-md-10">
                {{
                projectDetails?.publishDate
                ? (projectDetails?.publishDate | date : "dd/MM/yyyy")
                : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Category:</strong></div>
              <div class="col-md-10">{{ projectDetails?.category || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Industry:</strong></div>
              <div class="col-md-10">{{ projectDetails?.industry || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Min value (GBP):</strong></div>
              <div class="col-md-10">
                GBP {{ projectDetails?.minValue || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Max value (GBP):</strong></div>
              <div class="col-md-10">
                GBP {{ projectDetails?.maxValue || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Website:</strong></div>
              <div class="col-md-10">{{ projectDetails?.website || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Link to notice:</strong></div>
              <div class="col-md-10">
                <a *ngIf="projectDetails?.link; else noLink" href="{{ projectDetails?.link }}" target="_blank">Link to
                  Notice</a>
                <ng-template #noLink>-</ng-template>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Created Date:</strong></div>
              <div class="col-md-10">
                {{
                projectDetails?.createdAt
                ? (projectDetails?.createdAt | date : "dd/MM/yyyy")
                : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Due Date & Time:</strong></div>
              <div class="col-md-10">
                {{
                projectDetails?.dueDate
                ? (projectDetails?.dueDate | date : "dd/MM/yyyy, h:mm a")
                : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>CPV Codes:</strong></div>
              <div class="col-md-10">{{ projectDetails?.CPVCodes || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Notice Reference:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.noticeReference || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Status:</strong></div>
              <div class="col-md-10">{{ projectDetails?.status || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Project Type:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.projectType || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Mail Id:</strong></div>
              <div class="col-md-10">{{ projectDetails?.mailID || "-" }}</div>
            </div>

            <div class="row">
              <div class="col-md-2"><strong>Client Type:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.clientType || "-" }}
              </div>
            </div>

            <div class="row">
              <div class="col-md-2"><strong>Client Name:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.clientName || "-" }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Categorisation:</strong></div>
              <div class="col-md-10">
                {{ projectDetails?.categorisation || "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="pageheader bg-dark">
        <div class="container d-flex justify-content-between align-items-center">
          <p class="mb-0"><strong>Project Description</strong></p>
        </div>
      </section>
      <br />

      <p>{{ projectDetails?.description }}</p>

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Login Details</strong></p>
        </div>
      </section>

      <div class="col-lg-12 col-xl-12 col-12 mb-12 mt-5 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2"><strong>Login ID:</strong></div>
              <div class="col-md-10">{{ projectDetails?.loginID || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Password:</strong></div>
              <div class="col-md-10">{{ projectDetails?.password || "-" }}</div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Link to portal:</strong></div>
              <div class="col-md-10">
                <a [href]="projectDetails?.linkToPortal" target="_blank" rel="noopener noreferrer">
                  {{ projectDetails?.linkToPortal }}
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Documents link:</strong></div>
              <div class="col-md-10">
                <a [href]="projectDetails?.documentsLink" target="_blank" rel="noopener noreferrer">
                  {{ projectDetails?.documentsLink }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="pageheader bg-dark">
        <div class="container">
          <p><strong>Documents</strong></p>
        </div>
      </section>
      <br />

      <div class="row mb-4">
        <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
          <h5>Client Documents</h5>
          <div class="card">
            <ul class="project-document">
              <li *ngFor="let item of projectDetails?.clientDocument">
                <div class="row">
                  <div class="col-5 text-center">
                    <p class="mb-0">
                      {{ item?.name }}
                    </p>
                  </div>
                  <div class="col-7 text-center">
                    <a href="javascript:void(0);" class="btn btn-primary btn-sm me-2" data-bs-toggle="modal"
                      data-bs-target="#ViewClientDoc" (click)="openDocument(item)">
                      <i class="bi bi-eye-fill"></i> View
                    </a>

                    <a href="javascript:void(0);" (click)="download(item?.file?.url, item?.name)"
                      class="btn btn-primary btn-sm me-2">
                      <i class="bi bi-file-earmark-arrow-down-fill"></i> Download
                    </a>

                    <a href="javascript:void(0);" (click)="deleteDocuments(item?.name, 'clientDocument')"
                      class="btn btn-danger btn-sm">
                      <i class="bi bi-trash-fill"></i> Delete
                    </a>

                  </div>

                </div>
              </li>
            </ul>
            <button data-bs-toggle="modal" data-bs-target="#addClientDocument" type="button"
              class="btn btn-dark btn-sm add-card-btn">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>

        <div class="col-lg-6 col-xl-6 col-12 mob-mb1 mb-3">
          <h5>WestGate Documents</h5>
          <div class="card">
            <ul class="project-document">
              <!-- Document List -->
              <li *ngFor="let item of projectDetails?.westGetDocument" class="list-group-item">
                <div class="row">
                  <div class="col-5 text-center">
                    <p class="mb-0">{{ item?.name }}</p>
                  </div>
                  <div class="col-7 text-center">
                    <a href="href:;" class="btn btn-primarysm me-2" data-bs-toggle="modal"
                      data-bs-target="#ViewClientDoc" (click)="openDocument(item)">
                      <i class="bi bi-eye-fill"></i> View
                    </a>
                    <a href="javascript:void(0);" (click)="download(item?.file?.url, item?.name)"
                      class="btn btn-primary btn-sm me-2">
                      <i class="bi bi-file-earmark-arrow-down-fill"></i> Download
                    </a>
                    <a href="javascript:void(0);" (click)="deleteDocuments(item?.name, 'westgateDocument')"
                      class="btn btn-danger btn-sm ">
                      <i class="bi bi-trash-fill"></i> Delete
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <button data-bs-toggle="modal" data-bs-target="#addWestgateDocument" type="button"
              class="btn btn-dark btn-sm add-card-btn">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>

        <ng-container>
          <div class="row justify-content-end mb-3">
            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
              <a (click)="summaryDetail('save')" class="btn btn-dark w-100">Save Documents</a>
            </div>
          </div>
        </ng-container>
      </div>

      <section class="pageheader bg-dark mb-3">
        <div class="container d-flex justify-content-between align-items-center">
          <p class="mb-0">
            <strong>Assign Bid User:
              {{projectDetails?.assignBidmanager?.[0]?.name}}</strong>
          </p>
          <p class="mb-0">
            <strong>Due Date:
              {{
              (projectDetails?.assignBidmanager?.[0]?.dueDate | date :
              "dd/MM/yyyy")
              }}</strong>
          </p>
        </div>
      </section>


      <div class="row align-items-end mb-4">
        <div class="col-lg-5 col-md-5 col-sm-12">
          <form autocomplete="off">
            <div class="detail-item">
              <label>Assign To</label>
              <ng-select style="padding: 0px !important" placeholder="Select Assign User"
                class="form-select custom-ng-select-option" bindLabel="userName" bindValue="_id" [items]="BiduserList"
                [(ngModel)]="assignTo" name="assignTo">
              </ng-select>
            </div>
          </form>
        </div>

        <div class="col-lg-5 col-md-5 col-sm-12">
          <div class="detail-item">
            <label>Due Date</label>
            <div class="input-group">
              <input class="form-control" type="date" [(ngModel)]="dueDate" name="dueDate" />
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-12 text-end">
          <button type="button" class="btn btn-primary w-100" (click)="assignUser()">
            Submit
          </button>
        </div>
      </div>



      <div class="row align-items-end mb-4">
        <div class="col-lg-12 col-xl-12 col-12 mb-2">
          <div class="card shadow-sm p-4">
            <!-- Header -->
            <div class="card-header bg-primary text-white mb-3">
              <h5 class="mb-0">Feasibility Status</h5>
            </div>

            <!-- Status Buttons -->
            <div class="d-flex justify-content-between flex-wrap mb-4">
              <button class="btn btn-outline-primary flex-fill m-1" [class.active]="feasibilityStatus === 'InProgress'"
                (click)="statusFeasibilityChange('InProgress')">
                In-Progress
              </button>
              <button class="btn btn-outline-secondary flex-fill m-1" [class.active]="feasibilityStatus === 'InHold'"
                (click)="statusFeasibilityChange('InHold')">
                In-Hold
              </button>
              <button class="btn btn-outline-success flex-fill m-1" [class.active]="feasibilityStatus === 'Passed'"
                (click)="statusFeasibilityChange('Passed')">
                Pass
              </button>
              <button class="btn btn-outline-danger flex-fill m-1" [class.active]="feasibilityStatus === 'Fail'"
                (click)="statusFeasibilityChange('Fail')">
                Fail
              </button>
              <button class="btn btn-outline-warning flex-fill m-1"
                [class.active]="feasibilityStatus === 'DocumentsNotFound'"
                (click)="statusFeasibilityChange('DocumentsNotFound')">
                Document Not Found
              </button>
              <button class="btn btn-outline-dark flex-fill m-1" [class.active]="feasibilityStatus === 'Not Releted'"
                (click)="statusFeasibilityChange('Not Releted')">
                Not Releted
              </button>
            </div>

            <!-- Dynamic Comment Section -->
            <div *ngIf="feasibilityStatus" class="status-section">
              <h6 class="text-secondary mb-3">
                {{ feasibilityStatus }} Comments
              </h6>

              <!-- Display Existing Comments -->
              <div *ngIf="feasibilityCommentData?.length" class="comment-list comments-section">
                <div class="comment-item border rounded p-3 mb-2" *ngFor="let item of feasibilityCommentData">
                  <div class="comment mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 fw-bold">
                        {{ item?.userDetails?.name }}
                      </p>
                      <small class="text-muted">
                        {{ item?.date | date : "dd/MM/yyyy, h:mm a" }}
                      </small>
                    </div>
                    <small class="text-muted d-block"><strong>Status :</strong> {{ item?.status }}</small>
                    <div class="d-flex justify-content-between">
                      <p class="mt-2" [innerHTML]="item?.comment"></p>
                      <button class="btn btn-sm text-danger" (click)="deleteComment(item, item?.commentId)">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="comment-list comments-section">
                <div class="comment-item border rounded p-3 mb-2" *ngFor="let reason of getReasonList">
                  <div class="comment mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 fw-bold">
                        {{ reason?.userDetails?.name }}
                      </p>
                      <small class="text-muted">
                        {{ reason?.date | date : "dd/MM/yyyy, h:mm a" }}
                      </small>
                    </div>
                    <small class="text-muted d-block"><strong>Reason :</strong> {{ reason?.comment }}</small>
                    <div class="d-flex justify-content-between">
                      <p class="mt-2">{{ reason?.tag }}</p>
                      <button class="btn btn-sm text-danger" (click)="deleteFailedReasons(reason, reason?.commentId)">
                        Delete Reason
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add New Comment -->
              <ng-container>
                <div class="add-comment d-flex gap-3 align-items-center mt-3 mb-2" *ngIf="feasibilityStatus !== 'Fail'">
                  <div class="NgxEditor__Wrapper flex-grow-1">
                    <ngx-editor-menu [editor]="feasibilityEditor" [toolbar]="toolbar">
                    </ngx-editor-menu>
                    <ngx-editor
                      [editor]="feasibilityEditor"
                      [formControl]="feasibilityStatusComment"
                      [placeholder]="'Type here...'"
                      class="ngx-editor-textarea">
                    </ngx-editor>
                  </div>
                  <button class="btn btn-primary" type="button" (click)="pushFeasibilityStatus()">
                    <i class="bi bi-plus-lg"></i> Add
                  </button>
                </div>
              </ng-container>

              <!-- Fail Specific Fields -->
              <ng-container>
                <div *ngIf="feasibilityStatus === 'Fail'" class="mt-4">
                  <div class="d-flex align-items-center gap-3">
                    <select class="form-select" [(ngModel)]="selectedFailReason">
                      <option disabled selected hidden value="">
                        Select Fail Reason
                      </option>
                      <option value="Accreditation">Accreditation</option>
                      <option value="Time Constraints">Time Constraints</option>
                      <option value="Certification">Certification</option>
                      <option value="Case Study">Case Study</option>
                      <option value="Pre-Market">Pre-Market</option>
                      <option value="Certifications from Subcontractor">
                        Certifications from Subcontractor
                      </option>
                      <option value="Delivery of goods/Hardware">
                        Delivery of goods/Hardware
                      </option>
                      <option value="Financial Conditions">
                        Financial Conditions
                      </option>
                      <option value="Not related">Not related</option>
                      <option value="No Subcontracting">
                        No Subcontracting
                      </option>
                      <option value="No Supplier Match">
                        No Supplier Match
                      </option>
                      <option value="Other">Other</option>
                    </select>
                    <button class="btn btn-outline-primary" type="button" (click)="addFailReason()">
                      <i class="bi bi-plus-lg">Feasibility</i>
                    </button>
                  </div>

                  <!-- Display added reasons and comments -->
                  <div class="mt-3">
                    <div class="reason-item border rounded p-3 mb-2"
                      *ngFor="let reason of failStatusReasons; let i = index">
                      <div class="d-flex align-items-center">
                        <span class="me-3"><strong>Reason:</strong> {{ reason?.tag }}</span>
                        <input type="text" class="form-control me-3" style="flex: 1" placeholder="Add your comment"
                          [(ngModel)]="reason.comment" />
                        <button class="btn btn-sm btn-danger" type="button" (click)="removeFailReason(i)">
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div class="row justify-content-end mb-4">
                  <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
                    <a (click)="saveFeasibilityStatus('save')" [class.disabled]="
                        !isCommentValid() && feasibilityStatus !== 'Fail'
                      " class="btn btn-primary w-100">Save Status</a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-xl-12 col-12" *ngIf="projectDetails?.status == 'Passed'">
          <div class="card shadow-sm p-4">
            <!-- Header -->
            <div class="card-header bg-primary text-white mb-3">
              <h5 class="mb-0">Bid Status</h5>
            </div>

            <!-- Status Buttons -->
            <div class="d-flex justify-content-between flex-wrap mb-4">
              <button class="btn btn-outline-info flex-fill m-1" [class.active]="status === 'Awaiting'"
                (click)="statusChange('Awaiting')">
                Awaiting
              </button>
              <button class="btn btn-outline-info flex-fill m-1" [class.active]="status === 'InSolution'"
                (click)="statusChange('InSolution')">
                In-Solution
              </button>
              <button class="btn btn-outline-secondary flex-fill m-1" [class.active]="status === 'WaitingForResult'"
                (click)="statusChange('WaitingForResult')">
                Waiting For Result
              </button>
              <button class="btn btn-outline-success flex-fill m-1"
                [class.active]="status === 'Dropped after feasibility'"
                (click)="statusChange('Dropped after feasibility')">
                Dropped After Feasibility
              </button>
              <button class="btn btn-outline-danger flex-fill m-1" [class.active]="status === 'Awarded'"
                (click)="statusChange('Awarded')">
                Awarded
              </button>
              <button class="btn btn-outline-warning flex-fill m-1" [class.active]="status === 'NotAwarded'"
                (click)="statusChange('NotAwarded')">
                Not Awarded
              </button>
              <button class="btn btn-outline-dark flex-fill m-1" [class.active]="status === 'Nosuppliermatched'"
                (click)="statusChange('Nosuppliermatched')">
                No Supplier Matched
              </button>
              <button class="btn btn-outline-secondary flex-fill m-1" [class.active]="status === 'Go-NoGoStage1'"
                (click)="statusChange('Go-NoGoStage1')">
                GO-NO GO STAGE 1
              </button>
              <button class="btn btn-outline-danger flex-fill m-1" [class.active]="status === 'SupplierConfirmation'"
                (click)="statusChange('SupplierConfirmation')">
                SUPPLIER CONFIRMATION
              </button>
              <button class="btn btn-outline-success flex-fill m-1" [class.active]="status === 'Go-NoGoStage2'"
                (click)="statusChange('Go-NoGoStage2')">
                GO-NO GO STAGE 2
              </button>
            </div>

            <!-- Dynamic Comment Section -->
            <div *ngIf="status" class="status-section">
              <h6 class="text-secondary mb-3">{{ status }} Comments</h6>

              <!-- Display Existing Comments -->
              <div *ngIf="commentData?.length" class="comment-list comments-section">
                <div class="comment-item border rounded p-3 mb-2" *ngFor="let item of commentData">
                  <div class="comment mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 fw-bold">
                        {{ item?.userDetails?.name }}
                      </p>
                      <small class="text-muted">
                        {{ item?.date | date : "dd/MM/yyyy, h:mm a" }}
                      </small>
                    </div>
                    <small class="text-muted d-block"><strong>Status :</strong>
                      {{ item?.bidManagerStatus }}</small>
                    <div class="d-flex justify-content-between">
                      <p class="mt-2"  [innerHTML]="item?.comment"></p>
                      <button class="btn btn-sm text-danger" (click)="deleteBidComment(item, item?.commentId)">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="comment-list comments-section">
                <div class="comment-item border rounded p-3 mb-2"
                  *ngFor="let droppedreason of getDroppedAfterReasonList">
                  <div class="comment mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 fw-bold">
                        {{ droppedreason?.userDetails?.name }}
                      </p>
                      <small class="text-muted">
                        {{ droppedreason?.date | date : "dd/MM/yyyy, h:mm a" }}
                      </small>
                    </div>
                    <small class="text-muted d-block"><strong>Reason :</strong>
                      {{ droppedreason?.comment }}</small>
                    <div class="d-flex justify-content-between">
                      <p class="mt-2">{{ droppedreason?.tag }}</p>
                      <button class="btn btn-sm text-danger" (click)="
                          deleteDroppedReasons(
                            droppedreason,
                            droppedreason?.commentId
                          )
                        ">
                        Delete Reason
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add New Comment -->
              <ng-container>
                <div class="add-comment d-flex gap-3 align-items-center mt-3 mb-2" *ngIf="status !== 'Dropped after feasibility'">
                  <div class="NgxEditor__Wrapper flex-grow-1">
                    <ngx-editor-menu [editor]="bidStatusEditor" [toolbar]="toolbar">
                    </ngx-editor-menu>
                    <ngx-editor
                      [editor]="bidStatusEditor"
                      [formControl]="bidManagerStatusComment"
                      [placeholder]="'Type here...'"
                      class="ngx-editor-textarea">
                    </ngx-editor>
                  </div>
                  <button class="btn btn-primary" type="button" (click)="pushStatus()">
                    <i class="bi bi-plus-lg"></i> Add
                  </button>
                </div>
              </ng-container>

              <!-- Dropper After Feasibility Specific Fields -->
              <ng-container>
                <div *ngIf="status === 'Dropped after feasibility'" class="mt-4">
                  <div class="d-flex align-items-center gap-3">
                    <select class="form-select" [(ngModel)]="selectedDroppedAfterFeasibilityReason">
                      <option disabled selected hidden value="">
                        Select Dropper After Feasibility
                      </option>
                      <option value="Subcontracting No">
                        Subcontracting No
                      </option>
                      <option value="Supplier Dropped">Supplier Dropped</option>
                      <option value="Time Constrain">Time Constrain</option>
                      <option value="Rejected In Yes/No Call">
                        Rejected In Yes/No Call
                      </option>
                      <option value="Supplier Did Not Meet Eligibility Criteria">
                        Supplier Did Not Meet Eligibility Criteria
                      </option>
                      <option value="Other">Other</option>
                    </select>
                    <button class="btn btn-outline-primary" type="button" (click)="adddroppedReason()">
                      <i class="bi bi-plus-lg">Dropped</i>
                    </button>
                  </div>

                  <!-- Display added bid reasons and comments -->
                  <div class="mt-3">
                    <div class="reason-item border rounded p-3 mb-2"
                      *ngFor="let reason of droppedStatusReasons; let i = index">
                      <div class="d-flex align-items-center">
                        <span class="me-3"><strong>Reason:</strong> {{ reason?.tag }}</span>
                        <input type="text" class="form-control me-3" style="flex: 1" placeholder="Add your comment"
                          [(ngModel)]="reason.comment" />
                        <button class="btn btn-sm btn-danger" type="button" (click)="removeDroppedReason(i)">
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div class="row justify-content-end mb-4">
                  <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
                    <a (click)="saveBidStatus('save')" [class.disabled]="
                        !isBidCommentValid() &&
                        status !== 'Dropped after feasibility'
                      " class="btn btn-primary w-100">
                      Save Bid Status
                    </a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <br />
      </div>
    </div>
  </section>

  <section *ngFor="let item of projectStrips">
    <div class="container-fluid">
      <div class="bg-dark py-3">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="container d-flex justify-content-between align-items-center">
              <p class="mb-1 text-white fw-bold">{{ item?.text }}</p>
            </div>
            <br />
            <div>
              <button class="btn btn-danger btn-sm" (click)="deleteStrips(item?._id)">
                Delete Strip
              </button>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div class="bg-white py-3" *ngIf="item?.type != 'Image'">
        <p class="text-secondary mb-0 fw-bold"></p>
        <p class="text-dark mb-0" [innerHTML]="item?.description"></p>
      </div>

      <div class="row align-items-center">
        <div class="col-lg-12 col-xl-12 col-12">
          <div class="d-flex flex-row flex-wrap">
            <div class="col-lg-auto col-xl-auto col-md-auto col-sm-auto col-auto me-3"
              *ngFor="let image of item?.images">
              <div class="form-group">
                <label class="display-block">{{ image?.imageText }}</label>
                <br />
                <div class="upload-btn-wrapper">
                  <div class="d-flex justify-content-between">
                    <a *ngIf="subContractDocument" (click)="openViewImage(image)" class="btn btn-small me-2"
                      data-bs-toggle="modal" data-bs-target="#viewstripimage">
                      <i class="bi bi-eye-fill"></i> View
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" [id]="'imageModal_' + item._id" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add Image Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <!-- Dynamic Input Fields -->
              <div *ngFor="let field of imageFields; let i = index" class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Enter Text" [(ngModel)]="field.text" />
                <input type="file" class="form-control" accept="image/*" (change)="onFileChange($event, i)" />
                <button type="button" class="btn btn-danger" (click)="removeField(i)"
                  [disabled]="imageFields.length === 1">
                  Delete
                </button>
              </div>
              <button type="button" class="btn btn-secondary btn-sm mt-3" (click)="addField()">
                Add More
              </button>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="saveFields(item._id)" data-bs-dismiss="modal">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<div class="modal fade" id="ViewProjectLogs" tabindex="-1" aria-labelledby="ViewProjectLogsLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewProjectLogsLabel">Project Logs</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-flex">
        <div class="flex-grow-1 pe-3">
          <div class="comments-section">
            <ul class="SecondarytaskList mt-3">
              <li *ngFor="let log of projectDetails?.logs" class="mb-3">
                <div class="comment-item">
                  <div class="d-flex justify-content-between">
                    <p class="mb-0" [innerHTML]="log?.log"></p>
                    <span class="text-muted">
                      {{ log?.date | date : "dd/MM/yyyy, h:mm a" }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- No records found -->
            <p *ngIf="logs?.length === 0" class="text-center">
              No records found
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="addClientDocument" tabindex="-1" aria-labelledby="addClientDocumentLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewScreenshotLabel">
          Add Client Document
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="viewClientDocumentForm">
          <div class="card p-3 mt-3">
            <div class="row g-3 align-items-center">
              <div class="col-md-8 col-sm-12">
                <input type="text" [(ngModel)]="documentName" class="form-control" placeholder="Enter Document Name"
                  aria-label="Document Name" />
              </div>
              <div class="col-md-4 col-sm-12">
                <label class="btn btn-outline-primary w-100">
                  <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                  <input type="file" id="uploadClientDocument" (change)="
                      uploadDocument($event, documentUploadType.clientDocument)
                    " hidden aria-label="Upload Document" />
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addWestgateDocument" tabindex="-1" aria-labelledby="addWestgateDocumentLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewScreenshotLabel">
          Add Westgate Document
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="viewClientDocumentForm">
          <div class="card p-3 mt-3">
            <div class="row g-3 align-items-center">
              <div class="col-md-8 col-sm-12">
                <input type="text" [(ngModel)]="documentName" [ngModelOptions]="{ standalone: true }"
                  class="form-control" placeholder="Enter Document Name" aria-label="Document Name" />
              </div>
              <div class="col-md-4 col-sm-12">
                <label class="btn btn-outline-primary w-100">
                  <i class="bi bi-file-earmark-arrow-up-fill"></i> Upload
                  <input type="file" id="uploadClientDocument"
                    (change)="uploadDocument($event, documentUploadType.westGetDocument)" hidden
                    aria-label="Upload Document" />
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<!-- Login detail From modal -->
<div class="modal fade" id="loginDetailModal" tabindex="-1" aria-labelledby="ViewLogin" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" [formGroup]="loginDetailForm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewLogin">Add Login Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label>Company Name</label>
        <input type="text" formControlName="companyName" class="form-control" />
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['companyName'].touched && loginDetailForm.controls['companyName']?.errors?.['required']">
          Please Company Name
        </div>
        <label>Website Link</label>
        <input type="text" formControlName="link" class="form-control" />
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['link'].touched && loginDetailForm.controls['link']?.errors?.['required']">
          Please enter Website Link
        </div>
        <label>Login Id</label>
        <input type="text" formControlName="loginID" class="form-control" />
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['loginID'].touched && loginDetailForm.controls['loginID']?.errors?.['required']">
          Please enter Login Id
        </div>
        <label>Password</label>
        <input [type]="password" formControlName="password" class="form-control" />
        <span class="showicon" *ngIf="!showPassword" (click)="showHidePass()"><i class="bi bi-eye-fill"></i></span>
        <span class="showicon" *ngIf="showPassword" (click)="showHidePass()"><i class="bi bi-eye-slash-fill"></i></span>
        <div class="text-danger" style="color: red"
          *ngIf="loginDetailForm.controls['password'].touched && loginDetailForm.controls['password']?.errors?.['required']">
          Please enter Password
        </div>
        <div class="row justify-content-end">
          <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-12 text-end">
            <button (click)="addtitle()" data-bs-dismiss="modal" aria-label="Close" [disabled]="!loginDetailForm.valid"
              class="btn btn-dark w-100">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ViewUploadedDocument" tabindex="-1" aria-labelledby="ViewUploadedDocumentModal"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="display: flex; justify-content: space-between">
        <h5 class="modal-title" id="ViewUploadedDocumentModal">Document</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isPdf(uploadedDocument?.url); else otherFormatsForDocument">
          <ngx-extended-pdf-viewer [src]="uploadedDocument?.url" useBrowserLocale="true"
            height="600px"></ngx-extended-pdf-viewer>
        </ng-container>
        <ng-template #otherFormatsForDocument>
          <iframe *ngIf="isWordOrExcel(uploadedDocument?.url)" [src]="getDocumentViewerUrl(uploadedDocument?.url)"
            style="width: 100%; height: 600px"></iframe>
          <img *ngIf="isImage(uploadedDocument?.url)" [src]="uploadedDocument?.url" class="img-fluid" />
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ViewClientDoc" tabindex="-1" aria-labelledby="ViewClientDocLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewClientDocLabel">
          Client Document : {{ selectedDocument?.name }}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="alert alert-danger" role="alert">
        ⚠ Important Notice : If you are unable to view any documents, please
        note that the files may have been uploaded as a ZIP file. Kindly
        download the ZIP file to access the documents.
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <div class="modal-body">
            <ng-container *ngIf="isPdf(selectedDocument?.file?.url); else otherFormats">
              <ngx-extended-pdf-viewer [src]="selectedDocument?.file?.url" useBrowserLocale="true"
                height="600px"></ngx-extended-pdf-viewer>
            </ng-container>
            <ng-template #otherFormats>
              <iframe *ngIf="isWordOrExcel(selectedDocument?.file?.url)"
                [src]="getDocumentViewerUrl(selectedDocument?.file?.url)" style="width: 100%; height: 600px"></iframe>
              <img *ngIf="isImage(selectedDocument?.file?.url)" [src]="selectedDocument?.file?.url" class="img-fluid" />
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- View Document Modal -->
  <div class="modal fade" id="ViewCaseModal" tabindex="-1" aria-labelledby="ViewDocumentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="display: flex; justify-content: space-between">
          <h5 class="modal-title" id="ViewDocumentModalLabel">
            Case Study ({{ selectViewImage?.imageText }})
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="isPdf(selectViewImage?.url); else otherFormats">
            <ngx-extended-pdf-viewer [src]="selectViewImage?.url" useBrowserLocale="true"
              height="600px"></ngx-extended-pdf-viewer>
          </ng-container>
          <ng-template #otherFormats>
            <iframe *ngIf="isWordOrExcel(selectViewImage?.url)" [src]="getDocumentViewerUrl(selectViewImage?.url)"
              style="width: 100%; height: 600px"></iframe>
            <img *ngIf="isImage(selectViewImage?.key)" [src]="selectViewImage?.url" class="img-fluid" />
          </ng-template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- View StripAdd Modal -->
  <div class="modal fade" id="ViewStripAdd" tabindex="-1" aria-labelledby="ViewStripAddLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" [formGroup]="addStripForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ViewLogin">Add Strip Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <!-- Radio Buttons to Select Upload Type -->
          <div class="form-group d-flex align-items-center mb-4">
            <label class="me-3">Select Type:</label>
            <div class="form-check me-3">
              <input type="radio" id="textOption" class="form-check-input" formControlName="type" value="Text"
                (change)="selectUploadType(true)" />
              <label for="textOption" class="form-check-label">Text</label>
            </div>
            <div class="form-check">
              <input type="radio" id="imageOption" class="form-check-input" formControlName="type" value="Image"
                (change)="selectUploadType(false)" />
              <label for="imageOption" class="form-check-label">Image</label>
            </div>
          </div>

          <!-- Conditional Rendering Based on Upload Type -->
          <div *ngIf="uploadType; else imageUploadSection">
            <!-- Text Upload Section -->
            <div class="form-group mb-3">
              <label for="textInput" class="form-label">Strip Heading :</label>
              <input type="text" id="textInput" class="form-control" placeholder="Enter Text" formControlName="text" />
              <div *ngIf="
                addStripcontrol.text.touched && addStripcontrol.text.invalid
              " class="text-danger">
                Text is required.
              </div>
            </div>

            <div class="form-group mb-3">
              <label for="textInput" class="form-label">Strip Description :</label>
              <textarea type="text" id="textInput" class="form-control" placeholder="Enter Description"
                formControlName="description"></textarea>
              <div *ngIf="
                addStripcontrol.description.touched &&
                addStripcontrol.description.invalid
              " class="text-danger">
                Description is required.
              </div>
            </div>

            <div class="form-group mb-3">
              <ng-select id="assignTo" class="form-select custom-ng-select-option" placeholder="Select Role"
                [multiple]="true" bindLabel="name" [items]="ForTitleuserList" formControlName="roles">
              </ng-select>
            </div>
          </div>

          <!-- Image Upload Section -->
          <ng-template #imageUploadSection>
            <div class="form-group mb-3">
              <label for="imageTextInput" class="form-label">Strip Heading :</label>
              <input type="text" id="imageTextInput" class="form-control" placeholder="Enter Text"
                formControlName="imageText" />
              <div *ngIf="
                addStripcontrol.imageText.touched &&
                addStripcontrol.imageText.invalid
              " class="text-danger">
                Image Text is required.
              </div>
            </div>

            <div class="form-group mb-3">
              <ng-select id="assignTo" class="form-select custom-ng-select-option" placeholder="Select Role"
                [multiple]="true" bindLabel="name" [items]="ForTitleuserList" formControlName="roles">
              </ng-select>
            </div>
          </ng-template>

          <div class="row justify-content-end mt-4">
            <div class="col-auto">
              <button (click)="addtitle()" data-bs-dismiss="modal" aria-label="Close" class="btn btn-dark">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- View Strip image Modal -->
  <div class="modal fade" id="viewstripimage" tabindex="-1" aria-labelledby="viewstripimageLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="display: flex; justify-content: space-between">
          <h5 class="modal-title" id="ViewDocumentModalLabel">
            Strip Image ({{ selectViewImage?.imageText }})
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="selectViewImage">
            <ng-container *ngIf="isPdf(selectViewImage?.url); else otherFormats">
              <ngx-extended-pdf-viewer [src]="selectViewImage?.url" useBrowserLocale="true"
                height="600px"></ngx-extended-pdf-viewer>
            </ng-container>
            <ng-template #otherFormats>
              <iframe *ngIf="isWordOrExcel(selectViewImage?.url)" [src]="getDocumentViewerUrl(selectViewImage?.url)"
                style="width: 100%; height: 600px"></iframe>
              <img *ngIf="isImage(selectViewImage?.url)" [src]="selectViewImage?.url" class="img-fluid" />
            </ng-template>
          </ng-container>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>