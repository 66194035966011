<div class="body-login">
    <img src="assets/img/westgate.svg" width="250" height="100" class="float-start" />
    <div class="container d-flex justify-content-center align-items-center" style="height:100vh; overflow:hidden;">
        <div class="row d-flex align-items-center justify-content-center"
            style="overflow:hidden; width:100vw; height:100vh;">
            <div class="col-lg-4 col-xl-4 col-12">
                <div class="login-modal">
                    <div class="login-body">
                        <h4 class="mb-4"><strong>Hello!</strong></h4>
                        <form class="needs-validation" [formGroup]="loginForm" (ngSubmit)="login()">
                            <div class="form-group">
                                <label>Role</label>
                                <!-- <input type="text" formControlName="role" required class="form-control"
                                    placeholder="Enter role"> -->
                                <select formControlName="role" class="form-select">
                                    <option disabled selected hidden value="">Select User Role</option>
                                    <option value="BOS">User</option>
                                    <!-- <option value="FeasibilityUser">Feasibility User</option> -->
                                    <option value="ProjectManager">Bid Manager</option>
                                    <!-- <option value="SupplierAdmin">Supplier Admin</option> -->
                                    <!-- <option value="UKWriter">UK Writer</option>
                                        <option value="BIDSubmition">Bid Submission</option>
                                        <option value="ProjectCoOrdinator">Project Co-Ordinator</option> -->
                                    <option value="ProcessManagerAdmin">Process Manager</option>
                                    <option value="Admin">Super Admin</option>
                                    <!-- <option value="FeasibilityAdmin">Feasibility Manager</option> -->
                                </select>
                                <span class="text-danger" *ngIf="
                                loginForm.controls.role?.touched &&
                                loginForm.controls.role?.errors?.['required']">
                                    Please enter role
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Email id</label>
                                <input type="email" formControlName="email" required class="form-control"
                                    placeholder="Enter email id">
                                <span class="text-danger" *ngIf="
                                loginForm.controls.email?.touched &&
                                loginForm.controls.email?.errors?.['required']">
                                    Please enter email
                                </span>
                                <span class="text-danger" *ngIf="
                                loginForm.controls.email?.touched &&
                                loginForm.controls.email?.errors?.['pattern']">
                                    Please enter valid email
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input [type]="password" class="form-control" placeholder="Enter password"
                                    formControlName="password" required>
                                <span class="showicon" *ngIf="!showPassword" (click)="showHidePass()"><i
                                        class="bi bi-eye-fill"></i></span>
                                <span class="showicon" *ngIf="showPassword" (click)="showHidePass()"><i
                                        class="bi bi-eye-slash-fill"></i></span>
                                <span class="text-danger" *ngIf="
                                loginForm.controls.password?.touched &&
                                loginForm.controls.password?.errors?.['required']
                            ">
                                    Please enter valid password.
                                </span>
                                <!-- <span class="text-danger" *ngIf="
                                loginForm.controls.password?.touched &&
                                loginForm.controls.password?.errors?.['pattern']
                            ">
                                    Please enter valid password.
                                </span> -->
                            </div>
                            <p><a href="javascript:void(0)" routerLink="/forgot-password">Forgot Password?</a></p>
                            <button type="submit" class="btn btn-login w-100 custom-border">Login</button>
                        </form>
                    </div>
                </div>
            </div>

        </div> <!-- Inner row -->
    </div> <!-- Outer container -->
</div>