<body class="body-login">

    <div class="container d-flex justify-content-center align-items-center" style="height:100vh; overflow:hidden;">
        <div class="row d-flex align-items-center justify-content-center"
            style="overflow:hidden; width:100vw; height:100vh;">

            <div class="col-lg-4 col-xl-4 col-12 text-center">
                <img src="assets/img/westgate.svg" width="250" />
            </div>
            <div class="col-lg-4 col-xl-4 col-12">
                <div class="login-modal">
                    <div class="login-body">
                        <h4 class="mb-4"><strong>Reset Password</strong></h4>
                        <form class="needs-validation" [formGroup]="resetForm" (ngSubmit)="resetPassword()">
                            <div class="form-group">
                                <label>New Password</label>
                                <input type="password" class="form-control" placeholder="Enter new password"
                                    [type]="password" formControlName="password" required>
                                <span class="showicon" *ngIf="!showPassword" (click)="showHidePass()"><i
                                        class="bi bi-eye-fill"></i></span>
                                <span class="showicon" *ngIf="showPassword" (click)="showHidePass()"><i
                                        class="bi bi-eye-slash-fill"></i></span>

                                <span class="text-danger" *ngIf="
                                        resetForm.controls.password?.touched &&
                                        resetForm.controls.password?.errors?.['required']
                                    ">
                                    Please enter valid password
                                </span>
                                <span class="text-danger" *ngIf="
                                        resetForm.controls.password?.touched &&
                                        resetForm.controls.password?.errors?.['pattern']
                                    ">
                                    Password length must be 8 or more and at least 1 uppercase letter, 1 number and
                                    1 special character!
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Confirm Password</label>
                                <input type="password" class="form-control" placeholder="Enter new password"
                                    [type]="confirmPassword" formControlName="confirm_password" required>
                                <span class="showicon" *ngIf="!showConfPassword" (click)="showHideConfirmPass()"><i
                                        class="bi bi-eye-fill"></i></span>
                                <span class="showicon" *ngIf="showConfPassword" (click)="showHideConfirmPass()"><i
                                        class="bi bi-eye-slash-fill"></i></span>

                                <span class="text-danger" *ngIf="resetForm.getError('mismatch')">
                                    Confirm password don't match with set password
                                </span>
                            </div>
                            <button type="submit" class="btn btn-primary w-100 mt-3">Submit</button>
                        </form>
                    </div>
                </div>
            </div>

        </div> <!-- Inner row -->
    </div> <!-- Outer container -->
</body>