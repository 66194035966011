<main id="main">
  <section>
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/fail-approve-reject-project-list"><a>Fail</a></li>
            <li routerLink="/super-admin/approve-reject-projects"><a class="active">Not Releted</a></li>
            <li routerLink="/super-admin/drop-after-fesibility-projects"><a>⁠Dropped after Feasibility</a></li>
            <li routerLink="/super-admin/no-supplier-match-projects"><a>No Supplier Matched</a></li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
              <div class="form-group mb-0">
                <span class="input-icon"><i class="bi bi-search"></i></span>
                <input class="form-control control-search"
                  placeholder="Search by BOS ID, Project name, Client name, Website, Notice reference, Link to notice"
                  type="text" [(ngModel)]="searchText" (input)="searchtext()">
              </div>
            </div>
            <div class="col-lg-1 col-xl-1 col-12 mob-mb1" style="margin-right: 350px; cursor: pointer;">
              <i class="bi bi-info-circle-fill" data-bs-toggle="tooltip"
                title="Search using BOS ID, Project name, Client name, Website, Notice reference"></i>
            </div>
          </div>
          <div class="table-responsive mt-5 mb-3">
            <table class="table table-striped align-middle">
              <thead>
                <tr>
                  <th>Project Title</th>
                  <th>BOS ID</th>
                  <th style="cursor: pointer;" (click)="sort('publishDate')">Publish Date <i class="bi bi-sort-up"></i>
                  </th>
                  <th style="cursor: pointer;" (click)="duedatesort('dueDate')">Due Date & Time <i
                      class="bi bi-sort-up"></i></th>
                  <th>Assign User</th>
                  <th>Feasibility Status</th>
                  <th>Bid Status</th>
                  <th>Approval Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td *ngIf="!projectList?.length" colspan="14">
                    <app-no-record-found></app-no-record-found>
                  </td>
                </tr>

                <tr
                  *ngFor="let item of projectList | paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords }; let i = index">
                  <td><a (click)="projectDetails(item?._id)" href="javascript:;">{{item?.projectName}}</a></td>
                  <td v-align="middle">{{item?.BOSID}}</td>
                  <td>{{item?.publishDate | date:'dd/MM/yyyy'}}</td>
                  <td>{{item?.dueDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                  <td>{{item?.assignBidmanager[0]?.name}}</td>
                  <td>{{item?.status}}</td>
                  <td>{{item?.bidManagerStatus}}</td>
                  <td>{{item?.adminStatusDate | date : "dd/MM/yyyy, h:mm a"}}</td>
                  <td>
                    <div style="display: flex; gap: 10px;">
                      <a class="btn btn-primary btn-sm" style="cursor: pointer;"
                        (click)="approveOrRejectProjects('approve', item?._id)">
                        Approve
                      </a>
                      <a class="btn btn-danger btn-sm" style="cursor: pointer;"
                        (click)="approveOrRejectProjects('reject', item?._id)">
                        Reject
                      </a>
                    </div>
                  </td>
                  <td>
                    <a class="btn btn-primary btn-sm" style="cursor: pointer" data-bs-toggle="modal"
                      data-bs-target="#viewAllProjects" (click)="showComments(item?.task?.comments)">View
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mt-3 align-items-center">
            <div class="row">
              <div class="pagination">
                <pagination-controls (pageChange)="paginate($event)" [idMaxSize]="100"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="modal fade" id="viewAllProjects" tabindex="-1" aria-labelledby="viewAllProjectsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="display: flex; justify-content: space-between">
          <h6>Task Comments</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body d-flex">
          <div class="flex-grow-1 pe-3">
            <div class="comments-section">
              <ul class="SecondarytaskList mt-3">
                <li *ngFor="let comment of viewComments" class="mb-3">
                  <div class="comment-item">
                    <!-- Username on top -->
                    <div class="comment-text">
                      <b>{{ comment?.userDetail?.name }}</b>
                    </div>
                    <!-- Comment and Date on the next line -->
                    <div class="d-flex justify-content-between mt-1">
                      <div class="comment-text" [innerHTML]="comment?.comment">
                      </div>
                      <div class="comment-date text-muted">
                        {{ comment.date | date : "dd/MM/yyyy, h:mm a" }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ng-template #noData>
                <div class="no-data">No Comments available</div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

