<main id="main">
  <section>
    <div class="container-fluids mb-3">
      <div class="row align-items-center">
        <nav>
          <ul class="projects-nav-tab">
            <li routerLink="/super-admin/super-admin-supplier">
              <a class="active">Supplier</a>
            </li>
            <!-- <li routerLink="/super-admin/expertise-view">
              <a>Expertise-view</a>
            </li> -->
            <li routerLink="/super-admin/role-wise-resources-list">
              <a>Resources-view</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-12 col-xl-12 col-12 mb-2 mob-mb1">
          <div class="form-group mb-0">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="text-theme mb-0"><strong>Supplier List</strong></h4>
              <div>
                <span class="badge bg-success me-2">Active Supplier: {{activeSuppliers}}</span>
                <span class="badge bg-danger">Inactive Supplier: {{inactiveSuppliers}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 col-4 d-flex align-items-center">
        </div>
        <div class="d-flex align-items-center flex-grow-1">
          <div class="form-group mb-0 flex-grow-1">
            <span class="input-icon"><i class="bi bi-search"></i></span>
            <input class="form-control control-search" placeholder="Search Supplier..." type="text" [(ngModel)]="search"
              (input)="searchtext()" />
          </div>
        </div>
      </div>

      <!-- Date Filters -->
      <div class="row mt-3 mb-3">
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2">
          <label class="form-label">Start Date</label>
          <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="startDate" name="startDate">
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2">
          <label class="form-label">End Date</label>
          <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="endDate" name="endDate">
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
          <label class="form-label">&nbsp;</label>
          <div class="d-flex gap-3 align-items-center">
            <button class="btn btn-primary" (click)="applyDateFilter()" style="height: 38px;">
              Submit
            </button>
            <a routerLink="/super-admin/add-new-supplier" class="btn btn-primary">
              <i class="bi bi-plus-square"></i> Register Supplier
            </a>
            <button class="btn btn-secondary" (click)="toggleExpertiseView()">
              {{ showExpertiseView ? 'Hide Expertise View' : 'Show Expertise View' }}
            </button>
          </div>
        </div>
      </div>

      <div class="table-responsive mb-3">
        <table class="table table-striped align-middle">
          <thead>
            <tr>
              <th width="200px">Supplier Name</th>
              <th>Join Date</th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td *ngIf="!supplierUserList?.length" colspan="5">
                <app-no-record-found></app-no-record-found>
              </td>
            </tr>

            <tr
              *ngFor="let item of supplierUserList | paginate: { itemsPerPage: pagesize, currentPage: page, totalItems: totalRecords }; let i = index">
              <td>{{item?.companyName || item?.name}}</td>
              <td>{{item?.doj | date : 'dd/MM/yyyy'}}</td>
              <td>
                <a href="javascript:;" (click)="projectDetails(item?._id ,item)" class="btn btn-sm btn-primarysm"
                  title="View Details">
                  View
                </a>
              </td>
              <td>
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="item.active" (ngModelChange)="onToggleSwitch(item)">
                  <span class="slider round"></span>
                </label>
              </td>
              <!-- <td> <a (click)="deleteSupplier(item?._id)" class="btn btn-danger btn-sm"><i
                    class="bi bi-trash3-fill"></i>
                </a>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="row">
          <div class="pagination">
            <pagination-controls [idMaxSize]="100" (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

