<main id="main">
  <section class="casestudy-section">
    <div class="container-fluid mb-3">
      <div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
        <div class="sort-widget d-flex align-items-center gap-3">
          <div class="d-flex align-items-center gap-3">
            <!-- Expired Project Checkbox -->
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="expiredProject" [(ngModel)]="isExpired"
                (change)="searchtext(); getDataByStatus()" />
              <label class="form-check-label" for="expiredProject">Expired</label>
            </div>

            <!-- DPS Checkbox (Value = 1) -->
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="dps" (change)="updateCategorisation('DPS', $event)" />
              <label class="form-check-label" for="dps">DPS</label>
            </div>

            <!-- Framework Checkbox (Value = 3) -->
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="Framework"
                (change)="updateCategorisation('Framework', $event)" />
              <label class="form-check-label" for="Framework">Framework</label>
            </div>

            <!-- DTD Checkbox (Value = 2) -->
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="dtd" (change)="updateCategorisation('DTD', $event)" />
              <label class="form-check-label" for="dtd">DTD</label>
            </div>
          </div>

          <h6 class="p-600 me-3">Date Range</h6>
          <form class="d-flex align-items-center gap-2" (ngSubmit)="getDataByStatus()">
            <div class="input-group">
              <input class="form-control" type="date" [formControl]="trackerStartDate" />
            </div>
            <div class="input-group">
              <input class="form-control" type="date" [formControl]="trackerEndDate" />
            </div>
            <button class="btn btn-primary" type="submit">submit</button>
          </form>
        </div>

        <div class="d-flex align-items-center flex-grow-1">
          <div class="form-group mb-0 flex-grow-1">
            <span class="input-icon"><i class="bi bi-search"></i></span>
            <input class="form-control control-search"
              placeholder="Search by BOS ID, Project name, Client name, Website, Notice reference, Link to notice"
              type="text" [(ngModel)]="searchText" (input)="searchtext()" />
          </div>
          <i class="bi bi-info-circle-fill ms-2" data-bs-toggle="tooltip"
            title="Search using BOS ID, Project name, Client name, Website, Notice reference">
          </i>
        </div>

        <nav id="navbar" class="navbar p-0">
          <ul class="p-0 m-0 d-flex align-items-center">
            <li class="dropdown position-relative">
              <span class="btn btn-primary">+ Add Project</span>
              <ul class="dropdown-menu position-absolute">
                <li>
                  <a routerLink="/process-manager/add-project">Single Entry</a>
                </li>
                <!-- <li><a (click)="openAddTeamModal()">Bulk Entry</a></li> -->
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <h5 class="text-theme"><strong>Feasibility Status</strong></h5>
        <div class="col-lg-12 col-xl-12 col-md-6 col-sm-6 col-12 mb-4" style="cursor: pointer">
          <ul class="taskuserList d-flex flex-wrap list-unstyled">
            <li *ngFor="let item of feasibilityData" class="me-3">
              <div (click)="filter(item.status, 'feasibility')" (click)="selectStatus(item.status)"
                [ngClass]="{ 'text-danger': selectedStatus === item.status }">
                {{ item.status }} : {{ item.count }} : GBP
                {{ item.value | number : "1.0-2" }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <h5 class="text-theme"><strong>Bid Status</strong></h5>
        <div class="col-lg-12 col-xl-12 col-md-6 col-sm-6 col-12 mb-4" style="cursor: pointer">
          <ul class="taskuserList d-flex flex-wrap list-unstyled">
            <li *ngFor="let item of bidData" class="me-3">
              <div (click)="filter(item.status, 'bid')" (click)="selectBidStatus(item.status)"
                [ngClass]="{ 'text-danger': selectedBidStatus === item.status }">
                {{ item.status }} : {{ item.count }} : GBP
                {{ item.value | number : "1.0-2" }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
        <div class="table-responsive mt-5 mb-3">
          <table class="table table-striped align-middle">
            <thead>
              <tr>
                <th>Project Title</th>
                <th>BOS ID</th>
                <th style="cursor: pointer" (click)="sort('publishDate')">
                  Publish Date <i class="bi bi-sort-up"></i>
                </th>
                <th style="cursor: pointer" (click)="duedatesort('dueDate')">
                  Due Date & Time <i class="bi bi-sort-up"></i>
                </th>
                <th>Feasibility Status</th>
                <!-- <th>Feasibility Assign User</th> -->
                <th>Bid Status</th>
                <th>Bid Assign User</th>
                <th>Log Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td *ngIf="!projectList?.length" colspan="15">
                  <app-no-record-found></app-no-record-found>
                </td>
              </tr>

              <tr *ngFor="
                  let item of projectList
                    | paginate
                      : {
                          itemsPerPage: pagesize,
                          currentPage: page,
                          totalItems: totalRecords
                        };
                  let i = index
                ">
                <td>
                  <a href="javascript:;" (click)="projectDetails(item?._id)">{{
                    item?.projectName
                    }}</a>
                </td>
                <td v-align="middle">{{ item?.BOSID }}</td>
                <td>{{ item?.publishDate | date : "dd/MM/yyyy" }}</td>
                <td>
                  {{ item?.dueDate | date : "dd/MM/yyyy"
                  }} , {{ item.bidsubmissiontime }}
                </td>
                <td>{{ item?.status }}</td>
                <!-- <td>{{ item?.assignFeasibilityUser[0]?.name }}</td> -->
                <td>{{ item?.bidManagerStatus }}</td>
                <td>{{ item?.assignBidmanager[0]?.name }}</td>
                <td>
                  {{ item?.statusHistory[0]?.date | date : "dd/MM/yyyy, h:mm a'" }}
                </td>
                <td>
                  <a class="btn btn-primary btn-sm" style="cursor: pointer" data-bs-toggle="modal"
                    data-bs-target="#viewAllProjects" (click)="showComments(item?.task?.comments)">View
                  </a>
                </td>
                <td>
                  <span *ngIf="!item?.westGetDocument?.length" class="text-danger">
                    <i class="bi bi-circle-fill"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-3 align-items-center">
          <div class="row">
            <div class="pagination">
              <pagination-controls (pageChange)="paginate($event)" [idMaxSize]="100"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="modal fade" id="viewAllProjects" tabindex="-1" aria-labelledby="viewAllProjectsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="display: flex; justify-content: space-between">
          <h6>Task Comments</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body d-flex">
          <div class="flex-grow-1 pe-3">
            <div class="comments-section">
              <!-- Pinned Comments Section -->
              <div *ngIf="viewComments && viewComments.length > 0">
                <h6 class="mt-2 mb-3" *ngIf="hasPinnedComments()"><i class="bi bi-pin-fill" style="color: #6c757d;"></i> <strong>Pinned Comments</strong></h6>
                <ul class="SecondarytaskList" *ngIf="hasPinnedComments()">
                  <li *ngFor="let comment of viewComments" class="mb-3" [hidden]="!comment?.pinnedAt">
                    <div class="comment-item p-3 pinned-comment">
                      <!-- Pin icon at top right -->
                      <i class="bi bi-pin-fill" style="position: absolute; top: 12px; right: 12px; color: #6c757d;"></i>

                      <!-- Username and role -->
                      <div class="row align-items-center mb-2">
                        <div class="col">
                          <div class="d-flex justify-content-between">
                            <p class="mb-0 user-name"><strong>{{ comment?.userDetail?.name }}</strong></p>
                            <small class="comment-date" *ngIf="comment?.date">
                              {{ comment?.date | date : "dd/MM/yyyy, h:mm a" }}
                            </small>
                          </div>
                          <small class="user-role d-block text-muted">
                            {{ comment?.userDetail?.role === "BOS" ? "USER" : comment?.userDetail?.role }}
                          </small>
                        </div>
                      </div>

                      <!-- Comment content -->
                      <div class="comment-text" [innerHTML]="comment?.comment"></div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Regular Comments Section -->
              <h6 class="mt-3 mb-2"><strong>All Comments</strong></h6>
              <ul class="SecondarytaskList mt-2">
                <li *ngFor="let comment of viewComments" class="mb-3" [hidden]="comment?.pinnedAt">
                  <div class="comment-item p-3" style="border: 1px solid #dee2e6; border-radius: 4px;">
                    <!-- Username and role -->
                    <div class="row align-items-center mb-2">
                      <div class="col">
                        <div class="d-flex justify-content-between">
                          <p class="mb-0 user-name"><strong>{{ comment?.userDetail?.name }}</strong></p>
                          <small class="comment-date" *ngIf="comment?.date">
                            {{ comment?.date | date : "dd/MM/yyyy, h:mm a" }}
                          </small>
                        </div>
                        <small class="user-role d-block text-muted">
                          {{ comment?.userDetail?.role === "BOS" ? "USER" : comment?.userDetail?.role }}
                        </small>
                      </div>
                    </div>

                    <!-- Comment content -->
                    <div class="comment-text" [innerHTML]="comment?.comment"></div>
                  </div>
                </li>
              </ul>
              <div *ngIf="!viewComments || viewComments.length === 0" class="no-data">
                No Comments available
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
