import { Component } from '@angular/core';

@Component({
  selector: 'app-bid-matched-projects',
  templateUrl: './bid-matched-projects.component.html',
  styleUrls: ['./bid-matched-projects.component.scss']
})
export class BidMatchedProjectsComponent {

}
