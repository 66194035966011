import { Component } from '@angular/core';

@Component({
  selector: 'app-boss-user-upload-project',
  templateUrl: './boss-user-upload-project.component.html',
  styleUrls: ['./boss-user-upload-project.component.scss']
})
export class BossUserUploadProjectComponent {

}
